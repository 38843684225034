import { sortBy } from 'shared/lib/collections';
import { TrackingType } from 'shared/lib/types/postgres/manufacturing/types';
import {
  BlockType,
  RunPartBuildBlock,
  RunPartKitBlock,
  RunToolCheckInBlock,
  RunToolCheckOutBlock,
} from 'shared/lib/types/views/procedures';

export interface Run {
  sections: Array<RunSection>;
}

export interface RunSection {
  steps: Array<RunStep>;
}

export interface RunStep {
  completedAt?: string;
  content: Array<StepContentBlock>;
}

export interface StepContentBlock {
  type: BlockType;
}

export interface ToolCheckOutBlock {
  type: 'tool_check_out';
  recorded?: { tool_instance_id: number };
}

export interface ToolCheckInBlock {
  type: 'tool_check_in';
  recorded?: { tool_instance_id: number };
}

export interface Item {
  id: string;
  serial?: string;
  lot?: string;
  part: {
    part_no: string;
    rev: string;
    name: string;
    tracking: TrackingType;
  };
}

interface PartCheckOutItem {
  item_id?: string;
  tracking?: TrackingType;
  amount: number;
}

export interface PartCheckOutBlock {
  type: 'part_kit';
  part: { part_no: string } | null;
  recorded?: {
    items?: Record<string, PartCheckOutItem>;
    added_items?: Array<PartCheckOutItem>;
  };
}

interface PartCheckInItem {
  item_id?: string;
  tracking?: TrackingType;
  amount: number;
}

export interface PartCheckInBlock {
  type: 'part_build';
  part?: { part_no: string };
  recorded?: {
    items?: {
      [item_id: string]: PartCheckInItem;
    };
    added_items?: Array<PartCheckInItem>;
  };
}

const inventoryUtil = {
  checkedOutToolInstanceIds: (run: Run): Array<number> => {
    const toolCheckInOutBlocks = internal.toolCheckInOutBlocks(run);
    return internal.checkedOutToolInstanceIds(toolCheckInOutBlocks);
  },

  hasPartCheckInOutBlocks: (run: Run): boolean =>
    internal.partCheckInOutBlocks(run).length > 0,

  checkedOutInventory: (run: Run, allItems: Array<Item>): Array<Item> => {
    const partCheckInOutBlocks = internal.partCheckInOutBlocks(run);
    return internal.checkedOutInventory(partCheckInOutBlocks, allItems);
  },

  hasPartCheckInBlocks: (step: RunStep): boolean =>
    step.content.some((content) => content.type === 'part_build'),

  collectPartIdsInBlock: (
    content: { part_id?: string; items?: Array<{ part_id: string }> },
    recorded?: { added_items?: Array<{ part_id: string }> }
  ): string[] => {
    const { part_id, items } = content;
    const partIds = new Set<string>();
    if (part_id) {
      partIds.add(part_id);
    }
    (items ?? []).forEach((item) => partIds.add(item.part_id));

    const added_items = recorded?.added_items ?? [];
    added_items.forEach((item) => partIds.add(item.part_id));
    return Array.from(partIds);
  },
};

const internal = {
  completedSteps: (run: Run): Array<RunStep> =>
    run.sections.flatMap((section) =>
      section.steps.filter((step) => step.completedAt)
    ),

  /**
   * @returns tool check-out/in blocks in completed steps, sorted by step completion time
   */
  toolCheckInOutBlocks: (
    run: Run
  ): Array<RunToolCheckOutBlock | RunToolCheckInBlock> => {
    // @ts-ignore
    return sortBy(internal.completedSteps(run), ['completedAt']).flatMap(
      (step) =>
        step.content.filter(
          (content) =>
            content.type === 'tool_check_out' ||
            content.type === 'tool_check_in'
        )
    );
  },

  checkedOutToolInstanceIds: (
    contentBlocks: Array<ToolCheckOutBlock | ToolCheckInBlock>
  ): Array<number> => {
    const checkOuts = new Set<number>();
    for (const content of contentBlocks) {
      if (content.type === 'tool_check_out' && content.recorded) {
        checkOuts.add(content.recorded.tool_instance_id);
      } else if (content.type === 'tool_check_in' && content.recorded) {
        checkOuts.delete(content.recorded.tool_instance_id);
      }
    }
    return Array.from(checkOuts);
  },

  /**
   * @returns part check-out/in blocks in completed steps, sorted by step completion time
   */
  partCheckInOutBlocks: (
    run: Run
  ): Array<RunPartKitBlock | RunPartBuildBlock> => {
    // @ts-ignore
    return sortBy(internal.completedSteps(run), ['completedAt']).flatMap(
      (step) =>
        step.content.filter(
          (content) =>
            content.type === 'part_kit' || content.type === 'part_build'
        )
    );
  },

  itemKey: (
    item: Item,
    checkInOutItem: PartCheckOutItem | PartCheckInItem
  ): string => {
    switch (checkInOutItem.tracking) {
      case 'serial':
      default:
        return item.id;
      case 'lot':
        return `${item.part.part_no}-${item.part.rev}-${item.lot}`;
      case 'none':
        return `${item.part.part_no}-${item.part.rev}`;
    }
  },

  checkedOutInventory: (
    contentBlocks: Array<PartCheckOutBlock | PartCheckInBlock>,
    allItems: Array<Item>
  ): Array<Item> => {
    const checkOuts = contentBlocks.reduce((checkOuts, content) => {
      const updateAmount = (
        checkInOutItem: PartCheckOutItem | PartCheckInItem,
        updateType: 'increment' | 'decrement'
      ) => {
        const item = allItems.find(
          (item) => item.id === checkInOutItem.item_id
        );
        if (item) {
          const key = internal.itemKey(item, checkInOutItem);
          const val = checkOuts.get(key) ?? { itemId: item.id, amount: 0 };
          if (updateType === 'increment') {
            val.amount += checkInOutItem.amount;
            checkOuts.set(key, val);
          } else {
            val.amount = Math.max(0, val.amount - checkInOutItem.amount); // don't set to < 0
            checkOuts.set(key, val);
          }
        }
      };

      if (content.type === 'part_kit' && content.recorded) {
        for (const item of Object.values(content.recorded.items ?? {})) {
          updateAmount(item, 'increment');
        }
        for (const item of content.recorded.added_items ?? []) {
          updateAmount(item, 'increment');
        }
      } else if (content.type === 'part_build' && content.recorded) {
        for (const item of Object.values(content.recorded.items ?? {})) {
          updateAmount(item, 'decrement');
        }
        for (const item of content.recorded.added_items ?? []) {
          updateAmount(item, 'decrement');
        }
      }

      return checkOuts;
    }, new Map<string, { itemId: string; amount: number }>());

    const itemIds = Array.from(checkOuts.values())
      .filter((value) => value.amount > 0)
      .map((value) => value.itemId);

    return allItems.filter((item) => itemIds.includes(item.id));
  },
};

export default inventoryUtil;
