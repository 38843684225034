import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FormikHelpers, FormikValues } from 'formik';
import cloneDeep from 'lodash.clonedeep';
import {
  AddedStep,
  DraftInventoryDetailInputBlock,
  ExpressionToken,
  InitialToolCheckInBlock,
  InitialToolCheckOutBlock,
  InitialToolUsageBlock,
  Rule,
  TableInputBlock,
} from 'shared/lib/types/views/procedures';
import { CONTENT_TYPE_JUMP_TO } from '../JumpTo/JumpTo';
import { ExternalSearchType } from './ExternalSearchFieldSet';

export type BlockTypeList = keyof typeof BlockInitialValues;
export type BlockValues<T extends BlockTypeList> =
  (typeof BlockInitialValues)[T] & { id: string };

export type RecordedString = string;
export type RecordedNumber = number;
/**
 * See the {@link https://docs.google.com/document/d/1HPH_lZyiIifGg2oOHPZ5zF9rX54HzIhkMfWJpSVvnYk/#heading=h.79bwijfucnej External Data API} section
 * of the API Guide.
 */
export type ExternalDataItem = {
  id: string;
  dictionary_id?: number;
  name?: string;
  label?: string;
  metadata?: object;
  details?: Array<{
    name: string;
    value: string;
  }>;
  valid?: boolean;
  url?: string;
};

export type RecordedValue<
  U extends RecordedString | RecordedNumber | ExternalDataItem
> = {
  value?: U;
};

export type RedlineBlock<T extends BlockTypeList> = {
  block: BlockValues<T>;
  userId?: string;
  createdAt?: string;
  pending?: boolean;
  redlineIndex?: number;
};

export interface TypedBlockProps<
  T extends BlockTypeList,
  U extends RecordedString | RecordedNumber | ExternalDataItem
> {
  block: BlockValues<T>;
  recorded?: RecordedValue<U>;
  redlined?: RedlineBlock<T>;
  redlines?: Array<RedlineBlock<T>>;
  isEnabled?: boolean;
  isDark?: boolean;
  onRecordValuesChanged?: (recorded: RecordedValue<U>) => void;
  onRecordErrorsChanged?: (errors: { text?: string }) => void;
  onRecordUploadingChanged?: (uploading: boolean) => void;
  onContentRefChanged?: (id: string, element: HTMLElement) => void;
  scrollMarginTopValueRem?: number;
}

export interface TypedFieldSetProps<T extends BlockTypeList> {
  path: null | string;
  content: BlockValues<T>;
  contentErrors?: Record<string, string>;
  disabledFields?: Record<string, unknown>;
  setFieldValue?: FormikHelpers<FormikValues>['setFieldValue'];
  isSummaryHidden?: boolean | undefined;
  blockTitle?: string | undefined;
  pendingStep?: AddedStep;
  precedingStepId?: string;
}

export type Action = {
  ariaLabel: string;
  icon: IconProp;
  iconType?: 'primary' | 'secondary' | 'caution' | 'neutral' | 'warning';
  onAction: () => void;
  pendingAction?: boolean;
  pendingDotMatchesIcon?: boolean;
};

export interface TypedProcedureBlockProps {
  isHidden?: boolean;
  actions?: Action[];
  children: React.ReactNode;
  isSpacerHidden?: boolean;
  hasExtraVerticalSpacing?: boolean;
  blockLabel: string;
}

export type BlockType = keyof typeof BlockTypes;

// Block type definitions. These are stored in each Block, eg { type: 'text' }
const BlockTypes = Object.freeze({
  Alert: 'alert',
  Attachment: 'attachment',
  FieldInput: 'input',
  TableInput: 'table_input',
  JumpTo: CONTENT_TYPE_JUMP_TO,
  Text: 'text',
  ProcedureLink: 'procedure_link',
  Commanding: 'commanding',
  Telemetry: 'telemetry',
  Requirement: 'requirement',
  ExternalItem: 'external_item',
  Reference: 'reference',
  Expression: 'expression',
  Conditionals: 'conditionals',
  Dependencies: 'dependencies',
  PartList: 'part_list',
  PartKit: 'part_kit',
  PartBuild: 'part_build',
  InventoryDetailInput: 'inventory_detail_input',
  ToolCheckOut: 'tool_check_out',
  ToolCheckIn: 'tool_check_in',
  PartUsage: 'part_usage',
  ToolUsage: 'tool_usage',
  TestCases: 'test_cases',
  FieldInputTable: 'field_input_table',
} as const);

export const AttachmentImageSize = Object.freeze({
  Small: 'small',
  BestFit: 'best_fit',
  Original: 'original',
});

export const AttachmentImageDisplayStyle = Object.freeze({
  Inline: 'inline',
  ToTheSide: 'to_the_side',
});

// When editing a procedure, initial values for empty Blocks of each type.
const BlockInitialValues = Object.freeze({
  [BlockTypes.Alert]: {
    type: BlockTypes.Alert,
    subtype: '',
    text: '',
  },
  [BlockTypes.Attachment]: {
    type: BlockTypes.Attachment,
    size: AttachmentImageSize.BestFit,
    display_style: AttachmentImageDisplayStyle.Inline,
    caption: '',
    name: '',
    id: '',
    attachment_id: '',
    content_type: '',
    file: undefined,
  },
  [BlockTypes.FieldInput]: {
    type: BlockTypes.FieldInput,
    name: '',
    inputType: 'text', // Keep in sync with default dropdown <option>
    units: '',
    dateTimeType: 'date', // Three settings: date, time, datetime
    rule: {
      value: '',
      op: '',
    } as Rule,
    options: [] as string[],
    /**
     * This is actually the list id.
     */
    list: '',
    include_in_summary: true,
    external_item_type: '',
    dictionary_id: undefined,
    external_search_type: {
      data_type_dictionary_id: undefined,
      data_type: '',
      filter_options: [] as string[],
    } as ExternalSearchType,
  },
  [BlockTypes.JumpTo]: {
    type: BlockTypes.JumpTo,
    jumpToId: '',
  },
  [BlockTypes.Text]: {
    type: BlockTypes.Text,
    id: '',
    text: '',
    tokens: [] as ExpressionToken[],
  },
  [BlockTypes.ProcedureLink]: {
    type: BlockTypes.ProcedureLink,
    id: '',
    procedure: '',
    run: '',
    section: '',
  },
  [BlockTypes.Requirement]: {
    type: BlockTypes.Requirement,
    requirement_id: '',
    label: '',
    metadata: {},
  },
  [BlockTypes.ExternalItem]: {
    id: '',
    type: BlockTypes.ExternalItem,
    dictionary_id: undefined,
    item_type: '',
    item_id: '',
    item_name: '',
    item_label: '',
    item_url: '',
    item_valid: true,
  },
  [BlockTypes.Reference]: {
    type: BlockTypes.Reference,
    reference: '',
    sub_reference: '',
    field_index: '',
    include_in_summary: true,
  },
  [BlockTypes.Expression]: {
    type: BlockTypes.Expression,
    name: '',
    tokens: [] as ExpressionToken[],
    include_in_summary: true,
  },
  [BlockTypes.Telemetry]: {
    type: 'telemetry',
    key: 'parameter',
    name: '',
    parameter_id: undefined,
    dictionary_id: undefined,
    rule: '',
    value: '',
    expression: '',
    cosmos: {
      target: '',
      packet: '',
      item: '',
    },
    range: {
      min: '',
      max: '',
    },
    include_in_summary: true,
  },
  [BlockTypes.Commanding]: {
    type: 'commanding',
    key: 'command',
    name: '',
    command_id: undefined,
    dictionary_id: undefined,
    cosmos: {
      target: '',
      command: '',
    },
    include_in_summary: true,
  },
  [BlockTypes.PartKit]: {
    type: BlockTypes.PartKit,
    id: '',
    items: [],
    include_in_summary: true,
  },
  [BlockTypes.PartList]: {
    type: BlockTypes.PartList,
    id: '',
    part_id: '',
    part: null,
    items: [],
  },
  [BlockTypes.PartBuild]: {
    type: BlockTypes.PartBuild,
    id: '',
    part_id: '',
    part: null,
    items: [],
    include_in_summary: true,
  },
  [BlockTypes.InventoryDetailInput]: {
    id: '',
    type: BlockTypes.InventoryDetailInput,
    part_revision_id: '',
    detail_id: '',
  } as DraftInventoryDetailInputBlock,
  [BlockTypes.ToolCheckOut]: {
    type: BlockTypes.ToolCheckOut,
    id: '',
    tool_id: null,
    include_in_summary: true,
  } as InitialToolCheckOutBlock,
  [BlockTypes.ToolCheckIn]: {
    type: BlockTypes.ToolCheckIn,
    id: '',
    tool_id: null,
    include_in_summary: true,
  } as InitialToolCheckInBlock,
  [BlockTypes.PartUsage]: {
    type: BlockTypes.PartUsage,
    id: '',
    part_id: '',
    part: null,
    usage_types: [],
    include_in_summary: true,
  },
  [BlockTypes.ToolUsage]: {
    type: BlockTypes.ToolUsage,
    id: '',
    tool_id: null,
    usage_type_id: null,
    include_in_summary: true,
  } as InitialToolUsageBlock,
  [BlockTypes.TableInput]: {
    type: BlockTypes.TableInput,
    sub_type: '',
    id: '',
    columns: [],
    rows: 0,
    cells: [],
    include_in_summary: true,
  } as TableInputBlock,
  [BlockTypes.TestCases]: {
    type: BlockTypes.TestCases,
    id: '',
    items: [],
  },
  [BlockTypes.FieldInputTable]: {
    type: BlockTypes.FieldInputTable,
    id: '',
    fieldsPerRow: 1,
    fields: [
      {
        type: BlockTypes.FieldInput,
        name: '',
        inputType: 'text',
        units: '',
        dateTimeType: 'date',
        rule: {
          value: '',
          op: '',
        } as Rule,
        options: [] as string[],
        list: '',
        include_in_summary: true,
      },
    ],
    include_in_summary: true,
  },
});

/*
 * Defines whether a block supports redlining.
 * Will grow as we add redlining to more block types.
 */
const BlockRedlineSupport = Object.freeze({
  [BlockTypes.Text]: true,
  [BlockTypes.Alert]: true,
  [BlockTypes.FieldInput]: true,
} as const);

// Defines any restricted fields when redlining a block.
const BlockRedlineDisabledFields = Object.freeze({
  [BlockTypes.Text]: {},
  [BlockTypes.Alert]: {},
  [BlockTypes.FieldInput]: {
    inputType: true, // Cannot change field input type when redlining
    dateTimeType: true,
    include_in_summary: true, // Cannot change if included in summary
    options: true, // Cannot change options for "select" input type
    external_item_type: true, // Cannot change external data type
    external_search_type: true,
    list: true, // Cannot change list
    rule_operation: true, // Cannot change rule operator
  },
} as const);

/*
 * Gets initial form values for an empty block of the given type.
 *
 * blockType: String, one of the listed values in BlockTypes. Eg, 'text'
 * returns: a Block of the given type with initial empty values.
 */
const getInitialFormValues = (
  blockType: BlockTypeList,
  blockSubtype?: string
): (typeof BlockInitialValues)[typeof blockType] => {
  const initial = BlockInitialValues[blockType];
  if (!initial) {
    return initial;
  }
  const copied = cloneDeep(initial);
  /*
   * (aaron) This assumes all subtypes have the same type structure, which is
   * true so far.
   * TODO: Improve software architecture for block subtypes, perhaps ask each
   * block for initial values for type and subtype.
   */
  if (blockSubtype) {
    copied.subtype = blockSubtype;
  }
  return copied;
};

/*
 * Gets the disabled fields for a given block type when redlining.
 *
 * blockType: String, one of the listed values in BlockTypes. Eg, 'text'
 * returns: A dictionary in the format { field: true } to list disabled fields.
 */
const getRedlineDisabledFields = (
  blockType: keyof typeof BlockRedlineDisabledFields
): (typeof BlockRedlineDisabledFields)[typeof blockType] =>
  BlockRedlineDisabledFields[blockType];

/*
 * Checks if relinding is supported for the given block type.
 *
 * blockType: String, one of the listed values in BlockTypes. Eg, 'text'
 * returns: True if the block supports redlining, otherwise false.
 */
const isRedlineSupported = (blockType: string): boolean =>
  // Turn null/undefined into true/false
  !!BlockRedlineSupport[blockType.toLowerCase()];

export {
  BlockTypes,
  getInitialFormValues,
  getRedlineDisabledFields,
  isRedlineSupported,
};
