import React, { Fragment, useMemo, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import revisionsUtil from '../../lib/revisions';
import ProcedureFieldNoRedlines from '../ProcedureFieldNoRedlines';
import RunTableInput from '../TableInput/RunTableInput';
import BlockCommanding from '../../components/Blocks/BlockCommanding';
import BlockTelemetry from '../../components/Blocks/BlockTelemetry';
import PartBuild from '../../manufacturing/components/PartBuild';
import PartKit from '../../manufacturing/components/PartKit';
import PartUsage from '../../manufacturing/components/PartUsage';
import InventoryDetailInput from '../../manufacturing/components/InventoryDetailInput';
import RunToolCheckOutIn from '../../manufacturing/components/Tools/RunToolCheckOutIn';
import RunToolUsage from '../../manufacturing/components/Tools/RunToolUsage';
import { useProcedureFilter } from '../../contexts/ProcedureFilterContext';
import ReferenceBlock from '../Blocks/ReferenceBlock';
import { RUN_STATE, STEP_STATE, getStepState } from 'shared/lib/runUtil';
import ExpressionBlock from '../Expression/ExpressionBlock';
import { BlockTypes } from '../Blocks/BlockTypes';
import { useRunContext } from '../../contexts/RunContext';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { noop } from 'lodash';
import CommentWrapper from '../CommentWrapper';
import signoffUtil from 'shared/lib/signoffUtil';
import StepTimeline from '../StepTimeline';
import FieldInputTable from '../FieldInputTable';
import ProcedureBlockRunNoRedlines from '../Blocks/ProcedureBlockRunNoRedlines';
import withBlockRedlining from '../../hocs/withBlockRedlining';
import ProcedureBlockRun from '../Blocks/ProcedureBlockRun';
import withFieldRedlining from '../../hocs/withFieldRedlining';
import { useSettings } from '../../contexts/SettingsContext';
import ProcedureField from '../ProcedureField';
import Button, { BUTTON_TYPES } from '../Button';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import FullStepSuggestedEditsModal from '../SuggestedEdits/FullStepSuggestedEditsModal';

const ProcedureBlockWithRedlining = withBlockRedlining(ProcedureBlockRun);
const StepFieldWithRedlining = withFieldRedlining(ProcedureField);

const SummaryStep = ({ repeatKey, sectionStepKey, sectionId, step, onRefChanged }) => {
  const { run } = useRunContext();
  const { currentTeamId } = useDatabaseServices();
  const { filters, isStepFiltered, isContentFiltered } = useProcedureFilter();
  const isAddedStep = 'created_during_run' in step && step['created_during_run'] === true;
  const { isFullStepRedliningEnabled } = useSettings();
  const [showSuggestedEditsModal, setShowSuggestedEditsModal] = useState(false);

  const stepState = useMemo(() => getStepState(step), [step]);

  const fullRedliningEnabled = useMemo(
    () => isFullStepRedliningEnabled && isFullStepRedliningEnabled(),
    [isFullStepRedliningEnabled]
  );

  const redlineStepFieldChanges = useCallback(
    (field) => {
      return revisionsUtil.getStepFieldChanges(step, field, step.redlines);
    },
    [step]
  );

  const onContentRefChanged = useCallback(
    (contentId, element) => {
      if (typeof onRefChanged === 'function') {
        onRefChanged(contentId, element);
      }
    },
    [onRefChanged]
  );

  const onStepRefChanged = useCallback(
    (element) => {
      if (typeof onRefChanged === 'function') {
        onRefChanged(step.id, element);
      }
    },
    [step, onRefChanged]
  );

  const getFieldInputTableRecordedData = useCallback(
    (content) => {
      if (signoffUtil.isSignoffRequired(step.signoffs)) {
        return content.fields.map((field) => {
          return field.recorded;
        });
      }
      return content.fields.map((field) => field.recorded);
    },
    [step.signoffs]
  );

  if (!isStepFiltered(step.id)) {
    return null;
  }

  return (
    <Fragment>
      {/* Step title */}
      <tr>
        <td></td>
        <td colSpan={2}>
          <div
            ref={onStepRefChanged}
            className="ml-4 py-2 flex items-start border-t app-border-gray-4 page-break"
            style={{ scrollMarginTop: '4rem' }}
          >
            {/* Step key */}
            <div className="h-8 w-8 mt-0.5 flex justify-center items-center rounded-full bg-black">
              <span className="font-bold text-xs text-white">{sectionStepKey}</span>
            </div>
            {/* Step name and completion info */}
            <div className="ml-5 w-8 h-9 flex flex-row justify-between grow">
              <div className="w-full flex flex-row items-center font-semibold">
                {!fullRedliningEnabled && (
                  <StepFieldWithRedlining
                    fieldName="name"
                    fieldValue={step.name}
                    redlines={redlineStepFieldChanges('name')}
                    showsRedlineAction={false}
                    placeholder={undefined}
                    onDirtyChanged={undefined}
                    onSubmitEdit={undefined}
                    validate={undefined}
                    onLabelClick={undefined}
                    isEditing={undefined}
                    setIsEditing={undefined}
                    step={undefined}
                  />
                )}
                {fullRedliningEnabled && (
                  <ProcedureFieldNoRedlines
                    fieldName="name"
                    fieldValue={step.name}
                    redlines={redlineStepFieldChanges('name')}
                    onLabelClick={() => null}
                  />
                )}
                {isAddedStep && (
                  <div className="whitespace-nowrap flex-none self-start mt-1.5">
                    <span className="ml-4 text-sm text-gray-600">
                      <FontAwesomeIcon icon="clipboard" />
                    </span>
                    <span className="ml-1 text-sm font-bold text-gray-600 italic">Added</span>
                  </div>
                )}
                {repeatKey && (
                  <div className="whitespace-nowrap self-start mt-1.5">
                    <span className="ml-4 text-sm text-gray-600">
                      <FontAwesomeIcon icon="redo" />
                    </span>
                    <span className="ml-1 text-sm font-bold text-gray-600 italic">Repeat {repeatKey}</span>
                  </div>
                )}
                {fullRedliningEnabled && step.redlines && step.redlines.length > 0 && (
                  <div className="relative">
                    <Button
                      type={BUTTON_TYPES.TERTIARY}
                      leadingIcon="strikethrough"
                      iconTextColor={
                        step.redlines[step.redlines.length - 1].run_only ? 'text-blue-500' : 'text-red-500'
                      }
                      onClick={() => setShowSuggestedEditsModal(true)}
                    />
                    {step.redlines[step.redlines.length - 1].pending && (
                      <FontAwesomeIcon
                        icon={faCircle}
                        className={`absolute top-1.5 right-1 ${
                          step.redlines[step.redlines.length - 1].run_only ? 'text-blue-800' : 'text-red-800'
                        }`}
                        style={{
                          fontSize: '10px',
                          fontWeight: '700',
                          lineHeight: '1',
                        }}
                      />
                    )}
                  </div>
                )}
                {fullRedliningEnabled && showSuggestedEditsModal && (
                  <FullStepSuggestedEditsModal
                    currentStep={step}
                    redlines={step.redlines}
                    showLatestInHistory={false}
                    onClose={() => setShowSuggestedEditsModal(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </td>
      </tr>
      {filters.data &&
        step &&
        step.content &&
        step.content.map((content, contentIndex) => {
          if (!isContentFiltered(content.id)) {
            return null;
          }
          const typeLower = content.type.toLowerCase();
          const redlines = revisionsUtil.getBlockChanges(content, contentIndex, step.redlines);

          return (
            <CommentWrapper content={content} comments={filters.activity ? step.comments : []}>
              {typeLower === BlockTypes.FieldInput && (
                <>
                  {!fullRedliningEnabled && (
                    <ProcedureBlockWithRedlining
                      key={contentIndex}
                      block={content}
                      redlines={redlines}
                      recorded={content.recorded}
                      contentIndex={contentIndex}
                      isEnabled={false}
                      isHidden={false}
                      isSpacerHidden={false}
                      onContentRefChanged={onContentRefChanged}
                      scrollMarginTopValueRem={0}
                      showsRedlineAction={false}
                      onDirtyChanged={undefined}
                      onSubmitEdit={undefined}
                      blockLabel={undefined}
                      isEditing={undefined}
                      setIsEditing={undefined}
                    />
                  )}
                  {fullRedliningEnabled && (
                    <ProcedureBlockRunNoRedlines
                      key={contentIndex}
                      block={content}
                      redlines={redlines}
                      recorded={content.recorded}
                      contentIndex={contentIndex}
                      isEnabled={false}
                      isHidden={false}
                      isSpacerHidden={false}
                      onContentRefChanged={onContentRefChanged}
                      scrollMarginTopValueRem={0}
                      blockLabel={undefined}
                      onRecordValuesChanged={undefined}
                      onRecordErrorsChanged={undefined}
                      onRecordUploadingChanged={undefined}
                      isDark={undefined}
                      setExpressionRecorded={undefined}
                    />
                  )}
                </>
              )}
              {typeLower === BlockTypes.TableInput && (
                <RunTableInput
                  key={contentIndex}
                  content={content}
                  recorded={content.recorded}
                  contentIndex={contentIndex}
                  isHidden={false}
                  isEnabled={false}
                  isSpacerHidden={false}
                  blockLabel={null}
                  onFieldValueChange={null}
                  onFieldErrorsChanged={null}
                  saveTextComment={undefined}
                  editTextComment={undefined}
                />
              )}
              {typeLower === BlockTypes.Commanding && (
                <BlockCommanding
                  key={contentIndex}
                  commanding={content}
                  contentIndex={contentIndex}
                  isCompleteEnabled={false}
                  isHidden={false}
                  isSpacerHidden={false}
                />
              )}
              {typeLower === BlockTypes.Telemetry && (
                <BlockTelemetry
                  key={contentIndex}
                  telemetry={content}
                  docState={RUN_STATE.COMPLETED}
                  shouldPausePlot={
                    signoffUtil.anySignoffsComplete(step) ||
                    stepState === STEP_STATE.FAILED ||
                    stepState === STEP_STATE.SKIPPED
                  }
                  blockLabel={undefined}
                  onTelemetryStateChange={undefined}
                  canBeStale={undefined}
                />
              )}
              {typeLower === BlockTypes.Reference && (
                <ReferenceBlock
                  key={contentIndex}
                  originalReferencedContentId={content.reference}
                  originalReferencedSubtype={content.sub_reference}
                  isHidden={false}
                  blockLabel=""
                />
              )}
              {typeLower === BlockTypes.Expression && (
                <ExpressionBlock key={contentIndex} name={content.name} tokens={content.tokens} isHidden={false} />
              )}
              {typeLower === BlockTypes.PartKit && (
                <PartKit
                  content={content}
                  contentIndex={contentIndex}
                  recorded={content.recorded}
                  blockLabel={undefined}
                  onRecordValuesChanged={undefined}
                  isEnabled={false}
                  isHidden={false}
                  isStepComplete={stepState === STEP_STATE.COMPLETED}
                  teamId={currentTeamId}
                />
              )}
              {typeLower === BlockTypes.PartUsage && (
                <PartUsage
                  content={content}
                  errors={undefined}
                  recorded={content.recorded}
                  blockLabel={undefined}
                  teamId={currentTeamId}
                  onRecordErrorsChanged={undefined}
                  onRecordValuesChanged={undefined}
                  isEnabled={false}
                  isHidden={false}
                />
              )}
              {typeLower === BlockTypes.PartBuild && (
                <PartBuild
                  content={content}
                  recorded={content.recorded}
                  blockLabel={undefined}
                  teamId={currentTeamId}
                  onRecordValuesChanged={noop}
                  onRecordErrorsChanged={noop}
                  isEnabled={false}
                  isHidden={false}
                  isStepComplete={stepState === STEP_STATE.COMPLETED}
                />
              )}
              {typeLower === BlockTypes.InventoryDetailInput && (
                <InventoryDetailInput
                  content={content}
                  recorded={content.recorded}
                  blockLabel={undefined}
                  onRecordValuesChanged={noop}
                  isEnabled={false}
                />
              )}
              {typeLower === BlockTypes.ToolCheckOut && (
                <RunToolCheckOutIn
                  content={content}
                  type="out"
                  recorded={content.recorded}
                  isEnabled={false}
                  stepState={stepState}
                  teamId={currentTeamId}
                />
              )}
              {typeLower === BlockTypes.ToolUsage && (
                <RunToolUsage content={content} recorded={content.recorded} isEnabled={false} teamId={currentTeamId} />
              )}
              {typeLower === BlockTypes.ToolCheckIn && (
                <RunToolCheckOutIn
                  content={content}
                  type="in"
                  recorded={content.recorded}
                  isEnabled={false}
                  stepState={stepState}
                  teamId={currentTeamId}
                />
              )}
              {typeLower === BlockTypes.FieldInputTable && (
                <FieldInputTable
                  content={content}
                  recorded={getFieldInputTableRecordedData(content)}
                  isEnabled={false}
                  isHidden={false}
                  blockLabel=""
                  onRecordValuesChanged={() => {}}
                />
              )}
            </CommentWrapper>
          );
        })}
      {/* Step completion/skipped user id and timestamps */}
      {filters.activity ? (
        <tr>
          <td></td>
          <td colSpan={2}>
            <div />
            <div className="mt-4 mb-2 page-break">
              <StepTimeline sectionId={sectionId} step={step} issues={run.issues} isRun={true} />
            </div>
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

export default SummaryStep;
