import React, { useCallback, useMemo } from 'react';
import { Field, FormikHelpers, FormikValues, getIn } from 'formik';
import validateUtil from '../../lib/validateUtil';
import TextAreaAutoHeight from '../TextAreaAutoHeight';
import FieldSetMultiSelectCreatable from '../FieldSetMultiSelectCreatable';
import ExternalTypeSelectFieldSet from './ExternalTypeSelectFieldSet';
import ExternalSearchFieldSet from './ExternalSearchFieldSet';
import FieldSetCheckbox from '../FieldSetCheckbox';
import ListSelectFieldSet from './ListSelectFieldSet';
import FieldSetRule from '../FieldSetRule';
import procedureUtil from '../../lib/procedureUtil';
import fieldInputTypes from '../../lib/fieldInputTypes';
import {
  DraftFieldInputBlock,
  DraftFieldInputCustomListBlock,
  DraftFieldInputExternalDataBlock,
  DraftFieldInputExternalSearchBlock,
  DraftFieldInputMultipleChoiceBlock,
  DraftFieldInputNumberBlock,
  DraftFieldInputSettingsListBlock,
} from 'shared/lib/types/views/procedures';
import {
  ExternalItemInputBlockContentErrors,
  FieldInputBlockContentErrors,
  ListInputBlockContentErrors,
  MultipleChoiceInputBlockContentErrors,
  NumberFieldInputBlockContentErrors,
  NumberFieldSetContentErrors,
  SelectInputBlockContentErrors,
} from '../../lib/types';
import { StringSelectOption as SelectOption } from '../../lib/formik';
import UnitSelector from '../Settings/Units/UnitSelector';
import Button, { BUTTON_TYPES } from '../Button';

/*
 * Component for rendering form fields for a Block of type FieldInput.
 * Conforms to TypedFieldSet, see comments in useBlockComponents.js
 */
interface FieldInputFieldSetProps {
  content: DraftFieldInputBlock;
  contentErrors: FieldInputBlockContentErrors;
  disabledFields?: unknown;
  blockTitle?: string;
  path: string;
  allowedInputTypes?: Array<string>;
  includeSummaryButton?: boolean;
  setFieldValue?: FormikHelpers<FormikValues>['setFieldValue'];
}
// TODO: Rename to FieldSetFieldInput
const FieldInputFieldSet = React.memo(
  ({
    content,
    contentErrors,
    disabledFields = {},
    blockTitle = 'Field Input',
    path,
    allowedInputTypes,
    includeSummaryButton = true,
    setFieldValue = () => null, // TODO: Why is the default function a noop?
  }: FieldInputFieldSetProps) => {
    const showsRule = useMemo(() => {
      return procedureUtil.fieldInputHasRule((content as DraftFieldInputNumberBlock).rule);
    }, [content]);

    const getFieldPath = useCallback(
      (fieldName) => {
        if (!path) {
          return fieldName;
        }
        return `${path}.${fieldName}`;
      },
      [path]
    );

    const selectValue = useMemo(() => {
      const values = (content as DraftFieldInputCustomListBlock | DraftFieldInputMultipleChoiceBlock).options;
      if (!Array.isArray(values)) {
        return [];
      }
      return values.map((value) => ({
        value,
        label: value,
      }));
    }, [content]);

    const isInputTypeWithRule = useMemo(() => {
      const inputType = content.inputType.toLowerCase();
      return fieldInputTypes[inputType].allowsRules === true;
    }, [content.inputType]);

    const onAddRule = useCallback(() => {
      setFieldValue(`${path}.rule`, {
        value: '',
        op: '=',
      });
    }, [setFieldValue, path]);

    const onRemoveRule = useCallback(() => {
      setFieldValue(`${path}.rule`, {
        value: '',
        op: '',
      });
    }, [setFieldValue, path]);

    const isRuleDisabled = useMemo(() => {
      if (getIn(disabledFields, 'rule')) {
        return true;
      }
      return false;
    }, [disabledFields]);

    const showsFieldSetRule = useMemo(() => {
      return isInputTypeWithRule && showsRule;
    }, [isInputTypeWithRule, showsRule]);

    const showsAddRuleButton = useMemo(() => {
      return isInputTypeWithRule && !showsRule && !isRuleDisabled;
    }, [isInputTypeWithRule, showsRule, isRuleDisabled]);

    const showsRemoveRuleButton = useMemo(() => {
      return isInputTypeWithRule && showsRule && !isRuleDisabled;
    }, [isInputTypeWithRule, showsRule, isRuleDisabled]);

    const onChangeValueField = useCallback(
      (value, path) => {
        const valueDataType = 'float'; // field input number is float by default
        validateUtil.updateValueField(value, path, valueDataType, setFieldValue);
      },
      [setFieldValue]
    );

    const isUnitTypeRequired = useMemo(() => {
      const inputType = content.inputType.toLowerCase();
      return fieldInputTypes[inputType].unitsRequired === true;
    }, [content.inputType]);

    const isInputTypeWithUnits = useMemo(() => {
      const inputType = content.inputType.toLowerCase();
      return fieldInputTypes[inputType].allowsUnits === true;
    }, [content.inputType]);

    const isExternalItemType = useMemo(() => {
      const inputType = content.inputType.toLowerCase();
      return inputType === 'external_item';
    }, [content.inputType]);

    const isExternalSearchType = useMemo(() => {
      const inputType = content.inputType.toLowerCase();
      return inputType === 'external_search';
    }, [content.inputType]);

    const isTimestampType = useMemo(() => {
      const inputType = content.inputType.toLowerCase();
      return inputType === 'timestamp';
    }, [content.inputType]);

    const isSelectType = useMemo(() => {
      const inputType = content.inputType.toLowerCase();
      return inputType === 'select';
    }, [content.inputType]);

    const isListType = useMemo(() => {
      const inputType = content.inputType.toLowerCase();
      return inputType === 'list' || inputType === 'datetime';
    }, [content.inputType]);

    const isMultipleChoiceType = useMemo(() => {
      return content.inputType.toLowerCase() === 'multiple_choice';
    }, [content.inputType]);

    // Hack: TODO: create BlockRedlineHiddenFields instead
    const isSummaryHidden = useMemo(() => getIn(disabledFields, 'include_in_summary'), [disabledFields]);

    const onChangeExternalType = useCallback(
      (type, dictionaryId) => {
        const fieldValue = {
          ...content,
          external_item_type: type,
          dictionary_id: dictionaryId,
        };
        setFieldValue(path, fieldValue);
      },
      [setFieldValue, content, path]
    );

    const onChangeExternalSearchType = useCallback(
      (type) => {
        setFieldValue(getFieldPath('external_search_type'), type);
      },
      [setFieldValue, getFieldPath]
    );

    const onChangeInputType = useCallback(
      (event) => {
        const value = event.target.value;
        setFieldValue(getFieldPath('inputType'), value);
      },
      [setFieldValue, getFieldPath]
    );

    const onChangeListType = useCallback(
      (value) => {
        setFieldValue(getFieldPath('list'), value);
      },
      [setFieldValue, getFieldPath]
    );

    const onChangeSelectOptions = useCallback(
      (value) => {
        setFieldValue(getFieldPath('options'), value);
      },
      [setFieldValue, getFieldPath]
    );

    const onChangeDateTimeType = useCallback(
      (event) => {
        setFieldValue(getFieldPath('dateTimeType'), event.target.value);
      },
      [setFieldValue, getFieldPath]
    );

    const onChangeUnits = useCallback(
      (value: SelectOption | undefined) => {
        setFieldValue(getFieldPath('units'), value?.label);
      },
      [setFieldValue, getFieldPath]
    );

    const validateUnits = useMemo(() => {
      if (!isUnitTypeRequired) {
        return null;
      }
      return validateUtil.validateFieldInputUnits;
    }, [isUnitTypeRequired]);

    const filteredInputTypes = useMemo(() => {
      if (allowedInputTypes) {
        return Object.keys(fieldInputTypes)
          .filter((key) => allowedInputTypes.includes(key))
          .reduce((obj, key) => {
            obj[key] = fieldInputTypes[key];
            return obj;
          }, {});
      } else {
        return fieldInputTypes;
      }
    }, [allowedInputTypes]);

    const hasError = useMemo(() => {
      return (
        contentErrors &&
        [
          'name',
          'options',
          'list',
          'external_item_type',
          'external_search_type',
          'rule',
          'units',
          'text',
          'redline',
          'part_revision_id',
          'detail_id',
        ].some((key) => key in contentErrors)
      );
    }, [contentErrors]);

    return (
      <fieldset className="grow">
        <div className="flex flex-wrap">
          {/* Field input name */}
          <div className="flex flex-row flex-wrap">
            <div className="flex flex-col mr-2 items-start">
              {/* Block Title  */}
              <span className="field-title">{blockTitle}</span>
              <Field name={path ? `${path}.name` : 'name'} validate={validateUtil.validateFieldInputName}>
                {({ field }) => (
                  <>
                    <TextAreaAutoHeight
                      placeholder="Input name*"
                      disabled={getIn(disabledFields, 'name') ? true : null}
                      {...field}
                      style={{ minWidth: '20rem' }}
                    />
                    {contentErrors && contentErrors.name && <div className="text-red-700">{contentErrors.name}</div>}
                  </>
                )}
              </Field>
            </div>
            {/* Field input type */}
            <div className="flex flex-col mr-2 items-start">
              <span className="field-title">Type</span>
              <Field
                name={getFieldPath('inputType')}
                as="select"
                className="text-sm border border-gray-400 rounded disabled:bg-gray-300"
                disabled={getIn(disabledFields, 'inputType') ? true : null}
                onChange={onChangeInputType}
              >
                {Object.keys(filteredInputTypes).map((inputType) => (
                  <option key={inputType} value={inputType}>
                    {filteredInputTypes[inputType].displayName}
                  </option>
                ))}
              </Field>
            </div>

            {(isSelectType || isMultipleChoiceType) && (
              <div className="flex flex-col mr-2 items-start w-64">
                <span className="field-title">Options</span>
                <div className="w-64 text-sm">
                  <Field
                    name={getFieldPath('options')}
                    value={selectValue}
                    component={FieldSetMultiSelectCreatable}
                    placeholder="Create options*"
                    ariaLabel="Set options"
                    isDisabled={getIn(disabledFields, 'options')}
                    onChange={onChangeSelectOptions}
                  />
                </div>
                {contentErrors &&
                  (contentErrors as SelectInputBlockContentErrors | MultipleChoiceInputBlockContentErrors).options && (
                    <div className="text-red-700">
                      {(contentErrors as SelectInputBlockContentErrors | MultipleChoiceInputBlockContentErrors).options}
                    </div>
                  )}
              </div>
            )}

            {isTimestampType && (
              <div className="flex flex-col mr-2 items-start">
                <span className="field-title">Format</span>
                <Field
                  name={getFieldPath('dateTimeType')}
                  as="select"
                  className="text-sm border border-gray-400 rounded disabled:bg-gray-300"
                  onChange={onChangeDateTimeType}
                  disabled={getIn(disabledFields, 'dateTimeType') ? true : null}
                >
                  <option value="date">Date</option>
                  <option value="time">Time</option>
                  <option value="datetime">Date & Time</option>
                </Field>
              </div>
            )}

            {isListType && (
              <div className="flex flex-col mr-2 items-start w-64">
                <span className="field-title">List Name</span>
                <div className="w-64 text-sm">
                  <Field
                    name={getFieldPath('list')}
                    value={(content as DraftFieldInputSettingsListBlock).list}
                    component={ListSelectFieldSet}
                    onChange={onChangeListType}
                    isDisabled={getIn(disabledFields, 'list') ? true : null}
                  />
                </div>
                {contentErrors && (contentErrors as ListInputBlockContentErrors).list && (
                  <div className="text-red-700">{(contentErrors as ListInputBlockContentErrors).list}</div>
                )}
              </div>
            )}

            {/* Field input external data type */}
            {/* TODO: Cleanup and refactor into smaller components */}
            {isExternalItemType && (
              <div className="flex flex-col mr-2 items-start w-64">
                <span className="field-title">Data Type</span>
                <div className="w-64 text-sm">
                  <Field
                    name={getFieldPath('item_type')}
                    value={(content as DraftFieldInputExternalDataBlock).external_item_type}
                    dictionaryId={(content as DraftFieldInputExternalDataBlock).dictionary_id}
                    component={ExternalTypeSelectFieldSet}
                    onChange={onChangeExternalType}
                    isDisabled={getIn(disabledFields, 'external_item_type') ? true : null}
                  />
                </div>
                {contentErrors && (contentErrors as ExternalItemInputBlockContentErrors).external_item_type && (
                  <div className="text-red-700">
                    {(contentErrors as ExternalItemInputBlockContentErrors).external_item_type}
                  </div>
                )}
              </div>
            )}

            {/* Field input external search type */}
            {isExternalSearchType && (
              <Field
                name={getFieldPath('item_type')}
                value={(content as DraftFieldInputExternalSearchBlock).external_search_type}
                component={ExternalSearchFieldSet}
                onChange={onChangeExternalSearchType}
                isDisabled={getIn(disabledFields, 'external_search_type') ? true : false}
                contentErrors={contentErrors}
              />
            )}

            {/* Field input units description */}
            {isInputTypeWithUnits && (
              <div className="flex flex-col mr-2 items-start">
                <span className="field-title">Units</span>
                <Field name={path ? `${path}.units` : 'units'} validate={validateUnits}>
                  {({ field }) => (
                    <div style={{ minWidth: '14rem' }}>
                      <UnitSelector
                        name={field.name}
                        value={field.value}
                        isDisabled={getIn(disabledFields, 'name') ? true : false}
                        onChange={onChangeUnits}
                        required={isUnitTypeRequired}
                      />
                      {contentErrors && (contentErrors as NumberFieldInputBlockContentErrors).units && (
                        <div className="text-red-700">
                          {(contentErrors as NumberFieldInputBlockContentErrors).units}
                        </div>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            )}
          </div>

          {showsFieldSetRule && (
            <FieldSetRule
              path={path ? `${path}.rule` : 'rule'}
              rule={content && (content as DraftFieldInputNumberBlock).rule}
              setFieldValue={setFieldValue}
              contentErrors={contentErrors && (contentErrors as NumberFieldSetContentErrors)}
              operationFieldName="op"
              onChangeValueField={onChangeValueField}
              isDisabled={isRuleDisabled}
              disabledFields={disabledFields}
            />
          )}

          {showsRemoveRuleButton && (
            <div className="flex flex-col">
              <div className="h-4" />
              <div className="self-center h-10">
                <Button onClick={onRemoveRule} type={BUTTON_TYPES.TERTIARY} leadingIcon="minus-circle">
                  Remove Rule
                </Button>
              </div>
              {hasError && <div />}
            </div>
          )}

          {showsAddRuleButton && (
            <div className="flex flex-col">
              <div className="h-4" />
              <div className="self-center h-10">
                <Button onClick={onAddRule} type={BUTTON_TYPES.TERTIARY} leadingIcon="plus-circle">
                  Add Rule
                </Button>
              </div>
              {hasError && <div />}
            </div>
          )}
          {/* Include in summary checkbox */}
          {includeSummaryButton && (
            <div className={`flex flex-col ${isSummaryHidden ? 'hidden' : ''}`}>
              <div className="h-4" />
              <div className="self-center h-10 flex items-center">
                <FieldSetCheckbox
                  text="Include in Summary"
                  fieldName={path ? `${path}.include_in_summary` : 'include_in_summary'}
                  setFieldValue={setFieldValue}
                />
              </div>
              {hasError && <div />}
            </div>
          )}
        </div>
      </fieldset>
    );
  }
);

export default FieldInputFieldSet;
