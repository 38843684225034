import * as Yup from 'yup';
import telemetryExpressionUtil from 'shared/lib/telemetryExpressionUtil';
import { isSimulatedFieldNumberType } from '../telemetry/simulation';
import { SimulatedFields } from 'shared/lib/telemetry';
import CommandingService from '../api/commanding';
import RevisionsService from '../api/revisions';
import isNumber from './number';
import { isEmptyValue } from 'shared/lib/text';
import procedureUtil from './procedureUtil';
import { isEmpty, merge } from 'lodash';
import superlogin from '../api/superlogin';
import cloneDeep from 'lodash.clonedeep';
import expression from './expression';
import referenceUtil from './referenceUtil';
import revisionsUtil from './revisions';
import snippetUtil from './snippetUtil';
import { isReleased } from 'shared/lib/procedureUtil';
import { Duration } from 'luxon';
import { SUPPORTED_INPUT_DISPLAYS } from '../components/Blocks/ReferenceBlock';
import hasBuildsContent from './manufacturing';
import { hasCyclicReference } from 'shared/lib/expressionUtil';
import { evaluate } from 'shared/lib/math';
import { getStepRedlineMap } from './redlineUtil';

const REQUIRED_ERROR = 'Required';
const DUPLICATE_CODE_ERROR = 'ID already in use';
const DUPLICATE_NAME_ERROR = 'Name already in use';
const STEP_OR_SECTION_DNE_ERROR = 'Section or step no longer exists';
const NOT_A_NUMBER_ERROR = 'Unrecognized number';
const VERSION_IN_USE_ERROR = 'Version already in use.';
const DEPENDENCIES_CYCLIC_ERROR = 'Dependencies cannot be self-referencing.';
const CONDITIONAL_SELF_REFERENCE_ERROR = 'Conditional cannot be self-referencing.';
const DEPENDENCIES_REFERENCE_ERROR = 'Dependency no longer exists.';
const EXPRESSION_REFERENCE_ERROR = 'Expression reference(s) missing';
export const EXPRESSION_CYCLIC_ERROR = 'Expressions cannot be self-referencing.';
const RANGE_MIN_MAX_ERROR = 'Min must be less than max';
const RANGE_MIN_MAX_ERROR_ALLOW_EQUAL = 'Min must be less than or equal to max';
const NO_TOOL_SELECTED_ERROR = 'No tool selected';
const NO_USAGE_TYPE_SELECTED_ERROR = 'No usage type selected';
const NO_TEST_CASES_ERROR = 'No test points added';
const NO_LIST_SELECTED_ERROR = 'List is required';
const LINKED_PROCEDURE_NOT_RELEASED = 'Linked procedure not released';
const SIGNOFFS_REQUIRED_FOR_BUILDS_ERROR = 'Signoffs are required for steps using parts or tools';
const RISK_NOT_FOUND_ERROR = 'Risk not found';
const RISK_NOT_PROJECT_COMPATIBLE_ERROR = 'Risk not compatible with procedure project';

const ARGUMENT_TYPE_INT = 'int';
const ARGUMENT_TYPE_FLOAT = 'float';

const validateUtil = {
  _validateProcedure: async ({ procedure, teamId, procedures, redlines, snippets, risks, options }) => {
    const showRedlineValidation = options?.showRedlineValidation;
    const unresolvedRedlines =
      redlines && showRedlineValidation ? revisionsUtil.getUnresolvedRedlines(redlines, procedure) : [];
    const unresolvedRedlineComments =
      procedure.comments && showRedlineValidation ? revisionsUtil.getUnresolvedRedlineComments(procedure.comments) : [];
    const unresolvedSnippets =
      snippets && showRedlineValidation ? snippetUtil.getUnresolvedSnippets(procedure, snippets) : [];

    const procedureId = procedureUtil.getProcedureId(procedure); // strip :pending if needed

    const detailsErrors = await validateUtil._validateProcedureDetails(procedure, procedureId, teamId, procedures);
    const variableErrors = await validateUtil.validateProcedureVariables(procedure.variables);
    const riskErrors = await validateUtil._validateProcedureRisks(procedure, risks);
    const partBuildErrors = await validateUtil.validatePartBuild(procedure.part_list);
    const headersErrors = await validateUtil._validateHeadersList(procedure, unresolvedRedlines);

    const sectionsErrors = { sections: {} };

    // TODO: split validation functionality from update functionality
    const updatedProcedure = await validateUtil.validateAutomationList(procedure, sectionsErrors);

    await validateUtil._validateSectionsList(
      updatedProcedure ?? procedure,
      teamId,
      unresolvedRedlines,
      unresolvedRedlineComments,
      unresolvedSnippets,
      showRedlineValidation,
      sectionsErrors,
      procedures
    );
    const errors = {
      ...detailsErrors,
      ...headersErrors,
      ...variableErrors,
      ...riskErrors,
      ...partBuildErrors,
      ...sectionsErrors,
    };
    return { errors, updatedProcedure };
  },

  validateProcedureDetails: async (procedure, teamId, procedures, ignoreCode = false) => {
    const procedureId = procedureUtil.getProcedureId(procedure);
    const detailsErrors = await validateUtil._validateProcedureDetails(
      procedure,
      procedureId,
      teamId,
      procedures,
      ignoreCode
    );
    return detailsErrors;
  },

  _validateProcedureDetails: async (values, procedureId, teamId, procedures, ignoreCode = false) => {
    const nameError = validateUtil._validateProcedureName(values.name);
    const codeError = ignoreCode ? {} : validateUtil._validateProcedureCode(values, procedures);
    const versionError = await validateUtil._validateProcedureVersion(values.version, procedureId, teamId);
    const errors = {
      ...nameError,
      ...codeError,
      ...versionError,
    };
    return errors;
  },

  // no need to check for unique version on duplicate because no versions exist yet
  validateProcedureDetailsOnDuplicate: (values, proceduresMetadata, ignoreCode = false) => {
    const nameError = validateUtil._validateProcedureName(values.name);
    const codeError = ignoreCode ? {} : validateUtil._validateProcedureCode(values, proceduresMetadata);
    const versionError = validateUtil._validateProcedureVersionRequired(values.version);
    const errors = {
      ...nameError,
      ...codeError,
      ...versionError,
    };
    return errors;
  },

  validateProcedureVariables: async (variables) => {
    if (!variables) {
      return { variables: [] };
    }
    const variableInputErrors = variables.map(validateUtil._validateProcedureVariable);
    const allVariableErrors = validateUtil._validateUniqueVariableNames(variables, variableInputErrors);
    return { variables: allVariableErrors };
  },

  _validateProcedureRisks: async (procedure, risks) => {
    if (!procedure.risks) {
      return { risks: [] };
    }
    return {
      risks: procedure.risks.map((procedureRisk) =>
        validateUtil._validateProcedureRisk(procedureRisk, risks, procedure.project_id)
      ),
    };
  },

  _validateProcedureRisk: (procedureRisk, risks, project_id) => {
    if (!procedureRisk?.id) {
      return REQUIRED_ERROR;
    }
    const found = risks.find((risk) => risk.id === procedureRisk.id);
    if (!found) {
      return RISK_NOT_FOUND_ERROR;
    }
    if ((project_id || found.projectId) && found.projectId !== project_id) {
      return RISK_NOT_PROJECT_COMPATIBLE_ERROR;
    }
  },

  validatePartBuild: async (partBuild) => {
    if (!partBuild) {
      return {};
    }
    const errors = {};
    if (!partBuild.part_id) {
      errors.part_list = { part_id: REQUIRED_ERROR };
    }
    return errors;
  },

  _validateProcedureName: (name) => {
    if (!name) {
      return { name: REQUIRED_ERROR };
    }
    if (name.trim().length === 0) {
      return { name: REQUIRED_ERROR };
    }
    return {};
  },

  _validateProcedureVariable: (variable) => {
    if ('version' in variable) {
      return validateUtil._validateInput(variable);
      // handles backwards compatible procedure variable validation
    } else {
      if (!variable.name) {
        return { name: REQUIRED_ERROR };
      }
    }
    return {};
  },

  // checks and create errors for duplicate variable names
  _validateUniqueVariableNames: (variables, errors) => {
    const seen = new Set();
    const updatedErrors = cloneDeep(errors);
    variables.forEach((variable, index) => {
      const name = variable.name.toLowerCase();
      if (seen.has(name) && name !== '') {
        updatedErrors[index].name = DUPLICATE_NAME_ERROR;
      } else {
        seen.add(name);
      }
    });
    return updatedErrors;
  },

  _getCodeForComparison: (code) => code?.trim().toLowerCase(),

  _validateProcedureCode: (procedure, proceduresMetadata) => {
    if (!procedure.code) {
      return { code: REQUIRED_ERROR };
    }
    if (procedure.code.trim().length === 0) {
      return { code: REQUIRED_ERROR };
    }
    if (!proceduresMetadata) {
      throw new Error('Could not validate procedure code. Missing procedures metadata.');
    }
    const code = validateUtil._getCodeForComparison(procedure.code);
    const procedureIdStripped = procedureUtil.getProcedureId(procedure);

    const duplicateCode = Object.values(proceduresMetadata).some((compareProcedure) => {
      return (
        validateUtil._getCodeForComparison(compareProcedure.code) === code &&
        procedureUtil.getProcedureId(compareProcedure) !== procedureIdStripped &&
        compareProcedure.archived !== true
      );
    });
    if (duplicateCode) {
      return { code: DUPLICATE_CODE_ERROR };
    }
    return {};
  },

  _validateProcedureVersionRequired: (version) => {
    if (!version) {
      return { version: REQUIRED_ERROR };
    }
    return {};
  },

  _validateProcedureVersion: async (version, procedureId, teamId) => {
    const error = validateUtil._validateProcedureVersionRequired(version);
    if (!isEmpty(error)) {
      return error;
    }

    // Check unique version string.
    const service = new RevisionsService(teamId, superlogin);
    const isReleased = await service.isReleasedVersion(procedureId, version);
    if (isReleased) {
      return { version: VERSION_IN_USE_ERROR };
    }

    return {};
  },

  _validateHeadersList: async (procedure, redlines) => {
    const errors = { headers: {} };
    for (const header of procedure.headers) {
      const headerRedlines = redlines.filter(
        (redline) => redline.type === 'header_redline' && redline.header_redline.header.id === header.id
      );
      errors.headers[header.id] = await validateUtil._validateHeader(header, headerRedlines);
    }
    return errors;
  },

  _validateHeader: async (values, headerRedlines) => {
    const nameError = validateUtil._validateHeaderName(values.name);
    const redlineNameError = validateUtil._validateHeaderRedlineFieldName(headerRedlines);
    const headerRedlineContentIds = await validateUtil._getHeaderRedlineContentIds(headerRedlines);

    const contentErrors = await validateUtil._validateContent({
      content: values.content,
      redlineContentIds: headerRedlineContentIds,
    });
    const errors = {
      ...nameError,
      ...redlineNameError,
      ...contentErrors,
    };
    return errors;
  },

  //Validates a Redline Header Field
  _validateHeaderRedlineFieldName: (headerRedlines) => {
    if (headerRedlines.some((redline) => redline.header_redline.field === 'name')) {
      return { redlineName: REQUIRED_ERROR };
    }
    return {};
  },

  _validateSectionHeadersList: async (section) => {
    const errors = {};
    if (!section.headers) {
      return errors;
    }
    const headerErrors = {};
    for (const header of section.headers) {
      const headerError = await validateUtil._validateSectionHeader(header);
      if (!isEmpty(headerError)) {
        headerErrors[header.id] = headerError;
      }
    }

    if (!isEmpty(headerErrors)) {
      errors.headers = headerErrors;
    }
    return errors;
  },

  _validateSectionHeader: async (values) => {
    const nameError = validateUtil._validateHeaderName(values.name);
    /*
     * The second argument of validateContent is only needed to validate "jump to"
     * links, which is a type we don't yet support in the headers.
     */
    const contentErrors = await validateUtil._validateContent({ content: values.content });
    const errors = {
      ...nameError,
      ...contentErrors,
    };
    return errors;
  },

  _validateStepHeadersList: async (step) => {
    const errors = {};
    if (!step.headers) {
      return errors;
    }
    const headerErrors = {};
    for (const header of step.headers) {
      const headerError = await validateUtil._validateStepHeader(header);
      if (!isEmpty(headerError)) {
        headerErrors[header.id] = headerError;
      }
    }

    if (!isEmpty(headerErrors)) {
      errors.headers = headerErrors;
    }
    return errors;
  },

  _validateStepHeader: async (header) => {
    if (!header) {
      return {};
    }
    /*
     * The second argument of validateContent is only needed to validate "jump to"
     * links, which is a type we don't yet support in the step headers.
     */
    return validateUtil._validateContent({ content: header.content });
  },

  _validateHeaderName: (name) => {
    if (!name) {
      return { name: REQUIRED_ERROR };
    }
    return {};
  },

  _validateSectionsList: async (
    procedure,
    teamId,
    redlines,
    redlineComments,
    unresolvedSectionStepSnippetIds,
    showRedlineValidation,
    sectionsErrors,
    procedures
  ) => {
    const sectionAndStepIds = validateUtil._getAllSectionAndStepIds(procedure);
    const procedureMap = validateUtil.getProcedureMap(procedure);

    for (const section of procedure.sections) {
      const { errors: sectionErrors } = await validateUtil.validateSection({
        section,
        teamId,
        stepAndSectionIds: sectionAndStepIds,
        procedureMap,
        redlines,
        redlineComments,
        unresolvedSectionStepSnippetIds,
        showRedlineValidation,
        procedures,
      });
      sectionsErrors.sections[section.id] = merge({}, sectionsErrors.sections[section.id], sectionErrors);
    }
  },

  _getAllSectionAndStepIds: (procedure) => {
    const ids = [];
    procedure.sections.forEach((section) => {
      ids.push(section.id);
      section.steps.forEach((step) => {
        ids.push(step.id);
      });
    });
    return ids;
  },

  // Returns a map of all sections, steps, step content, and variables by respective id.
  getProcedureMap: (procedure) => {
    const procedureMap = {};
    const stepIdToSectionIdMap = {};

    procedure.sections.forEach((section) => {
      procedureMap[section.id] = section;

      section.steps.forEach((step) => {
        procedureMap[step.id] = step;
        stepIdToSectionIdMap[step.id] = section.id;

        step.content.forEach((content) => {
          procedureMap[content.id] = content;
        });
      });
    });

    if (procedure.variables) {
      procedure.variables.forEach((variable) => {
        procedureMap[variable.id] = variable;
      });
    }

    return { ...procedureMap, stepIdToSectionIdMap };
  },

  /**
   * Validates a section in a procedure
   *
   * @param {object} params
   * @param {object} params.section
   * @param {string} params.teamId
   * @param {object} params.procedures
   * @param {object} [params.stepAndSectionIds]
   * @param {object} [params.procedureMap]
   * @param {Array<object>} [params.redlines]
   * @param {Array<object>} [params.redlineComments]
   * @param {Array<object>} [params.unresolvedSectionStepSnippetIds]
   * @param {boolean} [params.showRedlineValidation]
   * @returns {Promise<{errors: object}>} - The validation errors
   */
  validateSection: async ({
    section,
    teamId,
    procedures,
    stepAndSectionIds = [],
    procedureMap = {},
    redlines = [],
    redlineComments = [],
    unresolvedSectionStepSnippetIds = [],
    showRedlineValidation = false,
  }) => {
    const unresolvedSnippetId = unresolvedSectionStepSnippetIds?.filter((snippetId) => snippetId === section.id);
    const sectionDetailsErrors = validateUtil.validateSectionDetails(section, unresolvedSnippetId);
    const sectionHeaderErrors = await validateUtil._validateSectionHeadersList(section);
    const sectionDepencencyErrors = validateUtil._validateDependency(section, procedureMap);
    const stepsListErrors = await validateUtil._validateStepsList(
      section.steps,
      teamId,
      stepAndSectionIds,
      procedureMap,
      redlines,
      redlineComments,
      unresolvedSectionStepSnippetIds,
      showRedlineValidation,
      procedures
    );
    const errors = {
      ...sectionDetailsErrors,
      ...sectionHeaderErrors,
      ...sectionDepencencyErrors,
      ...stepsListErrors,
    };
    return { errors };
  },

  validateSectionDetails: (values, unresolvedSnippetId) => {
    const snippetError = validateUtil._validateUnresolvedSnippet(unresolvedSnippetId);
    const nameError = validateUtil._validateSectionName(values.name);
    const errors = {
      ...nameError,
      ...snippetError,
    };
    return errors;
  },

  _validateUnresolvedSnippet: (unresolvedSnippetId) => {
    if (unresolvedSnippetId?.length > 0) {
      return { unresolvedSnippet: REQUIRED_ERROR };
    }
    return {};
  },

  _validateSectionName: (name) => {
    if (!name) {
      return { name: REQUIRED_ERROR };
    }
    return {};
  },

  _validateStepsList: async (
    steps,
    teamId,
    sectionAndStepIds,
    procedureMap,
    redlines,
    redlineComments,
    unresolvedSectionStepSnippetIds,
    showRedlineValidation,
    procedures
  ) => {
    const allStepErrors = {};
    const stepRedlineMap = getStepRedlineMap(redlines);
    for (const step of steps) {
      const stepRedlines = stepRedlineMap.get(step.id);
      const stepRedlineComments = redlineComments?.filter((redlineComment) => redlineComment.reference_id === step.id);
      const { errors: stepErrors } = await validateUtil.validateStep({
        step,
        teamId,
        sectionAndStepIds,
        procedureMap,
        procedures,
        stepRedlines,
        stepRedlineComments,
        unresolvedSectionStepSnippetIds,
        showRedlineValidation,
      });
      if (!isEmpty(stepErrors)) {
        allStepErrors[step.id] = stepErrors;
      }
    }

    if (isEmpty(allStepErrors)) {
      return {};
    }

    return { steps: allStepErrors };
  },

  // There will only be keys named `headers` or `content` if the corresponding header/content contains a content/block that contains an error
  validateStep: async ({
    step,
    teamId,
    procedures,
    sectionAndStepIds = [],
    procedureMap = {},
    stepRedlines = [],
    stepRedlineComments = [],
    unresolvedSectionStepSnippetIds = [],
    showRedlineValidation = false,
  }) => {
    const unresolvedStepSnippetId = unresolvedSectionStepSnippetIds.filter((snippetId) => snippetId === step.id);
    const snippetError = validateUtil._validateUnresolvedSnippet(unresolvedStepSnippetId);
    const nameError = validateUtil._validateStepName(step.name);
    const durationError = validateUtil._validateStepDuration(step.duration);
    const timerError = validateUtil._validateStepTimer(step.timer);
    const expectedDurationError = validateUtil._validateStepDuration(step.expected_duration, true);
    const signoffsError = validateUtil._validateSignoffs(step);
    const stepHeaderErrors = await validateUtil._validateStepHeadersList(step);
    const stepDependencyErrors = validateUtil._validateDependency(step, procedureMap);
    const stepConditionalErrors = validateUtil._validateStepConditionals(step, procedureMap);
    const stepRedlineErrors = validateUtil._validateRedlines(stepRedlines);
    const stepRedlineCommentsErrors = await validateUtil._validateRedlineCommentsList(stepRedlineComments);
    const addedStepDuringRunErrors = await validateUtil._validateAddedStepDuringRun(step, showRedlineValidation);
    const contentErrors = await validateUtil._validateContent({
      content: step.content,
      teamId,
      sectionAndStepIds,
      procedureMap,
      procedures,
    });
    const errors = {
      ...snippetError,
      ...addedStepDuringRunErrors,
      ...nameError,
      ...durationError,
      ...expectedDurationError,
      ...timerError,
      ...signoffsError,
      ...stepHeaderErrors,
      ...stepDependencyErrors,
      ...stepConditionalErrors,
      ...stepRedlineCommentsErrors,
      ...contentErrors,
      ...stepRedlineErrors,
    };
    return { errors };
  },

  _validateSignoffs: (step) => {
    if (hasBuildsContent(step) && step.signoffs.length < 1) {
      return { signoffs: SIGNOFFS_REQUIRED_FOR_BUILDS_ERROR };
    }
    return {};
  },

  _validateAddedStepDuringRun: async (values, showRedlineValidation) => {
    const errors = {};
    if (!values.created_during_run || !showRedlineValidation) {
      return errors;
    }
    return { stepAddedDuringRun: REQUIRED_ERROR };
  },

  _validateRedlines: (redlines) => {
    if (redlines?.length > 0) {
      return { redline: REQUIRED_ERROR };
    }
    return {};
  },

  _validateRedlineCommentsList: async (redlineComments) => {
    const errors = {};
    if (!redlineComments || redlineComments.length === 0) {
      return errors;
    }

    //Add all Redline Comments to errors
    for (const redlineComment of redlineComments) {
      errors[redlineComment.id] = REQUIRED_ERROR;
    }

    return { comments: errors };
  },

  _validateStepName: (name) => {
    const nameError = validateUtil.validateFieldStepName(name);
    return {
      ...(nameError ? { name: nameError } : {}),
    };
  },

  // Validates a formik field
  validateFieldStepName: (name) => {
    if (!name) {
      return REQUIRED_ERROR;
    }
    return null;
  },

  // Validates a formik field
  validateFieldHeaderName: (name) => {
    if (!name) {
      return REQUIRED_ERROR;
    }
    return null;
  },

  _validateStepDuration: (duration, isExpectedDuration = false) => {
    if (!duration) {
      // duration not required
      return null;
    }

    if (typeof duration === 'object') {
      return null;
    }

    const durationRegEx = /^\d{2}:\d{2}:\d{2}$/;
    if (!duration.match(durationRegEx)) {
      if (isExpectedDuration) {
        return { expected_duration: 'Enter HH:MM:SS' };
      }
      return { duration: 'Enter HH:MM:SS' };
    }
    return {};
  },

  _validateStepTimer: (timer) => {
    if (typeof timer === 'object') {
      const timeLeft = Duration.fromISO(timer.time_left);
      if (!timeLeft.isValid) {
        return { timer: 'Enter HH:MM:SS' };
      }
    }
    return {};
  },

  _validateDependency: (blockToValidate, procedureMap = {}) => {
    if (!Object.keys(procedureMap).length) {
      return {};
    }

    if (!validateUtil._hasDependencies(blockToValidate)) {
      return {};
    }

    const dependentIds = [];
    const dependentVisitedSet = new Set();

    blockToValidate.dependencies.forEach((dependency) => {
      dependentIds.push(...dependency.dependent_ids);
      dependency.dependent_ids.forEach((dependent_id) => dependentVisitedSet.add(dependent_id));
    });

    while (dependentIds.length) {
      const dependentId = dependentIds.pop();

      /**
       * dependency is cyclic if it references itself.
       */
      if (dependentId === blockToValidate.id) {
        return { dependencies: DEPENDENCIES_CYCLIC_ERROR };
      }

      const dependent = procedureMap[dependentId];

      /**
       * Dependency has a reference error if step it references in dependents
       * is not available
       */
      if (!dependent) {
        return { dependencies: DEPENDENCIES_REFERENCE_ERROR };
      }

      dependentVisitedSet.add(dependentId);

      if (validateUtil._hasDependencies(dependent)) {
        dependent.dependencies.forEach((dependency) => {
          dependency.dependent_ids.forEach((dependentId) => {
            if (!dependentVisitedSet.has(dependentId)) {
              dependentIds.push(dependentId);
              dependentVisitedSet.add(dependentId);
            }
          });
        });
      }

      if (dependent['steps']) {
        // Dependent is a section, we expand section to include all of its steps as dependents
        for (const step of dependent['steps']) {
          if (!dependentVisitedSet.has(step.id)) {
            dependentIds.push(step.id);
            dependentVisitedSet.add(dependentId);
          }
        }
      } else {
        const sectionId = procedureMap.stepIdToSectionIdMap[dependentId];
        const section = procedureMap[sectionId];
        if (validateUtil._hasDependencies(section)) {
          // Dependent is a step, we add the parent section as an implicit dependency if needed
          if (!dependentVisitedSet.has(sectionId)) {
            dependentIds.push(sectionId);
            dependentVisitedSet.add(sectionId);
          }
          // Add any explicit parent section dependencies too
          section.dependencies.forEach((dependency) => {
            dependency.dependent_ids.forEach((dependentId) => {
              if (!dependentVisitedSet.has(dependentId)) {
                dependentIds.push(dependentId);
                dependentVisitedSet.add(dependentId);
              }
            });
          });
        }
      }
    }

    return {};
  },

  validateAutomationList: (procedure, sectionsErrors) => {
    if (procedure.automation_enabled) {
      const copiedProcedure = cloneDeep(procedure);
      const seenStepIds = new Set();
      copiedProcedure.sections.forEach((section) => {
        section.steps.forEach((step) => {
          seenStepIds.add(step.id);

          if (!step.dependencies || step.dependencies.length < 1) {
            return;
          }

          const hasIncorrectDependency = step.dependencies.some(
            (dep) => !dep.dependent_ids.every((depId) => seenStepIds.has(depId))
          );

          if (hasIncorrectDependency) {
            if (!sectionsErrors.sections[section.id]) {
              sectionsErrors.sections[section.id] = { steps: {} };
            }
            if (!sectionsErrors.sections[section.id].steps[step.id]) {
              sectionsErrors.sections[section.id].steps[step.id] = {};
            }

            sectionsErrors.sections[section.id].steps[step.id].dependencies = 'Dependencies must follow linear flow';
          }
        });
      });
      return copiedProcedure;
    }
    return null;
  },
  /**
   * Detect if the given edge would create a cycle if added to the graph.
   *
   * The graph is represented as a list of edges given in the conditionals map,
   * which is required to not contain any cycles itself.
   *
   * @param {String} sourceId - Node id of the source of the edge.
   * @param {String} targetId - Node id of the target of the edge.
   * @param {Map<String, Set<Object>>} sourceConditionalsMap - Map that represents
   *   the graph of step conditionals. Key is step id, and value is the list of
   *   conditionals that link to the given step.
   */
  conditionalCreatesCycle: (sourceId, targetId, sourceConditionalsMap) => {
    /**
     * We detect a cycle by walking "up" the graph and visiting all nodes to all
     * root nodes. Then, there is a cycle if the targetId is already visited.
     */
    const visited = new Set();
    const sourceIds = [sourceId];
    while (sourceIds.length) {
      const sourceId = sourceIds.pop();
      visited.add(sourceId);

      if (!sourceId) {
        continue;
      }
      const sourceConditionals = sourceConditionalsMap[sourceId];
      if (!sourceConditionals) {
        continue;
      }
      sourceConditionals.forEach((conditional) => {
        sourceIds.push(conditional.source_id);
      });
    }
    return visited.has(targetId);
  },

  _validateStepConditionals: (step, procedureMap = {}) => {
    if (!step.conditionals) {
      return {};
    }

    const errors = [];
    step.conditionals.forEach((conditional, index) => {
      if (!conditional.target_id) {
        errors[index] = REQUIRED_ERROR;
      } else if (!procedureMap[conditional.target_id]) {
        errors[index] = DEPENDENCIES_REFERENCE_ERROR;
      } else if (conditional.target_id === step.id) {
        errors[index] = CONDITIONAL_SELF_REFERENCE_ERROR;
      }
    });
    if (!errors.length) {
      return {};
    }
    return { conditionals: errors };
  },

  _hasDependencies: (block) => {
    return block.dependencies?.some((dependency) => dependency.dependent_ids?.length);
  },

  /**
   * @param {{
   *   content: any;
   *   redlineContentIds?: Set<string>;
   *   teamId?: string;
   *   sectionAndStepIds?: Array<string>;
   *   procedureMap?: {[id: string]: object};
   *   procedures?: object;
   * }} param
   * @private
   */
  _validateContent: async ({ content, redlineContentIds, teamId, sectionAndStepIds, procedureMap, procedures }) => {
    const errors = {};

    if (!content) {
      return errors;
    }

    for (const block of content) {
      let blockError = await validateUtil._validateContentBlock(
        block,
        teamId,
        sectionAndStepIds,
        procedureMap,
        procedures
      );

      if (redlineContentIds) {
        blockError = await validateUtil._validateRedlineContentBlock(block, redlineContentIds, blockError);
      }

      // If there are no block errors, skip this block.
      if (!blockError || Object.keys(blockError).length === 0) {
        continue;
      }
      // Add content errors, preparing content error field if necessary.
      if (!errors.content) {
        errors.content = {};
      }
      errors.content[block.id] = blockError;
    }
    return errors;
  },

  _validateRedlineContentBlock: async (block, stepRedlineIds, blockError) => {
    if (stepRedlineIds.has(block.id)) {
      const redlineBlockError = {
        ...blockError,
        redline: REQUIRED_ERROR,
      };
      return redlineBlockError;
    }
    return blockError;
  },

  _getHeaderRedlineContentIds: async (headerRedlines) => {
    const headerRedlineContentIds = new Set();
    for (const redline of headerRedlines) {
      await headerRedlineContentIds.add(redline.header_redline.content_id);
    }
    return headerRedlineContentIds;
  },

  _validateContentBlock: async (block, teamId, stepAndSectionIds, procedureMap, procedures) => {
    const contentType = block.type.toLowerCase();
    if (contentType === 'telemetry') {
      return validateUtil._validateTelemetry(block);
    } else if (contentType === 'commanding') {
      return validateUtil._validateCommand(block, teamId);
    } else if (contentType === 'input') {
      return validateUtil._validateInput(block);
    } else if (contentType === 'text') {
      return validateUtil._validateText(block);
    } else if (contentType === 'alert') {
      return validateUtil._validateAlert(block);
    } else if (contentType === 'requirement') {
      return validateUtil._validateRequirement(block);
    } else if (contentType === 'procedure_link') {
      return validateUtil._validateProcedureLink(block, procedures);
    } else if (contentType === 'jump_to') {
      return validateUtil._validateJumpTo(block, stepAndSectionIds);
    } else if (contentType === 'external_item') {
      return validateUtil._validateExternalItem(block);
    } else if (contentType === 'reference') {
      return validateUtil._validateReference(block, procedureMap);
    } else if (contentType === 'expression') {
      return validateUtil._validateExpression(block, procedureMap);
    } else if (contentType === 'part_kit') {
      return {}; // all part kit configurations are valid
    } else if (contentType === 'part_build') {
      return {}; // all part build configurations are valid
    } else if (contentType === 'inventory_detail_input') {
      return validateUtil._validateInventoryDetailInput(block);
    } else if (contentType === 'tool_check_out') {
      return validateUtil._validateToolCheckOutIn(block);
    } else if (contentType === 'tool_check_in') {
      return validateUtil._validateToolCheckOutIn(block);
    } else if (contentType === 'part_usage') {
      return validateUtil._validatePartUsage(block);
    } else if (contentType === 'tool_usage') {
      return validateUtil._validateToolUsage(block);
    } else if (contentType === 'test_cases') {
      return validateUtil._validateTestCases(block);
    } else if (contentType === 'table_input') {
      return validateUtil._validateTableInput(block);
    } else if (contentType === 'field_input_table') {
      return validateUtil._validateFieldInputTable(block);
    }
    return {}; // all content types validated so shouldn't get here
  },

  _validateTelemetry: async (block) => {
    const telemetryKey = block.key.toLowerCase();
    if (telemetryKey === 'custom') {
      return validateUtil._validateTelemetryExpression(block);
    } else if (telemetryKey === 'parameter') {
      return validateUtil._validateTelemetryParameter(block);
    }
    return {};
  },

  _validateTelemetryExpression: async (block) => {
    if (!block.expression) {
      return { expression: REQUIRED_ERROR };
    }
    const errorMessage = telemetryExpressionUtil.getExpressionErrorMessage(block.expression);
    if (errorMessage !== '') {
      return { expression: errorMessage };
    }

    return {};
  },

  _validateTelemetryParameter: (block) => {
    const nameError = validateUtil._validateTelemetryParameterName(block);
    const ruleError = validateUtil._validateTelemetryParameterRule(block);
    const valuesErrors = validateUtil._validateTelemetryParameterValues(block);
    const errors = {
      ...nameError,
      ...ruleError,
      ...valuesErrors,
    };
    return errors;
  },

  _validateTelemetryParameterName: (block) => {
    if (!block.name) {
      return { name: REQUIRED_ERROR };
    }
    return {};
  },

  _validateTelemetryParameterRule: (block) => {
    // only required if value entered with no rule set
    if (!block.rule && block.value) {
      return { rule: REQUIRED_ERROR };
    }
    return {};
  },

  _validateTelemetryParameterValues: (block) => {
    if (block.rule === 'range') {
      return validateUtil._validateTelemetryRangeValues(block);
    }
    return validateUtil._validateTelemetryNotRangeValue(block);
  },

  _validateTelemetryRangeValues: (block) => {
    let minError = validateUtil._validateTelemetryValue(block.name, block.range.min, block.rule);
    const maxError = validateUtil._validateTelemetryValue(block.name, block.range.max, block.rule);

    if (minError === null && maxError === null) {
      const minValue = parseFloat(block.range.min);
      const maxValue = parseFloat(block.range.max);
      const allowMinEqualToMax = block.range.include_min && block.range.include_max;
      if (allowMinEqualToMax) {
        if (evaluate(minValue, maxValue, '>')) {
          minError = RANGE_MIN_MAX_ERROR_ALLOW_EQUAL;
        }
      } else {
        if (evaluate(minValue, maxValue, '≥')) {
          minError = RANGE_MIN_MAX_ERROR;
        }
      }
    }

    if (minError || maxError) {
      const rangeErrors = {
        ...(minError && { min: minError }),
        ...(maxError && { max: maxError }),
      };
      return { range: rangeErrors };
    }
    return {};
  },

  _validateTelemetryNotRangeValue: (block) => {
    const valueError = validateUtil._validateTelemetryValue(block.name, block.value, block.rule, block.parameter_type);
    if (valueError) {
      return { value: valueError };
    }
    return {};
  },

  // "name" needed to check if telemetry is simulated and requires number-type value
  _validateTelemetryValue: (name, value, rule, parameterType) => {
    if (value === '' || value === undefined || value === null) {
      // value only required if rule is set
      return !rule ? null : REQUIRED_ERROR;
    }

    // check if telemetry is simulated
    const simulatedField = SimulatedFields[name];
    if (simulatedField) {
      // check if input value does not match simulated telemetry type
      const isNumberType = isSimulatedFieldNumberType(simulatedField);
      if (isNumberType && !isNumber(value)) {
        return NOT_A_NUMBER_ERROR;
      }
      return null;
    }

    if (['float', 'int'].includes(parameterType)) {
      return !isNumber(value) ? NOT_A_NUMBER_ERROR : null;
    }

    return null;
  },

  _validateCommand: async (block, teamId) => {
    const nameError = validateUtil._validateCommandName(block);
    const argumentsErrors = await validateUtil._validateCommandArgumentsList(block, teamId);
    const errors = {
      ...nameError,
      ...argumentsErrors,
    };
    return errors;
  },

  _validateCommandName: (block) => {
    if (!block.name) {
      return { name: REQUIRED_ERROR };
    }
    return {};
  },

  _validateCommandArgumentsList: async (block, teamId) => {
    if (!block.arguments || block.arguments.length === 0) {
      return {};
    }
    const argumentsErrors = {};
    for (const argument of Object.entries(block.arguments)) {
      const commandName = block.name;
      const dictionaryId = block.dictionary_id;
      const [argumentName, value] = argument;
      const error = await validateUtil._validateCommandArgumentValue(
        commandName,
        dictionaryId,
        argumentName,
        value,
        teamId
      );
      if (error) {
        argumentsErrors[argumentName] = error;
      }
    }
    if (isEmpty(argumentsErrors)) {
      return {};
    }
    return { arguments: argumentsErrors };
  },

  _validateCommandArgumentValue: async (commandName, dictionaryId, argumentName, value, teamId) => {
    if (!value) {
      // value is optional
      return null;
    }
    const service = new CommandingService(teamId);
    const command = await service.getCommandByName(commandName, dictionaryId);

    const argument = command.arguments?.find((arg) => {
      return argumentName === arg.name;
    });

    const isNumberType = argument?.type === ARGUMENT_TYPE_INT || argument?.type === ARGUMENT_TYPE_FLOAT;

    if (isNumberType && !isNumber(value)) {
      return NOT_A_NUMBER_ERROR;
    }
    return null;
  },

  _validateInput: (block) => {
    const inputTypeToValidateFunction = {
      number: validateUtil._validateInputNumber,
      text: validateUtil._validateInputText,
      checkbox: validateUtil._validateInputCheckbox,
      timestamp: validateUtil._validateInputTimestamp,
      attachment: validateUtil._validateInputAttachment,
      select: validateUtil._validateInputSelect,
      external_item: validateUtil._validateInputExternalItem,
      external_search: validateUtil._validateInputExternalSearch,
      multiple_choice: validateUtil._validateInputSelect,
      list: validateUtil._validateInputList,
      sketch: validateUtil._validateSketch,
    };
    const inputType = block.inputType.toLowerCase();
    const validateInputFunction = inputTypeToValidateFunction[inputType];
    return validateInputFunction(block);
  },

  // Validates a formik field
  validateFieldInputName: (name) => {
    if (!name) {
      return REQUIRED_ERROR;
    }
    return null;
  },

  // Validates a formik field
  validateFieldInputUnits: (units) => {
    if (!units) {
      return REQUIRED_ERROR;
    }
    return null;
  },

  // Validates a formik field
  validateFieldInputOptions: (options) => {
    if (!options || options.length === 0) {
      return REQUIRED_ERROR;
    }
    return null;
  },

  _validateRequiredString: (string) => {
    if (!string) {
      return REQUIRED_ERROR;
    }
    return null;
  },

  _validateInputNumber: (block) => {
    const nameError = validateUtil.validateFieldInputName(block.name);
    const unitsError = validateUtil.validateFieldInputUnits(block.units);
    const ruleError = validateUtil.validateFieldInputRule(block.rule);
    return {
      ...(nameError && { name: nameError }),
      ...(unitsError && { units: unitsError }),
      ...(ruleError && { rule: ruleError }),
    };
  },

  validateFieldInputRule: (rule) => {
    if (isEmptyValue(rule)) {
      return null;
    }
    // Field input rules are optional, allow both op and value empty
    if (isEmptyValue(rule.op) && isEmptyValue(rule.value)) {
      return null;
    }
    if (isEmptyValue(rule.op)) {
      return { op: REQUIRED_ERROR };
    }
    if (rule.op === 'range') {
      const rangeErrors = validateUtil._validateFieldInputRange(rule.range);
      if (rangeErrors) {
        return rangeErrors;
      }
    } else if (isEmptyValue(rule.value)) {
      return { value: REQUIRED_ERROR };
    }
    return null;
  },

  _validateFieldInputRange: (range) => {
    let minError = validateUtil._validateFieldInputRangeBound(range.min);
    const maxError = validateUtil._validateFieldInputRangeBound(range.max);

    if (minError === null && maxError === null) {
      const minValue = parseFloat(range.min);
      const maxValue = parseFloat(range.max);

      const allowMinEqualToMax = range.include_min && range.include_max;
      if (allowMinEqualToMax) {
        if (evaluate(minValue, maxValue, '>')) {
          minError = RANGE_MIN_MAX_ERROR_ALLOW_EQUAL;
        }
      } else {
        if (evaluate(minValue, maxValue, '≥')) {
          minError = RANGE_MIN_MAX_ERROR;
        }
      }
    }

    if (minError || maxError) {
      const rangeErrors = {
        ...(minError && { min: minError }),
        ...(maxError && { max: maxError }),
      };
      return { range: rangeErrors };
    }
    return null;
  },

  _validateFieldInputRangeBound: (bound) => {
    if (isEmptyValue(bound)) {
      return REQUIRED_ERROR;
    }
    if (!isNumber(bound)) {
      return NOT_A_NUMBER_ERROR;
    }
    return null;
  },

  _validateInputText: (block) => {
    const nameError = validateUtil.validateFieldInputName(block.name);
    return nameError ? { name: nameError } : {};
  },

  _validateInputCheckbox: (block) => {
    const nameError = validateUtil.validateFieldInputName(block.name);
    return nameError ? { name: nameError } : {};
  },

  _validateInputTimestamp: (block) => {
    const nameError = validateUtil.validateFieldInputName(block.name);
    return nameError ? { name: nameError } : {};
  },

  _validateInputAttachment: (block) => {
    const nameError = validateUtil.validateFieldInputName(block.name);
    return nameError ? { name: nameError } : {};
  },

  _validateInputSelect: (block) => {
    const nameError = validateUtil.validateFieldInputName(block.name);
    const optionsError = validateUtil.validateFieldInputOptions(block.options);
    return {
      ...(nameError && { name: nameError }),
      ...(optionsError && { options: optionsError }),
    };
  },

  _validateInputList: (block) => {
    const nameError = validateUtil.validateFieldInputName(block.name);
    const listError = validateUtil._validateRequiredString(block.list);
    return {
      ...(nameError && { name: nameError }),
      ...(listError && { list: listError }),
    };
  },

  _validateInventoryDetailInput: (block) => {
    const partRevisionIdError = validateUtil._validateRequiredString(block.part_revision_id);
    const detailIdError = validateUtil._validateRequiredString(block.detail_id);
    return {
      ...(partRevisionIdError && { part_revision_id: partRevisionIdError }),
      ...(detailIdError && { detail_id: detailIdError }),
    };
  },

  _validateSketch: (block) => {
    const nameError = validateUtil.validateFieldInputName(block.name);
    return nameError ? { name: nameError } : {};
  },

  _validateInputExternalItem: (block) => {
    const nameError = validateUtil.validateFieldInputName(block.name);
    const typeError = validateUtil._validateRequiredString(block.external_item_type);
    return {
      ...(nameError && { name: nameError }),
      ...(typeError && { external_item_type: typeError }),
    };
  },
  _validateInputExternalSearch: (block) => {
    const nameError = validateUtil.validateFieldInputName(block.name);
    const typeError = validateUtil._validateRequiredString(block.external_search_type?.data_type);
    return {
      ...(nameError && { name: nameError }),
      ...(typeError && { external_search_type: typeError }),
    };
  },
  _validateExternalItem: (block) => {
    const typeError = validateUtil._validateRequiredString(block.item_type);
    const itemError = validateUtil._validateRequiredString(block.item_id);
    return {
      ...(typeError && { item_type: typeError }),
      ...(itemError && { item_id: itemError }),
    };
  },
  _validateReference: (block, procedureMap) => {
    let referencedBlock = procedureMap[block.reference];

    if (isNumber(block.field_index)) {
      referencedBlock = referencedBlock.fields[block.field_index];
    }

    let referenceError = !referencedBlock && 'Invalid Reference';
    if (!referenceError && block.sub_reference) {
      referenceError =
        !referenceUtil.getAllSubReferences(referencedBlock).includes(block.sub_reference) && 'Invalid Reference';
    }
    if (!referenceError) {
      referenceError =
        !referenceUtil.isReferenceEnabled(referencedBlock, Object.keys(SUPPORTED_INPUT_DISPLAYS)) &&
        'Invalid Reference';
    }
    return { ...(referenceError && { reference: referenceError }) };
  },

  _validateExpression: (block, procedureMap) => {
    const nameError = validateUtil.validateFieldInputName(block.name);
    let formulaError;
    let parseError;
    let cyclicError;

    if (!block.tokens || (block.tokens && block.tokens.length === 0)) {
      formulaError = REQUIRED_ERROR;
    } else {
      block.tokens.forEach((token) => {
        if (token.reference_id && !procedureMap[token.reference_id]) {
          formulaError = EXPRESSION_REFERENCE_ERROR;
        }
      });
      const expressionError = expression.validate(block.tokens);
      if (expressionError) {
        parseError = expressionError;
      }
      cyclicError = hasCyclicReference({
        tokens: block.tokens,
        procedureMap,
      })
        ? EXPRESSION_CYCLIC_ERROR
        : '';
    }
    return {
      ...(nameError && { name: nameError }),
      ...(formulaError && { formula: formulaError }),
      ...(parseError && { parse: parseError }),
      ...(cyclicError && { cyclic: cyclicError }),
    };
  },

  _validateText: (block) => {
    const textError = validateUtil.validateFieldText(block.text);
    return textError ? { text: textError } : {};
  },

  // Validates a formik field
  validateFieldText: (text) => {
    if (!text) {
      return REQUIRED_ERROR;
    }
    return null;
  },

  _validateAlert: (block) => {
    const textError = validateUtil.validateFieldAlert(block.text);
    return textError ? { text: textError } : {};
  },

  _validateRequirement: (block) => {
    const errors = {};

    if (!block.subType || block.subType === 'manual') {
      const labelError = validateUtil.validateNotEmpty(block.label);
      if (labelError) {
        errors.label = labelError;
      }
    } else if (block.subType === 'jama') {
      if (!block.integrationDetails || Object.keys(block.integrationDetails).length === 0) {
        errors.label = REQUIRED_ERROR;
      }
    }

    return errors;
  },

  // Validates a formik field
  validateFieldAlert: (text) => {
    if (!text) {
      return REQUIRED_ERROR;
    }
    return null;
  },

  _validateProcedureLink: (block, procedures) => {
    if (!block.procedure) {
      return { procedure: REQUIRED_ERROR };
    }

    if (
      !Object.prototype.hasOwnProperty.call(procedures, block.procedure) ||
      !isReleased(procedures[block.procedure])
    ) {
      return { procedure: LINKED_PROCEDURE_NOT_RELEASED };
    }
    return {};
  },

  _validateJumpTo: (block, stepAndSectionIds) => {
    if (!block.jumpToId) {
      return { jumpToId: REQUIRED_ERROR };
    }
    if (!stepAndSectionIds.includes(block.jumpToId)) {
      return { jumpToId: STEP_OR_SECTION_DNE_ERROR };
    }
    return {};
  },

  _validatePartUsage: (block) => {
    const errors = {};
    if (block.part && (!block.usage_types || block.usage_types.length === 0)) {
      errors.usage_types = [NO_USAGE_TYPE_SELECTED_ERROR];
    }
    return errors;
  },

  /**
   * @param {import('shared/lib/types/views/procedures').DraftToolCheckOutBlock} block
   * @returns {import('../manufacturing/components/Tools/DraftToolCheckOutIn').ContentErrors | null}
   */
  _validateToolCheckOutIn: (block) => {
    if (block.tool_id === null) {
      return { tool: NO_TOOL_SELECTED_ERROR };
    }
    return null;
  },

  /**
   * @param {import('shared/lib/types/views/procedures').DraftToolUsageBlock} block
   * @returns {import('../manufacturing/components/Tools/DraftToolUsage').ContentErrors | null}
   */
  _validateToolUsage: (block) => {
    const errors = {};
    if (block.tool_id === null) {
      errors.tool = NO_TOOL_SELECTED_ERROR;
    }
    if (block.usage_type_id === null) {
      errors.usage_type_id = NO_USAGE_TYPE_SELECTED_ERROR;
    }
    return errors;
  },

  _validateTestCases: (block) => {
    if (block.items?.length > 0) {
      return {};
    }
    return { error: NO_TEST_CASES_ERROR };
  },

  _validateTableInput: (block) => {
    for (const column of block.columns) {
      if (column.column_type === 'input' && column.input_type === 'list') {
        const listError = validateUtil._validateRequiredString(column.list);
        if (listError) {
          return { error: NO_LIST_SELECTED_ERROR };
        }
      }
    }
    return {};
  },

  _validateFieldInputTable: (block) => {
    const errors = {};
    if (block.fields.length === 0) {
      errors['name'] = 'Cannot be empty';
    }

    block.fields.forEach((field, index) => {
      const fieldErrors = validateUtil._validateInput(field);
      if (Object.keys(fieldErrors).length !== 0) {
        errors[index] = fieldErrors;
      }
    });

    return errors;
  },

  _isEmptyString: (s) => {
    if (!s) {
      return true;
    }
    // Remove invisible unicode characters not included in \s character class (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes)
    s = s.replace(/[\s\u034F\u17B5\u17B4]*/u, '');
    s = s.replace(
      /[\u00AD\u061C\u115F\u1160\u180E\u2000-\u200F\u2060-\u2064\u206A-\u206F\u2800\u3164\uFFA0\u{1D159}\u{1D173}-\u{1D17A}]*/u,
      ''
    );
    return s === '';
  },

  validateNotEmpty: (fieldValue) => {
    if (validateUtil._isEmptyString(fieldValue)) {
      return REQUIRED_ERROR;
    }
    return null;
  },

  getNonEmptyStringValidator: () => {
    return Yup.string()
      .trim()
      .required('Required')
      .test('nonemptyString', 'Required', (s) => !validateUtil._isEmptyString(s));
  },

  // Parses value field and casts to number if appropriate
  updateValueField: (value, path, valueDataType, setFieldValue) => {
    if (value === '' || value === undefined || value === null) {
      return 'Required';
    }
    switch (valueDataType) {
      case 'uint':
      case 'int':
      case 'float':
        if (isNaN(parseFloat(value))) {
          return;
        }
        /*
         * parseFloat will convert things like "3abc" to "3", so update displayed
         * value.
         */
        if (typeof value !== 'number') {
          setFieldValue(path, parseFloat(value));
        }
        break;
      default:
        // Text fields don't need validation
        break;
    }
  },

  checkForConditonals: (procedure) => {
    return procedure.sections.some((section) =>
      section.steps.some((step) => step.conditionals && step.conditionals.length > 0)
    );
  },

  getFirstProcedureDetailsError: (errors) => {
    if (!errors) {
      return null;
    }
    const fields = Object.keys(errors);
    const firstErrorField = fields.find((field) => {
      return field !== 'headers' && field !== 'sections' && field !== 'variables' && field !== 'risks';
    });
    if (firstErrorField) {
      return `procedure.${firstErrorField}`;
    }
    return null;
  },

  getFirstContentError: (step, contentErrors) => {
    /*
     *`step` could be falsy if we pass in a step header as its value for a legacy procedure that does not have step headers
     *By design, `contentErrors` should never be falsy, so this is a "just-in-case" safety check
     */
    if (!step || !contentErrors) {
      return null;
    }
    for (const block of step.content) {
      const error = contentErrors[block.id];
      if (!isEmpty(error)) {
        return `${step.id}.content[${block.id}]`;
      }
    }
    return null;
  },

  getFirstCommentError: (step, commentErrors) => {
    /*
     *`step` could be falsy if we pass in a step header as its value for a legacy procedure that does not have step headers
     *By design, `commentErrors` should never be falsy, so this is a "just-in-case" safety check
     */
    if (!step || !commentErrors) {
      return null;
    }

    for (const commentId in commentErrors) {
      const error = commentErrors[commentId];
      if (!isEmpty(error)) {
        return `comment.[${commentId}]`;
      }
    }
    return null;
  },

  getFirstStepError: (step, stepErrors, showRedlineValidation) => {
    if (stepErrors.headers) {
      if (step.headers && step.headers.length > 0) {
        const stepHeader = step.headers[0];
        const stepHeaderErrors = stepErrors.headers[stepHeader.id]; // headers must be present within this case block
        /*
         *By design, if `headers` is present, its `content` child is present and has an error
         *so there is really no need to check for the existence of `stepHeaderErrors` here,
         *except for "just-in-case" safety
         */
        if (stepHeaderErrors) {
          const stepHeaderContentError = validateUtil.getFirstContentError(stepHeader, stepHeaderErrors?.content);
          if (stepHeaderContentError) {
            return stepHeaderContentError;
          }
        }
      }
    }

    if ('unresolvedSnippet' in stepErrors) {
      const error = stepErrors['unresolvedSnippet'];
      if (error) {
        return `${step.id}.${'unresolvedSnippet'}`;
      }
    }

    if (showRedlineValidation) {
      if (stepErrors.redline) {
        return `${step.id}.top`;
      }

      if (stepErrors.stepAddedDuringRun) {
        return `${step.id}.stepAddedDuringRun`;
      }

      if (stepErrors.comments) {
        const commentError = validateUtil.getFirstCommentError(step, stepErrors?.comments);
        if (commentError) {
          return commentError;
        }
      }
    }

    if (stepErrors.name) {
      return `${step.id}.name`;
    }

    if (stepErrors.content) {
      const contentError = validateUtil.getFirstContentError(step, stepErrors.content);
      if (contentError) {
        return contentError;
      }
    }

    if (stepErrors.conditionals && stepErrors.conditionals.length > 0) {
      for (const [index, error] of stepErrors.conditionals.entries()) {
        if (!isEmpty(error)) {
          return `${step.id}.conditionals[${index}]`;
        }
      }
    }

    // Default behavior for unspecified fields
    for (const field in stepErrors) {
      if (field === 'headers' || field === 'content' || field === 'comments' || field === 'stepAddedDuringRun') {
        continue;
      }
      return `${step.id}.${field}`;
    }

    return null;
  },

  getFirstSectionHeaderError: (sectionHeader, sectionHeaderErrors) => {
    if (sectionHeaderErrors.name) {
      return `${sectionHeader.id}.name`;
    }

    if (sectionHeaderErrors.content) {
      const contentError = validateUtil.getFirstContentError(sectionHeader, sectionHeaderErrors.content);
      if (contentError) {
        return contentError;
      }
    }

    // Default behavior for unspecified fields
    for (const field in sectionHeaderErrors) {
      if (field === 'name' || field === 'content') {
        continue;
      }

      return `${sectionHeader.id}.${field}`;
    }

    return null;
  },

  getFirstProcedureVariablesError: (errors) => {
    if (!errors || !errors.variables) {
      return null;
    }
    for (const [index, variableErrors] of errors.variables.entries()) {
      if (Object.keys(variableErrors).length > 0) {
        return `variables[${index}]`;
      }
    }
    return null;
  },

  getFirstProcedureRisksError: (errors) => {
    if (!errors || !errors.risks) {
      return null;
    }
    const index = errors.risks.findIndex((risk) => risk !== undefined);
    if (index !== -1) {
      return `risks[${index}]`;
    }
    return null;
  },

  getFirstHeaderErrorId: (procedure, errors) => {
    if (!procedure || !errors) {
      return null;
    }

    if (!errors.headers) {
      return null;
    }

    const firstHeaderWithError = procedure.headers.find((header) => {
      return header.id in errors.headers && !isEmpty(errors.headers[header.id]);
    });
    if (firstHeaderWithError) {
      return firstHeaderWithError.id;
    }
    return null;
  },

  getFirstHeaderError: (procedure, errors, firstHeaderErrorId, showRedlineValidation) => {
    if (!procedure || !errors) {
      return null;
    }
    if (!firstHeaderErrorId) {
      return null;
    }
    const header = procedure.headers.find((header) => {
      return header.id === firstHeaderErrorId;
    });
    const headerErrors = errors.headers[firstHeaderErrorId];
    return validateUtil.getFirstStepError(header, headerErrors, showRedlineValidation);
  },

  getFirstSectionErrorAndIds: (section, sectionErrors, showRedlineValidation) => {
    if ('headers' in section && 'headers' in sectionErrors) {
      for (const sectionHeader of section.headers) {
        const sectionHeaderErrors = sectionErrors.headers && sectionErrors.headers[sectionHeader.id];

        const firstSectionHeaderError =
          sectionHeaderErrors && validateUtil.getFirstSectionHeaderError(sectionHeader, sectionHeaderErrors);
        if (firstSectionHeaderError) {
          return {
            firstSectionError: firstSectionHeaderError,
            sectionId: section.id,
            sectionHeaderId: sectionHeader.id,
          };
        }
      }
    }

    if ('unresolvedSnippet' in sectionErrors) {
      const error = sectionErrors['unresolvedSnippet'];
      if (error) {
        return {
          firstSectionError: `${section.id}.${'unresolvedSnippet'}`,
          sectionId: section.id,
        };
      }
    }

    if ('name' in section && 'name' in sectionErrors) {
      const error = sectionErrors['name'];
      if (error) {
        return {
          firstSectionError: `${section.id}.${'name'}`,
          sectionId: section.id,
        };
      }
    }

    if ('steps' in section && 'steps' in sectionErrors) {
      for (const step of section.steps) {
        const stepErrors = sectionErrors.steps && sectionErrors.steps[step.id];

        const firstStepError = stepErrors && validateUtil.getFirstStepError(step, stepErrors, showRedlineValidation);
        if (firstStepError) {
          return {
            firstSectionError: firstStepError,
            sectionId: section.id,
            stepId: step.id,
          };
        }
      }
    }

    for (const field in section) {
      if (field !== 'headers' && field !== 'name' && field !== 'steps') {
        const error = sectionErrors[field];
        if (error) {
          return {
            firstSectionError: `${section.id}.${field}`,
            sectionId: section.id,
          };
        }
      }
    }

    return {};
  },

  getFirstSectionErrorAndIdsOfAllSections: (procedure, errors, showRedlineValidation) => {
    if (!procedure || !errors) {
      return {};
    }
    if (!procedure.sections || !errors.sections) {
      return {};
    }
    for (const section of procedure.sections) {
      const sectionErrors = errors.sections[section.id];
      if (!sectionErrors) {
        return {};
      }
      const { firstSectionError, sectionId, stepId } = validateUtil.getFirstSectionErrorAndIds(
        section,
        sectionErrors,
        showRedlineValidation
      );
      if (firstSectionError) {
        return {
          firstSectionError,
          sectionId,
          stepId,
        };
      }
    }
    return {};
  },

  findFirstErrorField: ({
    firstProcedureDetailsError,
    firstProcedureVariablesError,
    firstProcedureRisksError,
    firstHeaderError,
    firstHeaderErrorId,
    firstSectionError,
    sectionId,
    stepId,
  }) => {
    if (firstProcedureDetailsError) {
      return { errorFieldRef: firstProcedureDetailsError };
    }

    if (firstProcedureVariablesError) {
      return { errorFieldRef: firstProcedureVariablesError };
    }

    if (firstProcedureRisksError) {
      return { errorFieldRef: firstProcedureRisksError };
    }

    if (firstHeaderError) {
      return {
        errorFieldRef: firstHeaderError,
        errorHeaderId: firstHeaderErrorId,
      };
    }

    if (firstSectionError) {
      return {
        errorFieldRef: firstSectionError,
        errorSectionId: sectionId,
        errorStepId: stepId,
      };
    }
    return {};
  },

  getFirstErrorField: ({ procedure, errors, options }) => {
    const firstProcedureDetailsError = validateUtil.getFirstProcedureDetailsError(errors);
    const firstProcedureVariablesError = validateUtil.getFirstProcedureVariablesError(errors);
    const firstProcedureRisksError = validateUtil.getFirstProcedureRisksError(errors);
    const firstHeaderErrorId = validateUtil.getFirstHeaderErrorId(procedure, errors);
    const firstHeaderError = validateUtil.getFirstHeaderError(
      procedure,
      errors,
      firstHeaderErrorId,
      options.showRedlineValidation
    );
    const { firstSectionError, sectionId, stepId } = validateUtil.getFirstSectionErrorAndIdsOfAllSections(
      procedure,
      errors,
      options.showRedlineValidation
    );

    return validateUtil.findFirstErrorField({
      firstProcedureDetailsError,
      firstProcedureVariablesError,
      firstProcedureRisksError,
      firstHeaderError,
      firstHeaderErrorId,
      firstSectionError,
      sectionId,
      stepId,
    });
  },

  getProcedureValidationResults: async ({ procedure, teamId, procedures, redlines, snippets, risks, options = {} }) => {
    const { updatedProcedure, errors } = await validateUtil._validateProcedure({
      procedure,
      teamId,
      procedures,
      redlines,
      snippets,
      risks,
      options,
    });

    const firstErrorField = validateUtil.getFirstErrorField({
      procedure,
      errors,
      options,
    });

    return {
      updatedProcedure,
      errors,
      procedureHasErrors: Boolean(firstErrorField.errorFieldRef),
      firstErrorField,
    };
  },

  mergeSectionValidationErrors: ({ currentProcedureErrors, updatedAllSectionErrors }) => {
    const updatedProcedureErrors = cloneDeep(currentProcedureErrors) || {};
    if (!updatedProcedureErrors.sections) {
      updatedProcedureErrors.sections = {};
    }
    updatedProcedureErrors.sections = {
      ...updatedProcedureErrors.sections,
      ...updatedAllSectionErrors.sections,
    };

    return updatedProcedureErrors;
  },

  getMergeSectionValidationResults: ({ procedure, currentProcedureErrors, updatedAllSectionErrors, options }) => {
    const mergedErrors = validateUtil.mergeSectionValidationErrors({
      currentProcedureErrors,
      updatedAllSectionErrors,
    });

    const firstErrorField = validateUtil.getFirstErrorField({
      procedure,
      errors: mergedErrors,
      options,
    });

    return {
      errors: mergedErrors,
      procedureHasErrors: Boolean(firstErrorField.errorFieldRef),
      firstErrorField,
    };
  },
};

export default validateUtil;
