import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReviewBlockAttachment from './Blocks/ReviewBlockAttachment';
import ReviewBlockTelemetry from './ReviewBlockTelemetry';
import ReviewBlockCommanding from './Blocks/ReviewBlockCommanding';
import StepCommenting from '../StepCommenting';
import ReviewCommenting from '../ReviewCommenting';
import ProcedureBlockReview from '../Blocks/ProcedureBlockReview';
import ProcedureFieldNoRedlines from '../ProcedureFieldNoRedlines';
import ReviewProcedureStepHeader from './ReviewProcedureStepHeader';
import ReviewPartBuild from '../../manufacturing/components/Review/ReviewPartBuild';
import ReviewPartKit from '../../manufacturing/components/Review/ReviewPartKit';
import ReviewPartUsage from '../../manufacturing/components/Review/ReviewPartUsage';
import ReviewInventoryDetailInput from '../../manufacturing/components/Review/ReviewInventoryDetailInput';
import ReviewStepDetail from './ReviewStepDetail';
import ExpandCollapseCaret from '../ExpandCollapse/ExpandCollapseCaret';
import { generateHiddenClassString } from '../../lib/styles';
import signoffUtil from 'shared/lib/signoffUtil';
import { STEP_STATE, isStepEnded, getStepState } from 'shared/lib/runUtil';
import { AttachmentImageDisplayStyle, BlockTypes } from '../Blocks/BlockTypes';
import reviewUtil from '../../lib/reviewUtil';
import ReviewProcedureStepSignoffButton from './ReviewProcedureStepSignoffButton';
import { useReviewContext } from '../../contexts/ReviewContext';
import { useSettings, AUTO_COLLAPSE_UNACTIONABLE_STEPS_KEY } from '../../contexts/SettingsContext';
import { DefaultStepDetailDefinitions } from '../StepDetailTypes';
import { isEmptyValue } from 'shared/lib/text';
import { useRunContext } from '../../contexts/RunContext';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import '../../App.css';
import procedureUtil from '../../lib/procedureUtil';
import ReviewStepConditionals from '../StepConditionals/ReviewStepConditionals';
import RequirementsOverlay from '../StepDependency/RequirementsOverlay';
import DiffContainer from '../Diff/DiffContainer';
import diffUtil from '../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import useDiff from '../../hooks/useDiff';
import useProcedureAdapter from '../../hooks/useProcedureAdapter';
import JiraIssueModal from '../JiraIssueModal';
import CreateIssueModal from '../../issues/components/CreateIssueModal';
import ReviewBlockText from './ReviewBlockText';
import ReviewJumpTo from './ReviewJumpTo';
import ReviewBlockProcedureLink from './ReviewBlockProcedureLink';
import ReviewTestCasesBlock from '../../testing/components/Review/ReviewTestCasesBlock';
import ReviewExpressionBlock from './ReviewExpressionBlock';
import ReviewTableInput from '../TableInput/ReviewTableInput';
import ReviewReferenceBlock from './ReviewReferenceBlock';
import { STEP_REFERENCE_TYPE } from '../../issues/constants';
import ReviewToolCheckOutIn from '../../manufacturing/components/Tools/ReviewToolCheckOutIn';
import { getScrollToUrlParams } from '../../lib/scrollToUtil';
import ReviewToolUsage from '../../manufacturing/components/Tools/ReviewToolUsage';
import ReviewProcedureStepBanner from './ReviewProcedureStepBanner';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import StepTimeline from '../StepTimeline';
import ReviewFieldInputTable from './ReviewFieldInputTable';
import ReviewSettingBadge from './ReviewSettingBadge';
import { isStepSettingEnabled } from 'shared/lib/procedureUtil';
import { faLayerGroup, faRedo, faStepForward, faStrikethrough } from '@fortawesome/free-solid-svg-icons';
import { generatePlaceholderId } from 'shared/lib/idUtil';
import PlaceholderBlock from '../Diff/PlaceholderBlock';

const PROCEDURE_LEVEL_STEP_SETTINGS = [
  {
    setting: 'skip_step_enabled',
    icon: faStepForward,
    tooltipText: 'Skip Step',
  },
  {
    setting: 'repeat_step_enabled',
    icon: faRedo,
    tooltipText: 'Repeat Step',
  },
  {
    setting: 'step_suggest_edits_enabled',
    icon: faStrikethrough,
    tooltipText: 'Suggest Edits',
  },
];

const MOBILE_WIDTH = 550;
const ReviewProcedureStep = ({
  runId,
  projectId,
  step,
  stepKey,
  defaultFormattedStepKey,
  sectionId,
  sectionKey,
  sourceName,
  docState,
  repeatKey,
  onRefChanged,
  onResolveReviewComment,
  onUnresolveReviewComment,
  saveReviewComment,
  comments,
  isHidden,
  isCollapsed,
  onStepCollapse,
  scrollToBufferRem = 0,
  onAddIssue,
  showReviewComments = false,
  showModifiedDiffContainer = true,
  isFullWidth = false,
  hideUnchangedBlocks = false,
  onClickPlaceholder,
}) => {
  const { currentTeamId } = useDatabaseServices();
  const { procedure } = useProcedureContext();

  const [collapseCompletedSteps, setCollapseCompletedSteps] = useState(true);
  const [showJiraIssueModal, setShowJiraIssueModal] = useState(false);
  const [showNcrIssueModal, setShowNcrIssueModal] = useState(false);

  const { config, isIssuesEnabled } = useSettings();
  const { run, isRun, areRequirementsMet, isStepVisible, isSingleCardEnabled, viewStepAsIncomplete } = useRunContext();

  const location = window.location;
  const locationUrl = `${location.protocol}//${location.host}${location.pathname}`;

  const { sourceStepConditionalsMap, removedSourceStepConditionalsMap } = useProcedureAdapter();
  const isMounted = useRef(true);
  const stepGotoRef = useRef();
  const { getSetting } = useSettings();
  const { onScrollToDiffRefChanged, isStepRemoved } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH);

  const displayedBlocks = useMemo(() => {
    if (!hideUnchangedBlocks) {
      return step.content;
    }

    return diffUtil.getChangesWithContext({
      items: step.content,
      placeholderGenerator: () => ({
        id: generatePlaceholderId(),
        type: 'placeholder',
      }),
      getIsPlaceholder: (item) => item.type === 'placeholder',
    });
  }, [hideUnchangedBlocks, step.content]);

  const stepState = useMemo(() => {
    let state = getStepState(step);
    if (state === STEP_STATE.COMPLETED && viewStepAsIncomplete(step.id)) {
      state = STEP_STATE.INCOMPLETE;
    }
    return state ?? STEP_STATE.INCOMPLETE;
  }, [step, viewStepAsIncomplete]);

  useEffect(() => {
    const handleWindowResize = function () {
      setIsMobile(window.innerWidth < MOBILE_WIDTH);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const isEnded = useMemo(() => isStepEnded(step), [step]);
  const isStepCollapsed = useMemo(() => {
    // Steps are always expanded in single card view
    if (isSingleCardEnabled) {
      return false;
    }
    return isCollapsed || isHidden;
  }, [isCollapsed, isHidden, isSingleCardEnabled]);

  // Returns an array of step detail objects.
  const allStepDetails = useMemo(() => {
    const standardStepDetails = Object.values(DefaultStepDetailDefinitions);

    // If config doc or step details do not exist, return standard step details.
    if (!config || !config.step_details) {
      return standardStepDetails;
    }

    const optionalStepDetails = Object.values(config.step_details);

    return [...standardStepDetails, ...optionalStepDetails];
  }, [config]);

  // Returns step details that have a non empty value.
  const visibleStepDetails = useMemo(() => {
    return allStepDetails.filter((stepDetail) => {
      const stepDetailValue = diffUtil.getFieldValue(step, stepDetail.id);

      if (Array.isArray(stepDetailValue)) {
        return stepDetailValue.length !== 0;
      }

      return !isEmptyValue(stepDetailValue);
    });
  }, [step, allStepDetails]);

  const toggleIsStepCollapsed = useCallback(() => {
    if (typeof onStepCollapse === 'function') {
      onStepCollapse(step.id, !isStepCollapsed);
    }
  }, [isStepCollapsed, onStepCollapse, step.id]);

  const areConditionalAndStepDependenciesFulfilled = useMemo(() => {
    /**
     * We use this component in a non-run (edit) context, where
     * areDependenciesFulfilled will be undefined. We don't need to verify if
     * dependencies are fulfilled in a non-run context, so we can return true.
     */
    if (!isRun || !areRequirementsMet) {
      return true;
    }
    return areRequirementsMet(step);
  }, [isRun, step, areRequirementsMet]);

  const isAutoCollapseEnabled = useMemo(() => {
    // Don't auto collapse in single card view or outside runs
    if (isSingleCardEnabled || !isRun) {
      return false;
    }
    return getSetting(AUTO_COLLAPSE_UNACTIONABLE_STEPS_KEY, false);
  }, [isSingleCardEnabled, getSetting, isRun]);

  /**
   * Initialize the step as collapsed if it is completed or has unmet
   * requirements. When the requirements are met, expand the step.
   *
   * Only does this on the first render, and not when the run data has changed,
   * to prevent the screen from jumping unexpectedly, when other users complete steps.
   */
  const requirementsPreviouslyMet = useRef(areConditionalAndStepDependenciesFulfilled);

  useEffect(() => {
    if (isAutoCollapseEnabled && collapseCompletedSteps) {
      if (isCollapsed === undefined) {
        if (isEnded || !areConditionalAndStepDependenciesFulfilled) {
          onStepCollapse(step.id, true);
        }
      } else {
        if (areConditionalAndStepDependenciesFulfilled && !requirementsPreviouslyMet.current) {
          onStepCollapse(step.id, false);
        }
        requirementsPreviouslyMet.current = areConditionalAndStepDependenciesFulfilled;
      }

      setCollapseCompletedSteps(false);
    }
  }, [
    areConditionalAndStepDependenciesFulfilled,
    onStepCollapse,
    step.id,
    isAutoCollapseEnabled,
    isEnded,
    isCollapsed,
    collapseCompletedSteps,
  ]);

  const runStateBannerClass = useMemo(() => {
    if (stepState === STEP_STATE.COMPLETED) {
      return 'bg-app-green-200';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'bg-red-200';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'bg-app-gray-400';
    }
    return '';
  }, [stepState]);

  const runStateStepBodyClass = useMemo(() => {
    if (stepState === STEP_STATE.COMPLETED) {
      return 'border-2 border-green-400 bg-white';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'border-2 border-red-400 bg-white';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'bg-zinc-50 border-2 border-app-gray-400'; /// zinc-50 == #FAFAFA
    } else {
      // Step has not been completed, failed, nor skipped
      return 'border-2 border-blue-200 bg-white';
    }
  }, [stepState]);

  // TODO (jon): fix that rings are being clipped at top and bottom
  const ringClasses = useMemo(() => {
    if (stepState === STEP_STATE.COMPLETED) {
      return 'ring-2 ring-offset-2 ring-app-green-400 ring-offset-app-green-200';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'ring-2 ring-offset-2 ring-red-400 ring-offset-red-200';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'ring-2 ring-offset-2 ring-app-gray-600 ring-offset-app-gray-400';
    }
    return '';
  }, [stepState]);

  const onStepRefChanged = useCallback(
    (element) => {
      if (typeof onRefChanged === 'function') {
        onRefChanged(step.id, element);
      }
    },
    [step, onRefChanged]
  );

  const stepRemoved = useMemo(() => isStepRemoved && isStepRemoved(step), [isStepRemoved, step]);

  const formatStepKey = useCallback(
    (displayStyle) => {
      if (!stepKey || !sectionKey) {
        return;
      }

      if (step.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED) {
        return '--';
      }

      return {
        letters: `${sectionKey}${stepKey}`,
        numbers: `${sectionKey}.${stepKey}`,
      }[displayStyle || 'letters'];
    },
    [sectionKey, step.diff_change_state, stepKey]
  );

  const stepHeaderHasValues = useMemo(() => procedureUtil.stepHeaderHasValues(step), [step]);

  const hasDependencies = useMemo(() => {
    const hasStepDependencies = Boolean(
      step.dependencies &&
        step.dependencies.length &&
        step.dependencies[0] &&
        step.dependencies[0].dependent_ids &&
        step.dependencies[0].dependent_ids.length
    );
    const stepId = step.id?.endsWith('__removed') ? step.id.replace('__removed', '') : step.id;
    const hasStepConditionals =
      Object.values(sourceStepConditionalsMap?.[stepId] || {}).length > 0 ||
      Object.values(removedSourceStepConditionalsMap?.[stepId] || {}).length > 0;
    return hasStepDependencies || hasStepConditionals;
  }, [step, sourceStepConditionalsMap, removedSourceStepConditionalsMap]);

  const showSignOffButton = useMemo(() => {
    return (
      signoffUtil.isSignoffRequired(step.signoffs) &&
      stepState !== STEP_STATE.FAILED &&
      stepState !== STEP_STATE.SKIPPED
    );
  }, [step, stepState]);

  const formattedStepKey = useMemo(() => {
    if (defaultFormattedStepKey) {
      return defaultFormattedStepKey;
    }
    return formatStepKey(config && config.display_sections_as);
  }, [defaultFormattedStepKey, config, formatStepKey]);

  const subStepKeys = useMemo(() => {
    let currentSubStepKey = 1;
    const numbers = {};
    step.content.forEach((content) => {
      const { substepKey: key, nextNumber } = procedureUtil.displaySubStepKey(
        formattedStepKey,
        currentSubStepKey,
        sharedDiffUtil.getDiffValue(content, 'type', 'new'),
        content.diff_change_state
      );
      numbers[content.id] = key;
      currentSubStepKey = nextNumber;
    });
    return numbers;
  }, [step.content, formattedStepKey]);

  const toTheSideImages = useMemo(() => {
    const toTheSideImages = [];
    if (step.content) {
      step.content.forEach((content) => {
        const type = /** @type {string} */ (sharedDiffUtil.getDiffValue(content, 'type', 'new') ?? '');
        if (
          type.toLowerCase() === 'attachment' &&
          sharedDiffUtil.getDiffValue(content, 'display_style', 'new') === AttachmentImageDisplayStyle.ToTheSide
        ) {
          toTheSideImages.push(content);
        }
      });
    }
    return toTheSideImages;
  }, [step.content]);

  const hasToTheSideImages = useMemo(() => {
    return toTheSideImages.length > 0;
  }, [toTheSideImages]);

  // Makes the images in line if they are the only step contents
  const onlyHasToTheSideImages = useMemo(() => {
    return step.content && toTheSideImages.length === step.content.length;
  }, [toTheSideImages, step.content]);

  const shouldDisplayToTheSideImages = useMemo(() => {
    return hasToTheSideImages && !onlyHasToTheSideImages && !isMobile;
  }, [hasToTheSideImages, onlyHasToTheSideImages, isMobile]);

  const colSpanValue = useMemo(() => {
    return shouldDisplayToTheSideImages ? '2' : '3';
  }, [shouldDisplayToTheSideImages]);

  const stepDataTestId = useMemo(() => {
    const changeInfo = [
      ARRAY_CHANGE_SYMBOLS.ADDED,
      ARRAY_CHANGE_SYMBOLS.MODIFIED,
      ARRAY_CHANGE_SYMBOLS.REMOVED,
    ].includes(step.diff_change_state)
      ? `_${step.diff_change_state}`
      : '';
    return `step-${formatStepKey(config && config.display_sections_as)}-${repeatKey || 0}${changeInfo}`;
  }, [config, repeatKey, formatStepKey, step.diff_change_state]);

  const stepBannerEnabled = useMemo(() => {
    return hasDependencies || step.timer || step.duration;
  }, [step.timer, step.duration, hasDependencies]);

  const diffChangeState = useMemo(() => {
    if (!showModifiedDiffContainer && step.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
      return ARRAY_CHANGE_SYMBOLS.UNCHANGED;
    }

    return step.diff_change_state;
  }, [showModifiedDiffContainer, step.diff_change_state]);

  if (isRun && !isStepVisible(step)) {
    return null;
  }

  const renderStepDetails = (stepDetail) => {
    if (stepDetail.id === 'duration' && typeof step[stepDetail.id] === 'object') {
      // No-op
    } else if (stepDetail.id === 'timer' && typeof step[stepDetail.id] === 'object') {
      // No-op
    } else {
      return (
        <ReviewStepDetail
          key={stepDetail.id}
          icon={stepDetail.icon}
          label={stepDetail.title}
          value={diffUtil.getFieldValue(step, stepDetail.id)}
          diffChangeState={diffUtil.getDiffChangeStateForFieldValue(step, stepDetail.id)}
        />
      );
    }
  };

  return (
    <tbody data-testid={stepDataTestId}>
      <tr>
        <td colSpan={isFullWidth ? 1 : 3}>
          <DiffContainer
            label="Step"
            diffChangeState={diffChangeState}
            onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(step.id, element)}
          >
            <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
              <thead>
                <tr>
                  {!isFullWidth && <th className="w-8" />}
                  <th className="w-full" />
                  {!isFullWidth && <th className="w-8" />}
                </tr>
              </thead>
              <tbody aria-label="Step" role="region">
                <tr>
                  {!isFullWidth && <td />}
                  <td>
                    <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
                      <thead>
                        <tr>
                          <th className="w-4"></th>
                          <th className="w-full"></th>
                          <th className="w-0"></th>
                        </tr>
                      </thead>
                      {stepHeaderHasValues && (
                        <>
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  className="w-0 h-0"
                                  ref={stepGotoRef}
                                  style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                                ></div>
                              </td>
                            </tr>
                          </tbody>
                          <ReviewProcedureStepHeader
                            header={step.headers[0]}
                            onRefChanged={onRefChanged}
                            scrollMarginTopValueRem={scrollToBufferRem}
                          />
                        </>
                      )}
                      {stepBannerEnabled && (
                        <ReviewProcedureStepBanner
                          step={step}
                          isRun={isRun}
                          hasDependencies={hasDependencies}
                          isSingleCardEnabled={isSingleCardEnabled}
                          areConditionalAndStepDependenciesFulfilled={areConditionalAndStepDependenciesFulfilled}
                          onRefChanged={onRefChanged}
                          scrollMarginTopValueRem={scrollToBufferRem}
                          updateStepDetail={undefined}
                          baseRunningCondition={false}
                          isStepActive={false}
                          stepState={stepState}
                        />
                      )}
                      <tbody
                        aria-label="Step Body"
                        className={`shadow-lg shadow rounded ${runStateStepBodyClass}`}
                        ref={stepHeaderHasValues ? null : stepGotoRef}
                        style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                        role="region"
                      >
                        {/* Title and completion checkbox row */}
                        <tr className={runStateBannerClass}>
                          <td className="align-top">
                            <div className={generateHiddenClassString('flex py-2', isHidden)}>
                              <div className="h-9">
                                {!isSingleCardEnabled && (
                                  <ExpandCollapseCaret
                                    isExpanded={!isStepCollapsed}
                                    onClick={toggleIsStepCollapsed}
                                    ariaLabel="Expand Collapse Step Toggle"
                                    isHidden={!onStepCollapse}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td colSpan={2} className="break-words">
                            <div
                              ref={onStepRefChanged}
                              className={generateHiddenClassString(
                                'ml-4 py-2 flex items-start app-border-gray-4 page-break',
                                isHidden
                              )}
                              style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                            >
                              {/* Step key */}
                              <button
                                className={`h-8 w-8 mt-0.5 flex justify-center items-center rounded-full bg-black ${ringClasses}`}
                                onClick={toggleIsStepCollapsed}
                              >
                                <span className="font-bold text-xs text-white">
                                  {formattedStepKey ? formattedStepKey : '--'}
                                </span>
                              </button>
                              {/* Step title bar */}
                              <div className="ml-4 w-8 flex flex-row grow justify-between">
                                {/* Step title bar left side content */}
                                <div className="flex grow font-semibold">
                                  <ProcedureFieldNoRedlines
                                    fieldName="name"
                                    fieldValue={step.name}
                                    onLabelClick={toggleIsStepCollapsed}
                                    isBold={true}
                                    redlines={[]}
                                  />
                                  {repeatKey && (
                                    <div className="whitespace-nowrap flex-none self-start mt-1.5">
                                      <span className="ml-4 text-sm text-gray-600">
                                        <FontAwesomeIcon icon="redo" />
                                      </span>
                                      <span className="ml-1 text-sm font-bold text-gray-600 italic">
                                        Repeat {repeatKey}
                                      </span>
                                    </div>
                                  )}
                                  {stepState === STEP_STATE.FAILED && (
                                    <div className="whitespace-nowrap flex-none self-start mt-1.5">
                                      <span className="ml-4 text-sm text-gray-600">
                                        <FontAwesomeIcon icon="exclamation-circle" />
                                      </span>
                                      <span className="ml-1 text-sm font-bold text-gray-600 italic">Failed</span>
                                    </div>
                                  )}
                                  {/* Content with icon for skip */}
                                  {stepState === STEP_STATE.SKIPPED && (
                                    <div className="whitespace-nowrap flex-none self-start mt-1.5">
                                      <span className="ml-4 text-sm text-gray-600">
                                        <FontAwesomeIcon icon="step-forward" aria-label="skipped" />
                                      </span>
                                      <span className="ml-1 text-sm font-bold text-gray-600 italic">Skipped</span>
                                    </div>
                                  )}
                                </div>

                                {/* TODO (jon): refactor this gnarly jsx */}
                                {/* Step title bar right side content */}
                                <div className="flex items-start gap-x-2">
                                  {PROCEDURE_LEVEL_STEP_SETTINGS.map(({ setting, tooltipText, icon }) => {
                                    return (
                                      <ReviewSettingBadge
                                        key={setting}
                                        tooltipText={tooltipText}
                                        icon={icon}
                                        oldSettingValue={isStepSettingEnabled(
                                          sharedDiffUtil.getDiffValue(step, setting, 'old'),
                                          sharedDiffUtil.getDiffValue(procedure, setting, 'old')
                                        )}
                                        newSettingValue={isStepSettingEnabled(
                                          sharedDiffUtil.getDiffValue(step, setting, 'new'),
                                          sharedDiffUtil.getDiffValue(procedure, setting, 'new')
                                        )}
                                        oldVisibleSettingValue={
                                          !isStepSettingEnabled(
                                            undefined,
                                            sharedDiffUtil.getDiffValue(procedure, setting, 'old')
                                          )
                                        }
                                        newVisibleSettingValue={
                                          !isStepSettingEnabled(
                                            undefined,
                                            sharedDiffUtil.getDiffValue(procedure, setting, 'new')
                                          )
                                        }
                                      />
                                    );
                                  })}
                                  <ReviewSettingBadge
                                    tooltipText="Batch Step"
                                    icon={faLayerGroup}
                                    oldSettingValue={sharedDiffUtil.getDiffValue(step, 'runAsBatch', 'old') === true}
                                    newSettingValue={sharedDiffUtil.getDiffValue(step, 'runAsBatch', 'new') === true}
                                    oldVisibleSettingValue={true}
                                    newVisibleSettingValue={true}
                                  />

                                  {/* Content with checkbox for signoffs */}
                                  <div className="flex flex-col items-end px-2">
                                    {step.requires_previous && (
                                      <span className="ml-4 mr-3 font-semibold text-xs text-gray-600 whitespace-nowrap">
                                        Requires Previous Step
                                      </span>
                                    )}
                                    {isRun &&
                                      !areConditionalAndStepDependenciesFulfilled &&
                                      stepState === STEP_STATE.INCOMPLETE && (
                                        <div className="py-1">
                                          <RequirementsOverlay step={step} isTooltipEnabled={isSingleCardEnabled} />
                                        </div>
                                      )}
                                    <div className="flex flex-row flex-wrap justify-end gap-x-2 gap-y-2">
                                      {showSignOffButton &&
                                        step.signoffs.map((signoff) => (
                                          <ReviewProcedureStepSignoffButton
                                            key={signoff.id}
                                            signoff={signoff}
                                            isStepCollapsed={isStepCollapsed}
                                          />
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {!isStepCollapsed && (visibleStepDetails.length > 0 || hasDependencies) && (
                          <tr>
                            <td colSpan={3}>
                              <div className={generateHiddenClassString('', isStepCollapsed)}></div>
                              <div
                                className={generateHiddenClassString(
                                  `flex flex-row flex-nowrap ${runStateBannerClass}`,
                                  isStepCollapsed
                                )}
                              >
                                <div className="w-11 mr-1"></div>
                                <div className="flex flex-row flex-wrap gap-x-3 items-start text-sm font-medium">
                                  {stepRemoved && !isSingleCardEnabled && hasDependencies && (
                                    <div className="flex">
                                      <FontAwesomeIcon className="text-gray-500 self-center mr-1" icon="info-circle" />
                                      <div className="flex flex-row gap-x-1 py-1">
                                        Dependencies are no longer valid.
                                      </div>
                                    </div>
                                  )}
                                  {visibleStepDetails.map((stepDetail) => (
                                    <div key={stepDetail.id}>{renderStepDetails(stepDetail)}</div>
                                  ))}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                        {!isStepCollapsed && (
                          <tr>
                            <td colSpan={3} className="align-top">
                              <table
                                className="table-fixed w-full border-collapse"
                                cellSpacing="0"
                                cellPadding="0"
                                border={0}
                              >
                                <thead>
                                  {shouldDisplayToTheSideImages && (
                                    <tr>
                                      <th className="w-4"></th>
                                      <th className="w-2/4"></th>
                                      <th className="w-2/4"></th>
                                    </tr>
                                  )}
                                  {!shouldDisplayToTheSideImages && (
                                    <tr>
                                      <th className="w-4"></th>
                                      <th className="w-auto"></th>
                                    </tr>
                                  )}
                                </thead>
                                <tbody>
                                  <tr>
                                    <td colSpan={colSpanValue} className="align-top">
                                      <table
                                        className="table-fixed w-full border-collapse"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        border={0}
                                      >
                                        <thead>
                                          <tr>
                                            <th className="w-4"></th>
                                            <th className="w-auto"></th>
                                            <th className="w-64"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {/* Step content blocks */}
                                          {displayedBlocks.map((content) => {
                                            const type = /** @type {string} */ (
                                              sharedDiffUtil.getDiffValue(content, 'type', 'new') ?? ''
                                            );

                                            return (
                                              <Fragment key={content.id}>
                                                {type.toLowerCase() === 'placeholder' && (
                                                  <tr>
                                                    <td />
                                                    <td colSpan={2}>
                                                      <div className="ml-4">
                                                        <PlaceholderBlock onClick={onClickPlaceholder} />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )}
                                                {type.toLowerCase() === 'text' && (
                                                  <ReviewBlockText
                                                    block={content}
                                                    blockLabel={subStepKeys[content.id]}
                                                    isHidden={isStepCollapsed}
                                                    onRefChanged={onRefChanged}
                                                    scrollMarginTopValueRem={scrollToBufferRem}
                                                  />
                                                )}
                                                {/* Render telemetry content row */}
                                                {/* TODO: Refactor to use ProcedureBlocReview */}
                                                {type.toLowerCase() === 'telemetry' && (
                                                  <ReviewBlockTelemetry
                                                    blockLabel={subStepKeys[content.id]}
                                                    telemetry={content}
                                                    docState={docState}
                                                    isHidden={isStepCollapsed}
                                                    isSpacerHidden={false}
                                                  />
                                                )}
                                                {/* Render commanding content row */}
                                                {type.toLowerCase() === 'commanding' && (
                                                  <tr>
                                                    <td />
                                                    <td colSpan={2}>
                                                      <ReviewBlockCommanding
                                                        blockLabel={subStepKeys[content.id]}
                                                        commanding={content}
                                                        isHidden={isStepCollapsed}
                                                        isSpacerHidden={false}
                                                      />
                                                    </td>
                                                  </tr>
                                                )}
                                                {/* Render procedure block */}
                                                {(type.toLowerCase() === 'input' ||
                                                  type.toLowerCase() === 'alert' ||
                                                  type.toLowerCase() === 'requirement' ||
                                                  type.toLowerCase() === 'external_item') && (
                                                  <tr>
                                                    <td />
                                                    <td colSpan={2}>
                                                      <ProcedureBlockReview
                                                        block={content}
                                                        blockLabel={subStepKeys[content.id]}
                                                        isEnabled={false}
                                                        isHidden={isStepCollapsed}
                                                        onContentRefChanged={onRefChanged}
                                                        scrollMarginTopValueRem={scrollToBufferRem}
                                                        recorded={undefined}
                                                        isSpacerHidden={undefined}
                                                        isDark={undefined}
                                                      />
                                                    </td>
                                                  </tr>
                                                )}
                                                {/* Render Table input */}
                                                {type.toLowerCase() === 'table_input' && (
                                                  <ReviewTableInput
                                                    content={content}
                                                    blockLabel={subStepKeys[content.id]}
                                                    isHidden={isStepCollapsed}
                                                    isSpacerHidden={false}
                                                  />
                                                )}
                                                {/* Render procedure link row */}
                                                {type.toLowerCase() === 'procedure_link' && (
                                                  <tr>
                                                    <td />
                                                    <td colSpan={2}>
                                                      <div className="ml-4 mr-2">
                                                        <ReviewBlockProcedureLink
                                                          blockLabel={subStepKeys[content.id]}
                                                          link={content}
                                                          isHidden={isStepCollapsed}
                                                          sourceName={sourceName}
                                                        />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )}
                                                {/* Render attachments row */}
                                                {type.toLowerCase() === 'attachment' &&
                                                  (onlyHasToTheSideImages ||
                                                    isMobile ||
                                                    !toTheSideImages.includes(content)) && (
                                                    <ReviewBlockAttachment
                                                      attachment={content}
                                                      blockLabel={subStepKeys[content.id]}
                                                      isHidden={isStepCollapsed}
                                                      isSpacerHidden={false}
                                                    />
                                                  )}
                                                {/* Render jump to row */}
                                                {type.toLowerCase() === BlockTypes.JumpTo && (
                                                  <ReviewJumpTo
                                                    content={content}
                                                    blockLabel={subStepKeys[content.id]}
                                                    isHidden={isStepCollapsed}
                                                    isValid={!stepRemoved}
                                                  />
                                                )}
                                                {/* Render reference row */}
                                                {type.toLowerCase() === BlockTypes.Reference && (
                                                  <ReviewReferenceBlock
                                                    originalReferencedContentId={sharedDiffUtil.getDiffValue(
                                                      content,
                                                      'reference',
                                                      'new'
                                                    )}
                                                    originalReferencedSubtype={sharedDiffUtil.getDiffValue(
                                                      content,
                                                      'sub_reference',
                                                      'new'
                                                    )}
                                                    originalReferencedFieldIndex={sharedDiffUtil.getDiffValue(
                                                      content,
                                                      'field_index',
                                                      'new'
                                                    )}
                                                    blockLabel={subStepKeys[content.id]}
                                                    isHidden={Boolean(isStepCollapsed)}
                                                    isValid={!stepRemoved}
                                                    diffChangeState={content.diff_change_state}
                                                  />
                                                )}
                                                {/* Render expression row */}
                                                {type.toLowerCase() === BlockTypes.Expression && (
                                                  <ReviewExpressionBlock
                                                    name={sharedDiffUtil.getDiffValue(content, 'name', 'new')}
                                                    tokens={content.tokens}
                                                    blockLabel={subStepKeys[content.id]}
                                                    isHidden={Boolean(isStepCollapsed)}
                                                    isValid={!stepRemoved}
                                                    diffChangeState={content.diff_change_state}
                                                  />
                                                )}
                                                {/* Render part kit */}
                                                {type.toLowerCase() === BlockTypes.PartKit && (
                                                  <ReviewPartKit
                                                    content={content}
                                                    onRecordValuesChanged={() => null}
                                                    recorded={null}
                                                    isHidden={Boolean(isStepCollapsed)}
                                                    isEnabled={false}
                                                    blockLabel={subStepKeys[content.id]}
                                                    isStepComplete={stepState === STEP_STATE.COMPLETED}
                                                    teamId={currentTeamId}
                                                  />
                                                )}
                                                {/* Render part kit */}
                                                {type.toLowerCase() === BlockTypes.PartBuild && (
                                                  <ReviewPartBuild
                                                    content={content}
                                                    recorded={null}
                                                    isHidden={Boolean(isStepCollapsed)}
                                                    isEnabled={false}
                                                    blockLabel={subStepKeys[content.id]}
                                                    teamId={currentTeamId}
                                                  />
                                                )}
                                                {/* Render inventory detail input */}
                                                {type.toLowerCase() === BlockTypes.InventoryDetailInput && (
                                                  <tr>
                                                    <td />
                                                    <td colSpan={2}>
                                                      <ReviewInventoryDetailInput
                                                        content={content}
                                                        onRecordValuesChanged={() => null}
                                                        blockLabel={subStepKeys[content.id]}
                                                        isEnabled={false}
                                                      />
                                                    </td>
                                                  </tr>
                                                )}
                                                {/* Render tool check-out */}
                                                {type.toLowerCase() === BlockTypes.ToolCheckOut && (
                                                  <ReviewToolCheckOutIn
                                                    content={content}
                                                    type="out"
                                                    teamId={currentTeamId}
                                                  />
                                                )}
                                                {/* Render tool check-in */}
                                                {type.toLowerCase() === BlockTypes.ToolCheckIn && (
                                                  <ReviewToolCheckOutIn
                                                    content={content}
                                                    type="in"
                                                    teamId={currentTeamId}
                                                  />
                                                )}
                                                {/* Render part usage */}
                                                {type.toLowerCase() === BlockTypes.PartUsage && (
                                                  <ReviewPartUsage
                                                    content={content}
                                                    isHidden={Boolean(isStepCollapsed)}
                                                    blockLabel={subStepKeys[content.id]}
                                                    teamId={currentTeamId}
                                                  />
                                                )}
                                                {/* Render tool usage */}
                                                {type.toLowerCase() === BlockTypes.ToolUsage && (
                                                  <ReviewToolUsage content={content} teamId={currentTeamId} />
                                                )}
                                                {type.toLowerCase() === BlockTypes.TestCases && (
                                                  <tr>
                                                    <td />
                                                    <td colSpan={2}>
                                                      <div className="mr-4">
                                                        <ReviewTestCasesBlock
                                                          content={content}
                                                          isHidden={Boolean(isStepCollapsed)}
                                                          blockLabel={subStepKeys[content.id]}
                                                        />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )}
                                                {type.toLowerCase() === BlockTypes.FieldInputTable && (
                                                  <tr>
                                                    <td />
                                                    <td colSpan={2}>
                                                      <div className="ml-4 mr-8">
                                                        <ReviewFieldInputTable content={content} />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )}
                                              </Fragment>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </td>
                                    {shouldDisplayToTheSideImages && (
                                      <td className="align-top">
                                        <table
                                          className="table-fixed w-full border-collapse"
                                          cellSpacing="0"
                                          cellPadding="0"
                                          border={0}
                                        >
                                          <tbody>
                                            {/* Render side by side images */}
                                            {toTheSideImages.map((content) => (
                                              <ReviewBlockAttachment
                                                key={content.id}
                                                attachment={content}
                                                isHidden={isStepCollapsed}
                                                isSpacerHidden={true}
                                                blockLabel={subStepKeys[content.id]}
                                              />
                                            ))}
                                          </tbody>
                                        </table>
                                      </td>
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}

                        {/* Step conditionals information */}
                        {!isStepCollapsed && step.conditionals && step.conditionals.length > 0 && (
                          <tr>
                            <td colSpan={3}>
                              <div className={generateHiddenClassString('', isStepCollapsed)}></div>
                              <div className={generateHiddenClassString('flex flex-row flex-nowrap', isStepCollapsed)}>
                                <div className="w-11 mr-1"></div>
                                <div className="flex flex-row flex-wrap gap-x-3 items-start text-sm font-medium">
                                  <ReviewStepConditionals step={step} conditionals={step.conditionals} />
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}

                        {/* Step completion user id and timestamps */}
                        {!isStepCollapsed && (stepState === STEP_STATE.SKIPPED || step.actions?.length > 0) && (
                          <>
                            <tr>
                              <td></td>
                              <td colSpan={2}>
                                <div className={generateHiddenClassString('page-break', isStepCollapsed)}>
                                  <StepTimeline sectionId={sectionId} step={step} issues={run.issues} isRun={isRun} />
                                </div>
                              </td>
                            </tr>
                          </>
                        )}

                        {/* Review commenting row */}
                        {!isStepCollapsed && showReviewComments && (
                          <>
                            <tr>
                              <td></td>
                              <td colSpan={2}>
                                <div className={generateHiddenClassString('mb-2', isStepCollapsed)}></div>
                                <div className={generateHiddenClassString('mb-2 page-break', isStepCollapsed)}>
                                  <ReviewCommenting
                                    stepId={step.id}
                                    onResolveReviewComment={onResolveReviewComment}
                                    onUnresolveReviewComment={onUnresolveReviewComment}
                                    saveReviewComment={saveReviewComment}
                                    reviewComments={reviewUtil.getStepReviewComments(comments, step.id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        )}

                        {/* Extra padding if there is no run comment (isPreviewMode) and no review comment */}
                        {!isStepCollapsed && !showReviewComments && (
                          <tr className="h-3">
                            <td colSpan={3}></td>
                          </tr>
                        )}

                        <tr>
                          <td>
                            <JiraIssueModal
                              // TODO: [EPS-3845] config setting to allow this to be generic
                              urlText={`${run?.name}: ${step?.name}`}
                              url={`${locationUrl}${getScrollToUrlParams({ id: step?.id, type: 'step' })}`}
                              onAddIssue={onAddIssue}
                              isModalShown={showJiraIssueModal}
                              onHideModal={() => setShowJiraIssueModal(false)}
                            ></JiraIssueModal>
                          </td>
                        </tr>

                        {isIssuesEnabled && isIssuesEnabled() && (
                          <tr>
                            <td>
                              <CreateIssueModal
                                runId={runId}
                                referenceId={step?.id}
                                referenceType={STEP_REFERENCE_TYPE}
                                onAddIssue={onAddIssue}
                                isModalShown={showNcrIssueModal}
                                onHideModal={() => setShowNcrIssueModal(false)}
                                projectId={projectId}
                              />
                            </td>
                          </tr>
                        )}

                        {/* Step commenting row */}
                        {!isStepCollapsed && (
                          <tr>
                            <td></td>
                            <td colSpan={2}>
                              <div className={generateHiddenClassString('mb-2', isStepCollapsed)}></div>
                              <div className={generateHiddenClassString('mb-2 page-break', isStepCollapsed)}>
                                <StepCommenting
                                  redlineComments={step.redline_comments}
                                  onRefChanged={onRefChanged}
                                  isRedlineActive={false}
                                  isEditing={false}
                                  onEditRedlineStepComment={() => null}
                                  onDirtyRedlineCommentChanged={() => null}
                                  reasonRedlineIsDisabled={null}
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tbody>
                        <tr className="h-4"></tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </DiffContainer>
        </td>
      </tr>
    </tbody>
  );
};

export default ReviewProcedureStep;
