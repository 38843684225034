import React, { useCallback } from 'react';
import { generateHiddenClassString } from '../../lib/styles';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import { useSettings } from '../../contexts/SettingsContext';
import procedureUtil, { Summary } from '../../lib/procedureUtil';
import runUtil from '../../lib/runUtil';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import useProcedureAdapter from '../../hooks/useProcedureAdapter';
import InvalidMessage from '../InvalidMessage';
import { JumpToBlock } from 'shared/lib/types/views/procedures';

export const CONTENT_TYPE_JUMP_TO = 'jump_to';

// Returns string with "'sectionKey'. 'sectionName'"
const getSectionLabel = (section, allSections, getSetting) => {
  const sectionKey = runUtil.displaySectionKey(
    allSections,
    section.index,
    getSetting('display_sections_as', 'letters')
  );

  if (section.name) {
    return `${sectionKey}. ${section.name}`;
  }

  return `${sectionKey}.`;
};

// Returns string with "'stepKey'. 'stepName'"
const getStepLabel = (step: Summary, stepIdsToLabelsMap: { [key: string]: string }) => {
  const stepLabel = stepIdsToLabelsMap[step.id];

  if (step.name) {
    return `${stepLabel}. ${step.name}`;
  }

  return `${stepLabel}.`;
};
interface JumptoProps {
  content: JumpToBlock;
  isHidden: boolean;
  blockLabel: string;
  isValid?: boolean;
}
const JumpTo = ({ content, isHidden, blockLabel, isValid = true }: JumptoProps) => {
  const { procedure, getItemPath, scrollTo } = useProcedureContext();
  const { getAllSections, getSectionSummary, getStepSummary, stepIdsToLabelsMap } = useProcedureAdapter();
  const { getSetting } = useSettings();

  const getIsTargetRemoved = () => {
    const path = getItemPath(content.jumpToId);

    const { sectionId, stepId } = procedureUtil.parsePath(path);

    /*
     * "Path not found" could happen if a section/step were removed, and then
     * another section/step were added, so that the diff thinks the section/step
     * was just changed.
     */
    const pathNotFound = !sectionId && !stepId;

    return pathNotFound || sectionId?.endsWith('__removed') || stepId?.endsWith('__removed');
  };

  const formatJumpToMessage = () => {
    const path = getItemPath(content.jumpToId);

    const { sectionId, stepId } = procedureUtil.parsePath(path);
    const sectionSummary = getSectionSummary(sectionId);

    if (sectionSummary) {
      const allSections = getAllSections();
      const stepSummary = stepId && getStepSummary(stepId, sectionId);

      if (stepSummary) {
        return `${getStepLabel(stepSummary, stepIdsToLabelsMap)}`;
      }

      return `${getSectionLabel(sectionSummary, allSections, getSetting)}`;
    }
    return null;
  };

  const onJumpToClick = useCallback(
    (event) => {
      event.preventDefault();

      const path = getItemPath(content.jumpToId);

      const { sectionId, stepId } = procedureUtil.parsePath(path);
      const latestRepeat = runUtil.getLatestRepeat(procedure, sectionId, stepId);

      scrollTo({
        sectionId: latestRepeat.sectionId,
        stepId: latestRepeat.stepId,
        stepHeaderId: latestRepeat.stepHeaderId,
      });
    },
    [content, getItemPath, procedure, scrollTo]
  );

  return (
    <tr>
      {/* Empty div for first grid column */}
      <td></td>
      <td colSpan={2}>
        <div className={generateHiddenClassString('', isHidden)}></div>

        {/* Content div */}
        <div className={generateHiddenClassString('flex mt-2 page-break', isHidden)}>
          {/* Bullet */}
          <Spacer />
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
          <div>
            <span className="mr-2">Jump to</span>
            {isValid && !getIsTargetRemoved() && (
              <button
                className="underline text-blue-600"
                type="button"
                onClick={onJumpToClick}
                disabled={typeof scrollTo !== 'function'} // Disables for viewing in edit procedure
              >
                {formatJumpToMessage()}
              </button>
            )}
            {(!isValid || getIsTargetRemoved()) && <InvalidMessage>Link no longer valid</InvalidMessage>}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default React.memo(JumpTo);
