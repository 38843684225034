import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionTab, AccordionTabChangeEvent } from 'primereact/accordion';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Run, Section } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import Avatar from '../elements/Avatar';
import Tooltip from '../elements/Tooltip';
import useSidebar, { SIDEBAR_CONTENT } from '../hooks/useSidebar';
import { procedureViewPath } from '../lib/pathUtil';
import runUtil from '../lib/runUtil';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import AvatarStack from './AvatarStack';
import DateTimeDisplay from './DateTimeDisplay';
import PreviewRoleSelector from './PreviewRoleSelector';
import RunActionButtons from './RunActionButtons';
import RunProgressBar, { StepCounts } from './RunProgressBar';
import RunSideBarActivityItem from './RunSideBarActivityItem';
import { RunStatus } from './RunStatusBadge';
import ProcedureFlowChart from './StepConditionals/ProcedureFlowChart';
import TOCSidebar from './TOCSidebar/TOCSidebar';
import Label from './Label';
import useProjectFilterHelper from '../testing/hooks/useProjectFilterHelper';

interface RunSidebarProps {
  run: Run;
  runStatus: RunStatus;
  runStepCounts: StepCounts;
  isPreviewMode: boolean;
  participantUserIds: Array<string>;
  isStartedByApi: boolean;
  isStartedByUser: boolean;
  showRunPausedStickyHeader: boolean;
  displaySections: Array<[Section, number]>;
  activeSidebarTabs: Array<number>;
  updateActiveSidebarTabs: (event: AccordionTabChangeEvent) => void;
  RunTagsSelector: JSX.Element;
  OperationSelector: JSX.Element;
}

const ACCORDION_HEIGHT = 49;
const PROGRESS_BAR_HEIGHT = 52;
const STICKY_HEADER_HEIGHT = 40;
const PADDING_HEIGHT = 19;
const PREVIEWING_AS_HEIGHT = 120;
const CLOSED_ACCORDION_HEIGHT = 35;

const getAvailableHeight = (
  showRunPausedStickyHeader: boolean,
  detailsHeight: number | undefined,
  actionsAreaHeight: number | undefined,
  tagsAndOperationsSelectorHeight: number | undefined,
  isPreviewMode: boolean
) => {
  return (
    window.innerHeight -
    PADDING_HEIGHT -
    PROGRESS_BAR_HEIGHT -
    (detailsHeight || 0) -
    (tagsAndOperationsSelectorHeight || 0) -
    (actionsAreaHeight || 0) -
    (isPreviewMode ? ACCORDION_HEIGHT : ACCORDION_HEIGHT * 2) -
    (isPreviewMode ? PREVIEWING_AS_HEIGHT : 0) -
    (showRunPausedStickyHeader ? 2 * STICKY_HEADER_HEIGHT : STICKY_HEADER_HEIGHT) -
    CLOSED_ACCORDION_HEIGHT
  );
};

const RunSidebar = ({
  run,
  runStatus,
  runStepCounts,
  isPreviewMode,
  participantUserIds,
  isStartedByApi,
  isStartedByUser,
  showRunPausedStickyHeader,
  displaySections,
  activeSidebarTabs,
  updateActiveSidebarTabs,
  RunTagsSelector,
  OperationSelector,
}: RunSidebarProps) => {
  const { currentTeamId } = useDatabaseServices();
  const { getProjectName } = useProjectFilterHelper();
  const detailsAreaRef = useRef<HTMLDivElement>(null);
  const actionsAreaRef = useRef<HTMLDivElement>(null);
  const tagsAndOperationsSelectorAreaRef = useRef<HTMLDivElement>(null);
  const procedureId = useMemo(() => runUtil.getProcedureId(run), [run]);
  const [availableHeight, setAvailableHeight] = useState<number>(100);
  const [showAll, setShowAll] = useState(false);

  const reversedActions = useMemo(() => {
    return run.actions ? [...run.actions].reverse() : [];
  }, [run.actions]);

  const onElementsResize = useCallback(() => {
    const available = getAvailableHeight(
      showRunPausedStickyHeader,
      detailsAreaRef?.current?.clientHeight,
      actionsAreaRef?.current?.clientHeight ? actionsAreaRef.current.clientHeight + 5 : undefined, // Adjust for accordion margin
      tagsAndOperationsSelectorAreaRef?.current?.clientHeight,
      isPreviewMode
    );
    setAvailableHeight(available);
  }, [isPreviewMode, showRunPausedStickyHeader]);

  const { toggleView, sidebarContainerRef, sidebarContentView } = useSidebar({ onElementsResize });

  return (
    <div ref={sidebarContainerRef} className="overflow-hidden">
      {isPreviewMode && <PreviewRoleSelector />}
      <div className={`${isPreviewMode ? '' : 'mb-4 mr-6'}`}>
        <RunProgressBar runStatus={runStatus} stepCounts={runStepCounts} showNumbers={true} />
      </div>
      {isPreviewMode && <RunActionButtons isPreviewMode={true} />}
      <div ref={tagsAndOperationsSelectorAreaRef} className="flex flex-col gap-y-1 px-1 pb-2">
        {RunTagsSelector}
        {OperationSelector}
      </div>
      <Accordion onTabChange={updateActiveSidebarTabs} multiple={true} activeIndex={activeSidebarTabs}>
        {!isPreviewMode && (
          <AccordionTab key="details" headerTemplate={<div className="py-3">Details</div>}>
            <div ref={detailsAreaRef}>
              <div className="flex flex-col ml-2 gap-y-2 lg:w-64">
                {run.version && (
                  <div className="whitespace-nowrap text-sm">
                    <span className="font-medium mr-1">Version</span>
                    <Tooltip content="View Master Procedure">
                      <Link
                        to={procedureViewPath(currentTeamId, procedureId)}
                        className="text-blue-500 hover:brightness-75"
                      >
                        {run.version}
                      </Link>
                    </Tooltip>
                  </div>
                )}
                {run.project_id && (
                  <div className="whitespace-nowrap text-sm">
                    <span className="font-medium mr-1">Project</span>
                    <Label text={getProjectName(run.project_id) || ''} color="bg-gray-200" />
                  </div>
                )}
                <div className="flex items-center">
                  <div className="font-medium text-sm whitespace-nowrap w-14">Started</div>
                  {isStartedByApi && (
                    <span className="group rounded-full h-7 w-7 text-white flex justify-center items-center border border-white bg-slate-300 cursor-default">
                      <FontAwesomeIcon icon="robot" />
                      <div className="hidden absolute group-hover:flex font-light px-1 -mt-6 mb-5 group-hover:z-50 bg-gray-500 rounded text-white items-center">
                        <span className="text-sm">Started via API</span>
                      </div>
                    </span>
                  )}
                  <div className="flex-none">
                    {isStartedByUser && run?.started_by?.user_id && <Avatar userId={run.started_by.user_id} />}
                  </div>
                  <div className="ml-0.5 text-xs">
                    <DateTimeDisplay timestamp={run.starttime} />
                  </div>
                </div>

                <div className="flex flex-col">
                  <span className="font-medium text-sm">Collaborators</span>
                  <AvatarStack userIds={participantUserIds} />
                </div>
                {run.completedAt && (
                  <div className="flex items-center">
                    <div className="font-medium text-sm whitespace-nowrap w-14">Ended</div>
                    {run.completedUserId && <Avatar userId={run.completedUserId} />}
                    <div className="ml-0.5 text-xs">
                      <DateTimeDisplay timestamp={run.completedAt} />
                    </div>
                  </div>
                )}
              </div>
              <RunActionButtons isPreviewMode={isPreviewMode} />
            </div>
          </AccordionTab>
        )}
        <AccordionTab
          headerTemplate={
            <div className="w-full flex flex-row justify-between py-2">
              <div className="py-1">Contents</div>
              <div className="w-20 flex flex-row">
                <button
                  className={stickyHeaderUtil.getButtonClassNames(false, sidebarContentView === SIDEBAR_CONTENT.TOC)}
                  onClick={toggleView}
                >
                  <FontAwesomeIcon icon="list" />
                </button>
                <button
                  className={stickyHeaderUtil.getButtonClassNames(false, sidebarContentView === SIDEBAR_CONTENT.FLOW)}
                  onClick={toggleView}
                >
                  <FontAwesomeIcon icon="sitemap" />
                </button>
              </div>
            </div>
          }
        >
          {sidebarContentView === SIDEBAR_CONTENT.TOC && (
            <TOCSidebar
              stickyMargin={showRunPausedStickyHeader ? 20 : 10}
              displaySections={displaySections}
              height={availableHeight}
              run={run}
            />
          )}
          {sidebarContentView === SIDEBAR_CONTENT.FLOW && (
            <ProcedureFlowChart procedure={run} height={availableHeight} />
          )}
        </AccordionTab>
        <AccordionTab
          headerTemplate={
            <div className="w-full flex flex-row justify-between py-2">
              <div className="py-1">Activity</div>
            </div>
          }
        >
          <div ref={actionsAreaRef} className="pb-2 max-h-[300px] overflow-auto">
            {reversedActions.length === 0 ? (
              <div className="text-center py-1">No Activities</div>
            ) : (
              <>
                {reversedActions.slice(0, showAll ? reversedActions.length : 5).map((action, actionIndex) => (
                  <RunSideBarActivityItem key={actionIndex} action={action} className="mb-1" />
                ))}
                {reversedActions.length > 5 && !showAll && <button onClick={() => setShowAll(true)}>Show More</button>}
              </>
            )}
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
};
export default RunSidebar;
