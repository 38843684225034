import { RunById } from './couch/views';
import { EventBase } from '../../schedule/types/event';

export const EMPTY_COUCH_LIKE_OPERATIONS_DOC: CouchLikeOperations = {
  _id: 'operations',
  operations: {},
  totals: {
    num_planning: 0,
    num_running: 0,
    num_ended: 0,
  },
};

export type OperationState = 'planning' | 'running' | 'ended';

export type OperationId = {
  name: string;
  key: string;
};

export type CouchLikeOperations = {
  _id: 'operations';
  summary?: false;
  totals: {
    num_planning: number;
    num_running: number;
    num_ended: number;
  };
  operations: {
    [operation_key: string]: CouchLikeOperation;
  };
};

export type CouchLikeOperationSummary = {
  key: string;
  name: string;
  state: OperationState;
};

export type CouchLikeOperationsSummary = {
  _id: 'operations';
  summary?: true;
  operations: {
    [operation_key: string]: CouchLikeOperationSummary;
  };
};

export type CouchLikeOperation = {
  name: string;
  key: string;
  description?: string;
  state: OperationState;
  procedures: Array<CouchLikeOperationProcedure>;
  events?: Array<EventBase>;
  runs?: Array<RunById>;
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  assignee_user_id?: string | null;
};

export type CouchLikeOperationProcedure = {
  id: string;
  count: number;
};
