import { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { StringSelectOption as SelectOption } from '../../lib/formik';
import { reactSelectStyles } from '../../lib/styles';
import { ComponentPart, Part, PartRevisionSummary } from 'shared/lib/types/postgres/manufacturing/types';
import Tooltip from '../../elements/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EMPTY_REVISION_DISPLAY_VALUE } from '../types';
import useParts from '../hooks/useParts';
import { TreeNode } from 'primereact/treenode';
import { getPartId } from '../lib/assemblyParts';
import { TreeSelect, TreeSelectChangeEvent } from 'primereact/treeselect';
import './styles/TreeSelect.css';
import { sortBy } from 'shared/lib/collections';
import { getReleasedRevisions } from '../lib/part_revisions';
import { isPartRestricted } from '../lib/parts';

export type PartSelection = {
  part: Part;
  partRevisionId: string;
  nodeKeyId?: string;
};

export const getPartAsSelection = (part: Part | undefined): PartSelection | null => {
  if (!part) {
    return null;
  }
  const revision = (part.revisions || []).find((revision) => revision.revision === part.rev);
  const partRevisionId = revision?.id || '';
  return { part, partRevisionId };
};

const nodeTemplate = (node: TreeNode) => {
  const isSelectable = node.selectable !== false;
  return (
    <div className={`w-48 break-words text-base	${isSelectable ? 'text-black' : 'text-gray-400'}`}>{node.label}</div>
  );
};

const sortNodesAlphabetically = (nodes: TreeNode[]): TreeNode[] => {
  const sortedNodes = sortBy(nodes, ['label']);
  sortedNodes.forEach((node) => {
    if (node.children && node.children.length > 0) {
      node.children = sortNodesAlphabetically(node.children);
    }
  });

  return sortedNodes;
};

const findNonAssemblyParts = (parts: Part[], partNumbersSet: Set<string>): Part[] => {
  return parts.filter((part) => !isPartRestricted(part) && !partNumbersSet.has(part.part_no));
};

type PartAndRevisionIdSelectProps = {
  selected: PartSelection | null;
  onSelect: (selection: PartSelection | null) => void;
  /** @todo use this property once primereact is upgraded to v10 */
  canClearPart?: boolean;
  isDisabled?: boolean;
  partSelectLabel?: string;
  partSelectToolTip?: string;
  revisionSelectLabel?: string;
  shouldDisablePartSelect?: (part: Part | ComponentPart) => boolean;
};

const PartAndRevisionIdSelect = ({
  selected,
  onSelect,
  canClearPart = true,
  isDisabled = false,
  partSelectLabel = 'Part',
  partSelectToolTip,
  revisionSelectLabel = 'Revision',
  shouldDisablePartSelect = undefined,
}: PartAndRevisionIdSelectProps) => {
  const { parts, assemblyParts, getPart } = useParts();
  const [nodes, setNodes] = useState<TreeNode[]>();

  /*
   * Guards against the user passing in a part from a content block in a procedure,
   * which may not have some expected fields like revisions.
   */
  let selectedPart: Part | undefined = undefined;
  let revisions: PartRevisionSummary[] = [];
  if (selected?.part) {
    selectedPart = getPart(selected?.part.id);
    if (selectedPart?.revisions) {
      revisions = selectedPart.revisions;
    }
  }
  const released = getReleasedRevisions(revisions);

  const mapPartToTreeSelectNodes = useCallback(
    (part: Part | ComponentPart, includeComponents: boolean, parentPathId = '') => {
      const partId = getPartId(part);
      const nodeKey = parentPathId === '' ? partId : `${parentPathId}-${partId}`;
      const node: TreeNode = {
        key: nodeKey,
        label: isPartRestricted(part) ? 'Restricted' : `${part.part_no} ${part.name}`,
        children: [],
        selectable: shouldDisablePartSelect ? !shouldDisablePartSelect(part) : true,
      };
      if (includeComponents && part.components && part.components.length > 0) {
        node.children = part.components.map((component) => mapPartToTreeSelectNodes(component, true, nodeKey));
      }
      return node;
    },
    [shouldDisablePartSelect]
  );

  useEffect(() => {
    if (!parts || !assemblyParts) {
      return;
    }
    const partNumbersSet = new Set<string>();
    for (const assemblyPart of assemblyParts) {
      partNumbersSet.add(assemblyPart.part_no);
    }
    const nonAssemblyParts = findNonAssemblyParts(parts, partNumbersSet);
    const assemblyPartNodes = assemblyParts.map((assemblyPart) => mapPartToTreeSelectNodes(assemblyPart, true));
    const nonAssemblyPartNodes = nonAssemblyParts.map((nonAssemblyPart) =>
      mapPartToTreeSelectNodes(nonAssemblyPart, false)
    );

    setNodes([...sortNodesAlphabetically(assemblyPartNodes), ...sortNodesAlphabetically(nonAssemblyPartNodes)]);
  }, [assemblyParts, mapPartToTreeSelectNodes, parts]);

  const revisionOptions: SelectOption[] = released.map((revision) => ({
    value: revision.id,
    label: revision.revision || EMPTY_REVISION_DISPLAY_VALUE,
  }));

  const selectedRevisionOption: SelectOption | null = useMemo(() => {
    if (!selectedPart) {
      return null;
    }

    // Revision id specified in selected.partRevisionId object
    if (selected?.partRevisionId) {
      const selectedRevision = released.find((revision) => revision.id === selected.partRevisionId);
      if (selectedRevision) {
        return { value: selectedRevision.id, label: selectedRevision.revision || EMPTY_REVISION_DISPLAY_VALUE };
      }
    }

    // Fall back first to revision matching selected.part
    if (selected?.part) {
      const selectedRevision = released.find((revision) => revision.revision === selected?.part.rev);
      if (selectedRevision) {
        return { value: selectedRevision.id, label: selectedRevision.revision || EMPTY_REVISION_DISPLAY_VALUE };
      }
    }

    // Fall back last to showing latest released revision if one exists
    if (released.length > 0) {
      const selectedRevision = released[0];
      return { value: selectedRevision.id, label: selectedRevision.revision || EMPTY_REVISION_DISPLAY_VALUE };
    }

    return null;
  }, [selected, selectedPart, released]);

  const onPartChange = (e: TreeSelectChangeEvent) => {
    if (!e.value || typeof e.value !== 'string') {
      return;
    }
    const partIds = e.value.split('-');
    const partId = partIds[partIds.length - 1];
    const part = getPart(partId);
    if (!part) {
      return;
    }
    const released = getReleasedRevisions(part.revisions || []);
    if (released.length < 1) {
      return;
    }
    const partRevisionId = released[0].id;
    onSelect({ part, partRevisionId, nodeKeyId: e.value });
  };

  const onRevisionChange = (option: SelectOption) => {
    if (!selected || !selected.part) {
      return;
    }
    onSelect({ part: selected.part, partRevisionId: option.value, nodeKeyId: selected.nodeKeyId });
  };

  const selectedNodeKey = useMemo(() => {
    if (!selected) {
      return null;
    }
    if (selected.nodeKeyId) {
      return selected.nodeKeyId;
    } else if (selected.part) {
      return getPartId(selected.part);
    }
    return null;
  }, [selected]);

  return (
    <div className="flex">
      <div className="w-72 flex-col items-start">
        <div className="flex flex-row items-center">
          <div className="field-title">{partSelectLabel}</div>
          {partSelectToolTip && (
            <Tooltip content={partSelectToolTip}>
              <FontAwesomeIcon icon="circle-info" className="text-xs text-gray-400 ml-1" />
            </Tooltip>
          )}
        </div>
        <TreeSelect
          value={selectedNodeKey}
          onChange={onPartChange}
          options={nodes}
          filter
          placeholder="Search parts"
          nodeTemplate={nodeTemplate}
          disabled={isDisabled}
          className={`${isDisabled ? 'dropdown-disabled' : ''} h-[42px]`}
          resetFilterOnHide
        />
      </div>
      <div className="w-48 ml-2 items-start">
        <div className="field-title">{revisionSelectLabel}</div>
        <Select
          styles={reactSelectStyles}
          classNamePrefix="react-select"
          isDisabled={isDisabled || !selectedPart}
          options={revisionOptions}
          onChange={onRevisionChange}
          value={selectedRevisionOption}
          placeholder="Select revision"
        />
      </div>
    </div>
  );
};

export default PartAndRevisionIdSelect;
