import { CreatedUpdatedAt, StringId, TeamId } from '../util';

export enum ItemStatus {
  InInventory = 'In Inventory',
  Unavailable = 'Unavailable',
  Scrap = 'Scrap',
}

// ---
// Inventory Activity
// ---

export enum ItemAction {
  Created = 'Created',
  Updated = 'Updated',
  Received = 'Received',
  CheckedOut = 'Checked Out',
  CheckedIn = 'Checked In',
  UsageRecorded = 'Usage Recorded',
  UsageReset = 'Usage Reset',
  DetailUpdated = 'Detail Updated',
  IssueLinked = 'Issue Linked',
}

export enum ItemActorType {
  Api = 'api',
  User = 'user',
  Run = 'run',
}

// TODO fill this in
export type Item = StringId &
  TeamId & {
    part_no: string;
    doc: { components?: { item_id: string }[] };
    part_revision_id: string;
    status: ItemStatus;
    amount: number;
  } & CreatedUpdatedAt;

// TODO rename to InventoryActivity
export type ItemActivity = {
  id: string; // bigint in the database, gets deserialized as a string
  timestamp: Date;
  item_id: string;
  action: ItemAction;
  actor_type: ItemActorType;
  actor_id?: string;
  new_value: object;
  old_value?: object;
};

export type UsageResetActivity = Omit<ItemActivity, 'new_value'> & {
  new_value: { usage_type: string };
};

export type InsertItemActivity = Omit<ItemActivity, 'id' | 'timestamp'>;

export type ListItemActivities = {
  has_older: boolean;
  activities: ItemActivity[];
};
