import { Run } from 'shared/lib/types/views/procedures';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import { DateTime } from 'luxon';
interface RunMap {
  [runId: string]: Run;
}

export const compareEventStartTimes = (a: Event, b: Event, runMap: RunMap): number => {
  const aRun = runMap[a.run_id || ''];
  const bRun = runMap[b.run_id || ''];
  const aTime = aRun?.starttime || (a.start as DateTime)?.toUTC().toISO() || '';
  const bTime = bRun?.starttime || (b.start as DateTime)?.toUTC().toISO() || '';
  return aTime < bTime ? -1 : aTime > bTime ? 1 : 0;
};
