import Papa, { ParseResult } from 'papaparse';
import { Location, Item, LocationMap, Vendor } from '../types';
import {
  Part,
  ComponentPart,
} from 'shared/lib/types/postgres/manufacturing/types';
import idUtil from '../../lib/idUtil';
import isNumber from '../../lib/number';
import {
  ExportedComponentItem,
  generateComponentTrees,
  getTrackingValue,
} from './items';
import { titleCase } from 'shared/lib/text';
import isEqual from 'lodash.isequal';
import { sortBy } from 'shared/lib/collections';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getFormattedAmount } from './currency';
import apm from '../../lib/apm';

/*
 * BASE
 */
const DefaultParseOptions = {
  header: true,
  transformHeader: (header) => header.trim(),
  dynamicTyping: false,
  skipEmptyLines: true,
};

export type CsvRow = {
  [key: string]: string;
};

export type CsvExport = Array<Array<string | number>>;

const normalizeCsvRow = (row: CsvRow) => {
  // Convert to lowercase keys
  return Object.entries(row).reduce((values, [key, value]) => {
    values[key.toLowerCase()] = value;
    return values;
  }, {});
};

const parseCsv = (file: File, parseOptions): ParseResult<CsvRow> => {
  return new Promise((complete, error) => {
    // nosemgrep: javascript-xml-rule-node_xpath_injection
    Papa.parse(file, { ...parseOptions, complete, error });
  });
};

const getNormalizedCsvHeader = async (file: File): Promise<Array<string>> => {
  const results = await parseCsv(file, {
    ...DefaultParseOptions,
    preview: true,
  });
  const normalizedRow = normalizeCsvRow(results.data[0]);
  const header = Object.keys(normalizedRow);
  return header;
};

export const getMissingColumnHeaders = async (
  file: File,
  columns: object
): Promise<Array<string>> => {
  const requiredColumns = Object.values(columns);
  const actualColumns = await getNormalizedCsvHeader(file);
  return requiredColumns.filter((col) => !actualColumns.includes(col));
};

export const getMissingColumnsError = (
  missingColumns: string[],
  type: string
): string => {
  const baseError = `Unable to import ${type}. Missing columns`;
  const titleCaseColumns = missingColumns.map(titleCase);
  const quotedColumns = titleCaseColumns.map((col) => `"${col}"`);
  return `${baseError} ${quotedColumns.join(', ')}.`;
};

/*
 * PARTS
 */
export const PART_COLUMNS = {
  PART_NO: 'part number',
  PART_NAME: 'name',
  REV: 'rev',
  TRACKING_TYPE: 'tracking type',
  DESCRIPTION: 'description',
  PARENT_PART_NO: 'parent part number',
  QUANTITY_NEEDED: 'quantity needed',
};

const parsePartRow = (row: CsvRow, existingParts: Part[]): Partial<Part> => {
  const normalized = normalizeCsvRow(row);

  const partNumber = normalized[PART_COLUMNS.PART_NO]?.trim() ?? '';
  const existingPart = existingParts.find(
    (part) => part.part_no === partNumber
  );

  const part: Partial<Part> = {
    id: existingPart ? existingPart.id : idUtil.generateUuidEquivalentId(),
    part_no: partNumber,
    name: normalized[PART_COLUMNS.PART_NAME]?.trim() ?? '',
    rev: normalized[PART_COLUMNS.REV]?.trim() ?? '',
    tracking:
      normalized[PART_COLUMNS.TRACKING_TYPE]?.trim().toLowerCase() ?? 'none',
    description: normalized[PART_COLUMNS.DESCRIPTION]?.trim() ?? '',
    components: existingPart ? existingPart.components : [],
    procedures: existingPart ? existingPart.procedures : [],
  };
  if (!['none', 'serial', 'lot'].includes(part.tracking || '')) {
    part.tracking = 'none';
  }
  if (existingPart) {
    if (existingPart.image) {
      part['image'] = existingPart.image;
    }
    if (existingPart.usage_types) {
      part['usage_types'] = existingPart.usage_types;
    }
  }
  return part;
};

type SubComponentPart = { part_no: string; amount: number };

type ComponentMap = { [parentPartNo: string]: SubComponentPart[] };

// Returns map of parts to an array of their components
const getComponentsMap = (csvRows: ParseResult<CsvRow>): ComponentMap => {
  const map: ComponentMap = {};
  csvRows.forEach((row) => {
    const normalized = normalizeCsvRow(row);
    const parentPartNo = normalized[PART_COLUMNS.PARENT_PART_NO]?.trim() ?? '';
    if (!parentPartNo) {
      return;
    }
    if (!(parentPartNo in map)) {
      map[parentPartNo] = [];
    }
    const partNo = normalized[PART_COLUMNS.PART_NO]?.trim() ?? '';
    const amount = normalized[PART_COLUMNS.QUANTITY_NEEDED]?.trim() ?? '';
    if (partNo && amount) {
      map[parentPartNo].push({
        amount,
        part_no: partNo,
      });
    }
  });
  return map;
};

const getPartId = (parts: Part[], partNo: string): string | null => {
  const part = parts.find((p) => p.part_no === partNo);
  if (!part) {
    return null;
  }
  return part.id;
};

const addComponentsToParts = (
  parts: Part[],
  componentsMap: ComponentMap
): Part[] => {
  return parts.map((originalPart) => {
    const part = { ...originalPart };
    if (part.part_no in componentsMap) {
      const components = componentsMap[part.part_no];
      const partComponentsFromCsv: ComponentPart[] = [];
      components.forEach(({ part_no, amount }) => {
        const partId = getPartId(parts, part_no);
        if (partId) {
          partComponentsFromCsv.push({
            part_id: partId,
            amount,
          });
        }
      });

      /*
       * combine the existing components with the new components from the csv.
       * if components match, the components from the CSV have a higher precendence
       */
      const uniqueSubComponentParts: { [id: string]: ComponentPart } = {};
      part.components.forEach((component) => {
        uniqueSubComponentParts[component.part_id] = component;
      });
      partComponentsFromCsv.forEach((component) => {
        uniqueSubComponentParts[component.part_id] = component;
      });
      part.components = Object.values(uniqueSubComponentParts);
    }
    return part;
  });
};

/*
 * Returns one part per part number. If there are two parts with the same part number,
 * we return the first part in the array with that part number.
 */
const getUniqueParts = (parts): Part[] => {
  const uniqueParts: Part[] = [];
  const seenPartNos = new Set<string>();
  for (const part of parts) {
    if (!seenPartNos.has(part.part_no)) {
      uniqueParts.push(part);
      seenPartNos.add(part.part_no);
    }
  }
  return uniqueParts;
};

export const partsFromCsv = (
  rows: ParseResult<CsvRow>,
  existingParts: Part[]
): {
  parts: Partial<Part>[];
  invalidPartCount: number;
  duplicatePartCount: number;
} => {
  const parts: Partial<Part>[] = [];
  let invalidPartCount = 0;
  const componentsMap = getComponentsMap(rows);

  for (const row of rows) {
    const part = parsePartRow(row, existingParts);

    if (!part.part_no || !part.name || !part.rev) {
      invalidPartCount++;
      continue;
    }

    parts.push(part);
  }

  // check if parent parts referenced in csv, but not in csv, already exist. If exists, add to parts
  for (const parentPartNo in componentsMap) {
    const parentPartInCsv = parts.some((part) => part.part_no === parentPartNo);
    if (parentPartInCsv) {
      continue;
    }

    const existingParentPart = existingParts.find(
      (part) => part.part_no === parentPartNo
    );
    if (existingParentPart) {
      parts.push(existingParentPart);
    }
  }

  const uniqueParts = getUniqueParts(parts);
  const duplicatePartCount = parts.length - uniqueParts.length;
  const partsWithComponents = addComponentsToParts(uniqueParts, componentsMap);

  // compare the parts from csv with existing parts - don't return existing parts that haven't been updated
  const newOrUpdatedParts: Part[] = [];
  partsWithComponents.forEach((newPart) => {
    const existingPart = existingParts.find((p) => p.id === newPart.id);
    if (
      !existingPart ||
      (existingPart && isPartUpdated(newPart, existingPart))
    ) {
      newOrUpdatedParts.push(newPart);
    }
  });

  return {
    invalidPartCount,
    duplicatePartCount,
    parts: newOrUpdatedParts,
  };
};

const isPartUpdated = (newPart: Part, existingPart: Part): boolean => {
  return !(
    newPart.name === existingPart.name &&
    newPart.rev === existingPart.rev &&
    newPart.tracking === existingPart.tracking &&
    newPart.description === existingPart.description &&
    isEqual(newPart.components, existingPart.components) &&
    isEqual(newPart.procedures, existingPart.procedures)
  );
};

/*
 * ITEMS
 */

export const ITEM_COLUMNS = {
  PART_NO: 'part number',
  REV: 'rev',
  QUANTITY: 'quantity',
  SERIAL_LOT_NUMBER: 'serial / lot #',
  LOCATION_ID: 'location id',
};

const parseItemRow = (row: CsvRow): Partial<Item> => {
  const normalized = normalizeCsvRow(row);
  const quantity = normalized[ITEM_COLUMNS.QUANTITY]?.trim();
  return {
    part_no: normalized[ITEM_COLUMNS.PART_NO]?.trim().toLowerCase() ?? '',
    rev: normalized[ITEM_COLUMNS.REV]?.trim().toLowerCase() ?? '',
    amount: isNumber(quantity) ? parseInt(quantity) : undefined,
    serial: normalized[ITEM_COLUMNS.SERIAL_LOT_NUMBER]?.trim() ?? '',
    lot: normalized[ITEM_COLUMNS.SERIAL_LOT_NUMBER]?.trim() ?? '',
    location_id: normalized[ITEM_COLUMNS.LOCATION_ID]?.trim() ?? '',
  };
};

const itemsFromCsv = (
  rows: ParseResult<CsvRow>
): { items: Partial<Item>[]; csvRowCount: number } => {
  const items: Partial<Item>[] = [];
  for (const row of rows) {
    const item = parseItemRow(row);
    if (item.part_no && item.rev) {
      items.push(item);
    }
  }
  return { items, csvRowCount: rows.length };
};

/*
 * LOCATIONS
 */
const parseLocationRow = (row: CsvRow): Location => {
  const normalized = normalizeCsvRow(row);
  return {
    id: idUtil.generateUuidEquivalentId(),
    name: normalized['name']?.trim() ?? '',
    code: normalized['location id']?.trim() ?? '',
    full_code: normalized['location id']?.trim() ?? '',
    parent: normalized['parent id']?.trim() ?? '',
  };
};

const locationsFromCsv = (rows: ParseResult<CsvRow>): Location[] => {
  const codeMap = {};
  const idMap = {};
  const locations: Location[] = [];

  for (const row of rows) {
    const location = parseLocationRow(row);
    locations.push(location);
    codeMap[location.code] = location;
    idMap[location.id] = location;
  }

  // Convert parent code links to id links.
  for (const location of locations) {
    location.parent = codeMap[location.parent]?.id || '';
  }

  // Convert codes to partial (node) codes.
  for (const location of locations) {
    const parentFullCode = idMap[location.parent]?.full_code || '';
    location.code = location.code.slice(parentFullCode.length);
  }

  return locations;
};

const getLocationCode = (
  locationMap: LocationMap,
  locationId: string | undefined
): string => {
  const location = locationMap[locationId || ''];
  return location ? location.full_code || location.code : '';
};

/*
 * VENDORS
 */
export const VENDOR_COLUMNS = {
  NAME: 'name',
  ADDRESS: 'address',
  CONTACT: 'contact',
  NOTES: 'notes',
};

export const vendorsFromCsv = (
  rows: ParseResult<CsvRow>
): Partial<Vendor>[] => {
  const vendors: Partial<Vendor>[] = [];
  for (const row of rows) {
    const vendor = parseVendorRow(row);
    if (vendor[VENDOR_COLUMNS.NAME]) {
      vendors.push(vendor);
    }
  }
  return vendors;
};

const parseVendorRow = (row: CsvRow): Partial<Vendor> => {
  const normalized = normalizeCsvRow(row);
  return {
    [VENDOR_COLUMNS.NAME]: normalized[VENDOR_COLUMNS.NAME]?.trim() ?? '',
    [VENDOR_COLUMNS.ADDRESS]: normalized[VENDOR_COLUMNS.ADDRESS]?.trim() ?? '',
    [VENDOR_COLUMNS.CONTACT]: normalized[VENDOR_COLUMNS.CONTACT]?.trim() ?? '',
    [VENDOR_COLUMNS.NOTES]: normalized[VENDOR_COLUMNS.NOTES]?.trim() ?? '',
  };
};

/*
 * EXPORTED LIB
 */
const csvLib = {
  // parts
  parsePartsCsv: (
    file: File,
    existingParts: Part[]
  ): Promise<{
    parts: Partial<Part>[];
    invalidPartCount: number;
    duplicatePartCount: number;
  }> => {
    return new Promise((resolve) => {
      const complete = (results) =>
        resolve(partsFromCsv(results.data, existingParts));
      const parseOptions = { ...DefaultParseOptions, complete };
      Papa.parse(file, parseOptions);
    });
  },

  // items
  parseItemsCsv: (
    file: File
  ): Promise<{ items: Partial<Item>[]; csvRowCount: number }> => {
    return new Promise((resolve) => {
      const complete = (results) => resolve(itemsFromCsv(results.data));
      const parseOptions = { ...DefaultParseOptions, complete };
      Papa.parse(file, parseOptions);
    });
  },

  createItemsExport: (items: Item[], locationMap: LocationMap): CsvExport => {
    const mappedItems = items.map((item) => ({
      ...item,
      _sort_part_rev: item.part.rev,
      _sort_tracking_value: getTrackingValue(item),
      _sort_location_code: getLocationCode(locationMap, item.location_id),
    }));

    const toExport: Item[] = sortBy(mappedItems, [
      'part_no',
      '_sort_part_rev',
      '_sort_tracking_value',
      '_sort_location_code',
    ]);

    // columns on export match columns on import
    const headerRow = [
      'Part Number',
      'Rev',
      'Quantity',
      'Serial / Lot #',
      'Location ID',
      'Unit Cost',
    ];
    const dataRows = toExport.map((item) => [
      item.part_no,
      item.part.rev,
      item.amount,
      getTrackingValue(item),
      getLocationCode(locationMap, item.location_id),
      item.unit_cost_cents === undefined
        ? ''
        : getFormattedAmount(item.unit_cost_cents),
    ]);
    return [headerRow, ...dataRows];
  },

  generateComponentTreeCsvData: (
    tree: ExportedComponentItem[],
    locationMap: LocationMap
  ): string[][] => {
    const csvData: string[][] = [];
    csvData.push([
      'Line',
      'Part Number',
      'Rev',
      'Quantity',
      'Serial / Lot #',
      'Location ID',
      'Unit Cost',
    ]);
    tree.forEach((row) => {
      const locationCode = getLocationCode(locationMap, row.locationId);
      csvData.push([
        row.lineId,
        row.partNumber,
        row.revision,
        String(row.quantity),
        row.trackingId,
        locationCode,
        row.unit_cost,
      ]);
    });
    return csvData;
  },

  exportItemsWithComponentTree: (
    selectedItems: Item[],
    parts: Part[],
    allItems: Item[],
    locationMap: LocationMap
  ): void => {
    const zip = new JSZip();

    const trees = generateComponentTrees(
      selectedItems as Item[],
      parts || [],
      allItems || []
    );
    trees.forEach((tree) => {
      const parentItem = tree[0];
      const fileName = `${parentItem.partNumber}-${parentItem.trackingId}`;
      const csvData = csvLib.generateComponentTreeCsvData(tree, locationMap);
      const asCsvExport = csvData.map((row) => row.join(',')).join('\n');
      zip.file(`${fileName}.csv`, asCsvExport);
    });
    zip
      .generateAsync({ type: 'blob' })
      .then((content) => {
        saveAs(content, `item_component_trees.zip`);
      })
      .catch((err) => apm.captureError(err));
  },

  // locations
  parseLocationCsv: (
    file: File,
    onComplete: (locations: Location[]) => void
  ): void => {
    const complete = (results) => onComplete(locationsFromCsv(results.data));
    const parseOptions = { ...DefaultParseOptions, complete };
    Papa.parse(file, parseOptions);
  },

  // vendors
  parseVendorsCsv: (file: File): Promise<Partial<Vendor>[]> => {
    return new Promise((resolve) => {
      const complete = (results) => resolve(vendorsFromCsv(results.data));
      const parseOptions = { ...DefaultParseOptions, complete };
      Papa.parse(file, parseOptions);
    });
  },
};

export default csvLib;
