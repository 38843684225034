import { useCallback, useMemo } from 'react';
import useParts from './useParts';
import {
  Part,
  ComponentPart,
} from 'shared/lib/types/postgres/manufacturing/types';
import idUtil from '../../lib/idUtil';
import { asComponentPart } from '../lib/parts';

interface PartBlockHelpers {
  getKitItems: (Part) => ComponentPart[];
  configurePartKitBlock: (PartKit) => void;
  configurePartBuildBlock: (PartBuild) => void;
}

interface Props {
  part?: Part;
}

const usePartBlockHelpers = ({ part }: Props): PartBlockHelpers => {
  const { getPart } = useParts();

  const getKitItems = useCallback(
    (part) => {
      const inventoryItems: ComponentPart[] = [];
      for (const component of part.components) {
        const inventoryPart = getPart(component.part_id);
        if (inventoryPart?.tracking === 'serial') {
          for (let i = 0; i < parseInt(component.amount); i++) {
            inventoryItems.push({
              ...component,
              id: idUtil.generateUuidEquivalentId(),
              amount: 1,
            });
          }
        } else {
          inventoryItems.push({
            ...component,
            id: idUtil.generateUuidEquivalentId(),
          });
        }
      }
      return inventoryItems;
    },
    [getPart]
  );

  const getCloseoutItems = useCallback((part) => {
    const component = asComponentPart(part, 1);
    return [{ ...component, id: idUtil.generateUuidEquivalentId() }];
  }, []);

  const configurePartKitBlock = useCallback(
    (block) => {
      if (part) {
        block.part = part;
        block.items = getKitItems(part);
      } else {
        block.part = null;
        block.items = [];
      }
    },
    [part, getKitItems]
  );

  const configurePartBuildBlock = useCallback(
    (block) => {
      if (part) {
        block.part = part;
        block.items = getCloseoutItems(part);
      } else {
        block.part = null;
        block.items = [];
      }
    },
    [part, getCloseoutItems]
  );

  const values = useMemo(() => {
    return {
      getKitItems,
      configurePartKitBlock,
      configurePartBuildBlock,
    };
  }, [getKitItems, configurePartKitBlock, configurePartBuildBlock]);

  return values;
};

export default usePartBlockHelpers;
