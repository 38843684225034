import { runViewPath, runViewStepPath } from '../../lib/pathUtil';
import PromptRedline from '../PromptRedline';
import React, { useCallback, useMemo, useState } from 'react';
import { StepRedline } from 'shared/lib/types/views/redlines';
import { getRedlineFromDoc, getRedlineId } from 'shared/lib/redlineUtil';
import { DraftStep, RunStep, RunStepFullRedline, RunStepRedline } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import ReviewProcedureStep from '../Review/ReviewProcedureStep';
import diffUtil from '../../lib/diffUtil';
import FullStepSuggestedEditsModal from './FullStepSuggestedEditsModal';
import { getStepById } from 'shared/lib/procedureUtil';
import CommentsList from '../CommentsList';

interface FullStepRedlineDiffProps {
  currentStep: DraftStep;
  redlineDoc: StepRedline;
  onAccept: (redline: RunStepRedline) => void;
  onReject: (redline: RunStepRedline) => void;
  stepKey: string;
  startsExpanded?: boolean;
  error?: string;
}

const FullStepRedlineDiff = ({
  currentStep,
  redlineDoc,
  onAccept,
  onReject,
  stepKey,
  error,
  startsExpanded = false,
}: FullStepRedlineDiffProps) => {
  const { currentTeamId, services } = useDatabaseServices();
  const redline = useMemo(() => getRedlineFromDoc(redlineDoc) as RunStepRedline, [redlineDoc]);
  const createdBy = redline.user_id ?? redline.userId ?? '';
  const timestamp = redline.created_at ?? redline.createdAt ?? '';
  const [suggestedEdits, setSuggestedEdits] = useState<Array<RunStepRedline>>([]);

  const stepDiffList = useMemo(() => {
    const previous = diffUtil.prepareRedlinedStepForDiff({ step: currentStep });
    const updated = diffUtil.prepareRedlinedStepForDiff({
      step: redline.step,
      stepId: currentStep.id,
    });
    return diffUtil.getStepDiff({
      previous,
      updated,
      ignoredFields: ['actions', 'id', 'actions', 'redline_id'],
    });
  }, [currentStep, redline.step]);

  const onOpenSuggestedEditsHistory = useCallback(async () => {
    if (!services.runs || !redlineDoc) {
      return;
    }
    const currentRedlineId = getRedlineId(getRedlineFromDoc(redlineDoc));
    if (!currentRedlineId) {
      return;
    }

    const run = await services.runs.getRun(redlineDoc.run_id);
    const sourceStep = getStepById(run, redlineDoc.source_section_id, redlineDoc.source_step_id) as RunStep;
    const redlines = sourceStep.redlines ?? [];

    const currentRedlineIndex = redlines.findIndex((redline) => {
      return getRedlineId(redline) === currentRedlineId;
    });
    if (currentRedlineIndex === -1) {
      return;
    }
    // Show all previous suggested edits and the current redline.
    setSuggestedEdits(redlines.slice(0, currentRedlineIndex + 1));
  }, [redlineDoc, services.runs]);

  return (
    <>
      {suggestedEdits.length > 0 && (
        <FullStepSuggestedEditsModal
          currentStep={currentStep}
          redlines={suggestedEdits}
          showLatestInHistory={true}
          onClose={() => setSuggestedEdits([])}
          contextUrl={runViewPath(currentTeamId, redlineDoc.run_id)}
        />
      )}

      <PromptRedline
        allRedlineUserIds={[createdBy]}
        timestamp={timestamp}
        onAccept={() => onAccept(redline)}
        onReject={() => onReject(redline)}
        contextUrl={
          redlineDoc.run_id ? runViewStepPath(currentTeamId, redlineDoc.run_id, redlineDoc.source_step_id) : ''
        }
        iconLabel="View History"
        onClickIcon={onOpenSuggestedEditsHistory}
        startsExpanded={startsExpanded}
        error={error}
      >
        <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
          {stepDiffList.map((stepDiff) => (
            <>
              {/* TODO: When ProcedureStep is updated to .tsx, update fields to be optional where necessary. Until then, ignore the missing attributes TS error. */}
              {/* @ts-ignore */}
              <ReviewProcedureStep
                step={stepDiff}
                showModifiedDiffContainer={false}
                isCollapsed={false}
                isFullWidth={true}
                defaultFormattedStepKey={stepKey}
                hideUnchangedBlocks={true}
                onClickPlaceholder={() => {
                  void onOpenSuggestedEditsHistory();
                }}
              />
            </>
          ))}

          <CommentsList comments={(redline as RunStepFullRedline).comments ?? []} hasBorder={true} />
        </table>
      </PromptRedline>
    </>
  );
};

export default FullStepRedlineDiff;
