import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { BlockTypes } from './Blocks/BlockTypes';
import { OverlayUpload, OverlaySize } from './OverlayUpload';
import procedureUtil from '../lib/procedureUtil';

const OverlayUploadFileDrop = ({ className, isEnabled, onUpdate, children }) => {
  const onDrop = useCallback(
    (files) => {
      const attachment = procedureUtil.newInitialBlock(BlockTypes.Attachment);
      // @ts-ignore until convert to typescript https://linear.app/epsilon3/issue/EPS-4271
      attachment.file = files[0];
      // @ts-ignore until convert to typescript
      attachment.name = files[0].name;
      onUpdate(attachment);
    },
    [onUpdate]
  );

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  if (!isEnabled) {
    return children;
  }

  return (
    <>
      <OverlayUpload isDragActive={isDragActive} size={OverlaySize.large} />
      <div className={className} {...getRootProps({ role: 'none' })}>
        {children}
      </div>
    </>
  );
};

export default OverlayUploadFileDrop;
