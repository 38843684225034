import ExpandCollapseCaret from '../ExpandCollapse/ExpandCollapseCaret';
import DateTimeDisplay from '../DateTimeDisplay';
import SuggestedEditIcon from './SuggestedEditIcon';
import ReviewProcedureStep from '../Review/ReviewProcedureStep';
import diffUtil from '../../lib/diffUtil';
import CommentsList from '../CommentsList';
import React, { useMemo } from 'react';
import { RunStepFullRedline, RunStepRedline } from 'shared/lib/types/views/procedures';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

interface SuggestedEditHistoryItemProps {
  redline: RunStepRedline;
  previousRedline: RunStepRedline;
  isExpanded: boolean;
  onToggleExpandCollapse: () => void;
}

const SuggestedEditHistoryItem = ({
  redline,
  previousRedline,
  isExpanded,
  onToggleExpandCollapse,
}: SuggestedEditHistoryItemProps) => {
  const diffWithPrevious = useMemo(() => {
    return diffUtil.getStepDiff({
      previous: diffUtil.prepareRedlinedStepForDiff({
        step: previousRedline.step,
        stepId: 'placeholder_id',
      }),
      updated: diffUtil.prepareRedlinedStepForDiff({
        step: redline.step,
        stepId: 'placeholder_id',
      }),
      ignoredFields: ['actions', 'id', 'actions', 'redline_id'],
    });
  }, [previousRedline.step, redline.step]);

  const stepChanged =
    diffWithPrevious?.[0]?.diff_change_state &&
    diffWithPrevious[0].diff_change_state !== ARRAY_CHANGE_SYMBOLS.UNCHANGED;

  return (
    <div
      className={`flex flex-col w-full py-2 px-2 gap-y-2 rounded border-2 bg-gray-50 ${
        redline.run_only ? 'border-blue-400' : 'border-red-400'
      }`}
    >
      <div className="flex flex-row items-center justify-between cursor-pointer" onClick={onToggleExpandCollapse}>
        <div className="-mr-2 text-sm">
          <ExpandCollapseCaret
            isExpanded={isExpanded}
            onClick={() => null}
            ariaLabel="Expand/Collapse Suggested Edit Toggle"
            isHidden={false}
          />
          <span className="font-semibold mr-1">{[redline.user_id ?? redline.userId].join(', ')}</span>
          <DateTimeDisplay timestamp={redline.created_at ?? redline.createdAt ?? ''} />
        </div>
        <SuggestedEditIcon isBlueline={Boolean(redline.run_only)} />
      </div>
      {isExpanded && (
        <div>
          {stepChanged &&
            diffWithPrevious?.map((stepDiff) => (
              <>
                {/* TODO: When ProcedureStep is updated to .tsx, update fields to be optional where necessary. Until then, ignore the missing attributes TS error. */}
                {/* @ts-ignore */}
                <ReviewProcedureStep
                  step={stepDiff}
                  showModifiedDiffContainer={false}
                  isCollapsed={false}
                  isFullWidth={true}
                />
              </>
            ))}
          <CommentsList comments={(redline as RunStepFullRedline).comments ?? []} />
        </div>
      )}
    </div>
  );
};

export default SuggestedEditHistoryItem;
