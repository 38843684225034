import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field } from 'formik';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import FieldSetCommandSelect from './FieldSetCommandSelect';
import FieldSetCheckbox from './FieldSetCheckbox';
import { BlockTypes, getInitialFormValues } from './Blocks/BlockTypes';
import apm from '../lib/apm';
import { DatabaseServices } from '../contexts/proceduresSlice';
import { Command } from '../lib/models/postgres/commanding';

const IGNORED_CMD_PARAMS = ['CCSDS_STREAMID', 'CCSDS_SEQUENCE', 'CCSDS_LENGTH', 'CCSDS_CHECKSUM', 'CCSDS_FC'];

/**
 * Since argument names can have dots in them, we need to use explicit notation
 * for the field name in Formik. (We should probably not allow .'s in args in
 * the future.)
 *
 * Reference: https://github.com/jaredpalmer/formik/issues/676
 */
export const escapeArgName = (argName) => `"${argName}"`;

const COMMAND_TYPES_WITH_DISPLAY_ARGS = ['float', 'int', 'string', 'enum'];

const FieldSetCommanding = ({ content, path, contentErrors, setFieldValue }) => {
  const isMounted = useRef(true);
  const [standardCommand, setStandardCommand] = useState<Command | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const getDefaultArguments = useCallback((command, currentArguments) => {
    if (!command.arguments) {
      return null;
    }
    return Object.assign(
      {},
      ...(command.arguments || []).map((arg) => {
        const value = (currentArguments && currentArguments[arg.name]) || '';
        return { [arg.name]: value };
      })
    );
  }, []);

  /**
   * Fetches command definition and optionally sets initial default arguments.
   *
   * name: String, name of command to load.
   * defaultArguments: Initial form arguments with default values.
   * returns: Promise that resolves when finished.
   */
  const updateCommandParams = useCallback(
    (name, dictionaryId) => {
      return services.commanding
        .getCommandByName(name, dictionaryId)
        .then((command) => {
          if (!isMounted.current) {
            return;
          }
          setStandardCommand(command);
        })
        .catch((err) => apm.captureError(err));
    },
    [services.commanding]
  );

  const displayCommandParams = useMemo(() => [].filter(([name]) => !IGNORED_CMD_PARAMS.includes(name)), []);

  // Migrate to new standard command object.
  useEffect(() => {
    if (!isLoading || !content.key) {
      return;
    }
    const key = content.key;
    // Migrate to new commanding data structure with `name` property
    if (!content.name && content.key.toLowerCase() !== 'command') {
      const name = key;
      setFieldValue(path, {
        ...content,
        key: 'command',
        name,
      });
    }
  }, [path, setFieldValue, content, isLoading]);

  // Load initial command definition.
  useEffect(() => {
    if (content.name === undefined || content.name === null) {
      return;
    }
    updateCommandParams(content.name, content.dictionary_id)
      .then(() => {
        if (!isMounted.current) {
          return;
        }
        setIsLoading(false);
      })
      .catch((err) => apm.captureError(err));
  }, [content.dictionary_id, content.name, updateCommandParams]);

  const getArgument = useCallback(
    (argName) => {
      if (!standardCommand || !standardCommand.arguments) {
        return null;
      }
      return standardCommand.arguments.find((arg) => arg.name === argName);
    },
    [standardCommand]
  );

  const getArgumentType = useCallback(
    (argName) => {
      const argument = getArgument(argName);
      return argument && argument.type;
    },
    [getArgument]
  );

  // Updates arg value to correct type (int, etc.) when field changes
  const onChangeArgumentValue = useCallback(
    (value, type, path) => {
      // Value is optional
      if (!type || value === '' || value === undefined || value === null) {
        return;
      }
      switch (type) {
        case 'int':
          if (isNaN(parseInt(value))) {
            return;
          }
          if (typeof value !== 'number') {
            setFieldValue(path, parseInt(value));
          }
          return;
        case 'float':
          if (isNaN(parseFloat(value))) {
            return;
          }
          /*
           * parseFloat will convert things like "3abc" to "3", so update displayed
           * value.
           */
          if (typeof value !== 'number') {
            setFieldValue(path, parseFloat(value));
          }
          return;
        default:
          // String and enum fields don't need validation
          return;
      }
    },
    [setFieldValue]
  );

  const onClearField = useCallback(() => {
    setFieldValue(path, {
      ...getInitialFormValues(BlockTypes.Commanding),
      id: content.id,
    });
  }, [content.id, path, setFieldValue]);

  const onChangeCommand = useCallback(
    (command) => {
      if (!command?.name) {
        onClearField();
        return;
      }
      return services.commanding.getCommandByName(command.name, command.dictionary_id).then((command) => {
        const defaultArguments = getDefaultArguments(command, command.arguments);
        const fieldValue = {
          ...content,
          name: command.name,
          dictionary_id: command.dictionary_id,
          command_id: command.id,
          arguments: defaultArguments,
        };
        setFieldValue(path, fieldValue);
      });
    },
    [services.commanding, onClearField, getDefaultArguments, content, setFieldValue, path]
  );

  // File arguments are currently only input during the run, so they are not shown while editing
  const displayCommandArguments = useMemo(() => {
    if (!content || !content.arguments) {
      return [];
    }
    const allArgNames = Object.keys(content.arguments);
    return allArgNames.filter((argName) => {
      const argType = getArgumentType(argName);
      return typeof argType === 'string' && COMMAND_TYPES_WITH_DISPLAY_ARGS.includes(argType);
    });
  }, [content, getArgumentType]);

  if (content.name === undefined || content.name === null) {
    return null;
  }

  return (
    <fieldset disabled={isLoading}>
      <div className="flex flex-wrap grow">
        {/* Command name */}
        <div className="flex flex-col w-96 mr-2">
          <span className="field-title">Command</span>
          <Field
            content={content}
            name={`${path}.name`}
            component={FieldSetCommandSelect}
            onChange={onChangeCommand}
            onClear={onClearField}
            icon="stream"
          />
          {contentErrors && contentErrors.name && <div className="text-red-700">{contentErrors.name}</div>}
        </div>

        {/* Command arguments */}
        {displayCommandArguments.map((argName) => (
          <div key={argName} className="flex flex-col mr-2">
            <span className="field-title">
              {argName} ({getArgumentType(argName)})
            </span>
            {getArgumentType(argName) && getArgumentType(argName) !== 'enum' && getArgumentType(argName) !== 'file' && (
              <Field
                name={`${path}.arguments[${escapeArgName(argName)}]`}
                type="text"
                placeholder="Value"
                className="text-sm border border-gray-400 rounded disabled:bg-gray-200"
                validate={(value) =>
                  onChangeArgumentValue(value, getArgumentType(argName), `${path}.arguments[${escapeArgName(argName)}]`)
                }
              />
            )}
            {getArgumentType(argName) === 'enum' && (
              <Field
                name={`${path}.arguments["${argName}"]`}
                as="select"
                className="text-sm border border-gray-400 rounded disabled:bg-gray-200"
              >
                <option value="">Select</option>
                {getArgument(argName) &&
                  getArgument(argName)?.values?.map((enumValue) => (
                    <option key={enumValue.value} value={enumValue.value}>
                      {enumValue.value} ({enumValue.string})
                    </option>
                  ))}
              </Field>
            )}
            {contentErrors && contentErrors.arguments && contentErrors.arguments[argName] && (
              <div className="text-red-700">{contentErrors.arguments[argName]}</div>
            )}
          </div>
        ))}
        {/* Include in summary checkbox */}
        <div className="self-end mb-2 flex flex-row items-center">
          <FieldSetCheckbox
            text="Include in Summary"
            fieldName={`${path}.include_in_summary`}
            setFieldValue={setFieldValue}
          />
        </div>
      </div>
      {displayCommandParams && displayCommandParams.length > 0 && (
        <div className="flex m-2">
          <div>Parameters: </div>
          <div className="ml-2">
            {displayCommandParams.map(([name, defaultValue]) => (
              <div key={name}>
                {name}: {defaultValue}
              </div>
            ))}
          </div>
        </div>
      )}
    </fieldset>
  );
};

export default React.memo(FieldSetCommanding);
