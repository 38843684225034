import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentPart, Part } from 'shared/lib/types/postgres/manufacturing/types';
import PartBadge from './PartBadge';
import RestrictedInfo from './RestrictedInfo';

type FieldInputComponentProps = {
  part?: Part;
  component: ComponentPart;
  teamId: string;
  onChanged: (updated: ComponentPart) => void;
  onRemoved: (component: ComponentPart) => void;
  partRestricted?: boolean;
};

const FieldInputComponent = ({
  part,
  component,
  teamId,
  onChanged,
  onRemoved,
  partRestricted = false,
}: FieldInputComponentProps) => {
  const onChangeAmount = (e) => {
    const amount = e.target.value > 0 ? Math.floor(e.target.value) : 1;
    const updated = {
      ...component,
      amount,
    };
    onChanged?.(updated);
  };

  return (
    <tr className="h-10 text-sm bg-white border-b items-center">
      <td>
        <PartBadge teamId={teamId} part={part} partRestricted={partRestricted} />
      </td>
      <td>
        <div className="p-2">
          {!partRestricted && (
            <input
              type="number"
              className="text-sm border-1 border-gray-400 rounded disabled:bg-gray-100"
              value={component.amount}
              onChange={onChangeAmount}
              min={0}
            />
          )}
          {partRestricted && <RestrictedInfo />}
        </div>
      </td>
      <td>
        <button type="button" className="secondary group" onClick={() => onRemoved?.(component)}>
          <FontAwesomeIcon className="text-gray-400 group-hover:text-gray-500 p-1" icon="trash" />
        </button>
      </td>
    </tr>
  );
};

export default FieldInputComponent;
