import React from 'react';

export interface LeftSideProps {
  children?: React.ReactNode;
}

const LeftSide = ({ children }: LeftSideProps) => {
  return <div className="flex flex-row items-center whitespace-nowrap text-ellipsis overflow-hidden">{children}</div>;
};

export default React.memo(LeftSide);
