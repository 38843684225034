import React, { forwardRef, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldSetStepDetailText from './FieldSetStepDetailText';
import FieldSetOperatorSelect from './FieldSetOperatorSelect';
import { StepDetailType } from './StepDetailTypes';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';

/**
 * Renders a step detail with an optional remove icon.
 *
 * @param {String} name - Property path of the field to be updated.
 * @param {String} icon - Icon name that will be rendered in the field input.
 * @param {function} onRemove - Callback that is called with the name string, when the remove icon is clicked, when not present, the remove icon is not displayed.
 * @param {String} placeholder - Placeholder string to be rendered in the field input.
 * @param {String} title - Title rendered above Field input.
 * @param {String} type - One of ['text', 'operatorSelect'], will render a text input for 'text', and a operator list multiselect for 'operatorSelect'.
 * @param {Array} values - Values array representing operatorSelect selected values. We will be adding userSelect support in the future.
 * @param {Object} stepErrors - Object containing validation errors for containing step.
 */
const FieldSetStepDetail = forwardRef(({ name, icon, onRemove, placeholder, title, type, values, stepErrors }, ref) => {
  const remove = useCallback(() => {
    if (onRemove) {
      onRemove(name);
    }
  }, [onRemove, name]);

  const defaultIcon = useMemo(() => {
    return icon || 'cog';
  }, [icon]);

  return (
    <div className="group relative flex flex-col grow">
      <div
        className="flex flex-col shrink-0"
        ref={ref}
        style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
      >
        <span className="field-title">{title}</span>
        {/* Text Input */}
        {(type === StepDetailType.Text || name === 'duration') && (
          <FieldSetStepDetailText name={name} icon={defaultIcon} placeholder={placeholder} />
        )}
        {/* Operator Select */}
        {type === StepDetailType.OperatorSelect && (
          <FieldSetOperatorSelect name={name} values={values} icon={defaultIcon} placeholder={placeholder} />
        )}
        {stepErrors && stepErrors[name] && (
          <span className="text-sm text-red-700 font-normal capitalize">{stepErrors[name]}</span>
        )}
      </div>
      {onRemove && (
        <FontAwesomeIcon
          className="absolute top-0.5 right-0 text-gray-400 hover:text-gray-600 cursor-pointer opacity-0 group-hover:opacity-100"
          icon="times-circle"
          aria-label="Remove Detail"
          onClick={remove}
        />
      )}
    </div>
  );
});

export default FieldSetStepDetail;
