import { TypedFieldSetProps } from './BlockTypes';
import React, { useMemo } from 'react';
import { Field } from 'formik';
import ReferenceEditSelect from './ReferenceEditSelect';
import FieldSetCheckbox from '../FieldSetCheckbox';

const ReferenceBlockEdit = ({
  path,
  content,
  contentErrors,
  setFieldValue,
  pendingStep,
  precedingStepId,
}: TypedFieldSetProps<'reference'>) => {
  const includeInSummaryFieldName = useMemo(() => (path ? `${path}.include_in_summary` : 'include_in_summary'), [path]);

  const value = {
    referenceContentId: content.reference,
    referenceSubtype: content.sub_reference,
    referenceFieldIndex: content.field_index,
  };

  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col w-96 mr-2">
        <span className="field-title">Field Input Reference</span>
        <Field
          name={`${path}`}
          component={ReferenceEditSelect}
          value={value}
          pendingStep={pendingStep}
          precedingStepId={precedingStepId}
        />
        {contentErrors && contentErrors.reference && <div className="text-red-700">{contentErrors.reference}</div>}
      </div>
      <div className="self-end mb-2 flex flex-row items-center">
        <FieldSetCheckbox
          id={includeInSummaryFieldName}
          isDisabled={false}
          text="Include in Summary"
          fieldName={includeInSummaryFieldName}
          setFieldValue={setFieldValue}
        />
      </div>
    </div>
  );
};

export default ReferenceBlockEdit;
