import revisionsUtil from '../../../lib/revisions';
import ProcedureFieldDiff from '../../ProcedureFieldDiff';
import PromptRedline from '../../PromptRedline';
import React, { useCallback, useMemo } from 'react';
import { RunHeaderFieldRedline, RunStepFieldRedline } from 'shared/lib/types/views/procedures';

/**
 * Renders a field redline block.
 *
 * @param type - The type of redline.
 * @param fieldName - Name of the field.
 * @param fieldValue - Value of the field.
 * @param runRedline - The redline as it was created in the run. This is stored within the redline doc.
 * @param acceptRedline - Function called when accept redline button is pressed.
 * @param rejectRedline - Function called when reject redline button is pressed.
 */
interface HeaderFieldRedlineBlockProps {
  fieldName: 'name';
  fieldValue: string;
  runRedline: RunHeaderFieldRedline | RunStepFieldRedline;
  error?: string;
  acceptRedline: (redline: RunHeaderFieldRedline | RunStepFieldRedline) => void;
  rejectRedline: (redline: RunHeaderFieldRedline | RunStepFieldRedline) => void;
}

const FieldRedlineBlock = ({
  fieldName,
  fieldValue,
  runRedline,
  error,
  acceptRedline,
  rejectRedline,
}: HeaderFieldRedlineBlockProps) => {
  const createdBy = useMemo(() => revisionsUtil.createdBy(runRedline), [runRedline]);
  const timestamp = useMemo(() => revisionsUtil.createdAt(runRedline), [runRedline]);
  const redlineFieldValue = useMemo(
    () =>
      (runRedline as RunHeaderFieldRedline).header
        ? (runRedline as RunHeaderFieldRedline).header[fieldName]
        : (runRedline as RunStepFieldRedline).step[fieldName],
    [fieldName, runRedline]
  );

  const onAcceptRedline = useCallback(() => acceptRedline(runRedline), [acceptRedline, runRedline]);
  const onRejectRedline = useCallback(() => rejectRedline(runRedline), [rejectRedline, runRedline]);

  return (
    <PromptRedline
      allRedlineUserIds={[createdBy]} // TODO EPS-2141: Refactor to pass in a single string when the keep-all-redlines work is done.
      timestamp={timestamp}
      error={error}
      onAccept={onAcceptRedline}
      onReject={onRejectRedline}
    >
      {/* Render redline diff for a specific field */}
      <ProcedureFieldDiff original={fieldValue} redlined={redlineFieldValue} />
    </PromptRedline>
  );
};

export default FieldRedlineBlock;
