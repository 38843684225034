import React, { useCallback } from 'react';
import { generateHiddenClassString } from '../../lib/styles';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import { useSettings } from '../../contexts/SettingsContext';
import procedureUtil from '../../lib/procedureUtil';
import runUtil from '../../lib/runUtil';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import useProcedureAdapter from '../../hooks/useProcedureAdapter';
import InvalidMessage from '../InvalidMessage';
import DiffContainer from '../Diff/DiffContainer';
import { JumpToBlockDiffElement, SectionDiffElement } from 'shared/lib/types/views/procedures';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

export const CONTENT_TYPE_JUMP_TO = 'jump_to';

// Returns string with "'sectionKey'. 'sectionName'"
const getSectionLabel = (section, allSections, getSetting) => {
  const sectionKey = runUtil.displaySectionKey(
    allSections,
    section.index,
    getSetting('display_sections_as', 'letters')
  );

  if (section.name) {
    return `${sectionKey}. ${section.name}`;
  }

  return `${sectionKey}.`;
};

// Returns string with "'stepKey'. 'stepName'"
const getStepLabel = (section, step, allSections, getSetting) => {
  const stepLabel = runUtil.displaySectionStepKey(
    allSections,
    section.index,
    step.index,
    getSetting('display_sections_as', 'letters')
  );

  if (step.name) {
    return `${stepLabel}. ${step.name}`;
  }

  return `${stepLabel}.`;
};
interface JumptoProps {
  content: JumpToBlockDiffElement;
  isHidden: boolean;
  blockLabel: string;
  isValid?: boolean;
}
const ReviewJumpTo = ({ content, isHidden, blockLabel, isValid = true }: JumptoProps) => {
  const { procedure, getItemPath, getItemPathForDiff, scrollTo } = useProcedureContext();
  const { getAllSections, getSectionSummary, getStepSummary } = useProcedureAdapter();
  const { getSetting } = useSettings();

  const getIsTargetRemoved = () => {
    const path =
      'diff_change_state' in content
        ? getItemPathForDiff(sharedDiffUtil.getDiffValue(content, 'jumpToId', 'new'))
        : getItemPath(sharedDiffUtil.getDiffValue(content, 'jumpToId', 'new'));

    const { sectionId, stepId } = procedureUtil.parsePath(path);

    /*
     * "Path not found" could happen if a section/step were removed, and then
     * another section/step were added, so that the diff thinks the section/step
     * was just changed.
     */
    const pathNotFound = !sectionId && !stepId;

    return pathNotFound || sectionId?.endsWith('__removed') || stepId?.endsWith('__removed');
  };

  const formatJumpToMessage = () => {
    const path =
      'diff_change_state' in content
        ? getItemPathForDiff(sharedDiffUtil.getDiffValue(content, 'jumpToId', 'new'))
        : getItemPath(sharedDiffUtil.getDiffValue(content, 'jumpToId', 'new'));

    const { sectionId, stepId } = procedureUtil.parsePath(path);
    const sectionSummaryNew = getSectionSummary(sectionId, 'new');
    const sectionSummaryOld = getSectionSummary(sectionId, 'old');
    const sectionSummary = sectionSummaryNew || sectionSummaryOld;

    if (sectionSummary) {
      const allSectionsNew = getAllSections('new') as Array<SectionDiffElement>;
      const allSectionsOld = getAllSections('old') as Array<SectionDiffElement>;

      const stepSummaryNew = getStepSummary(stepId, sectionId, 'new');
      const stepSummaryOld = getStepSummary(stepId, sectionId, 'old');
      const stepSummary = stepId && (stepSummaryNew || stepSummaryOld);

      if (stepSummary) {
        return stepSummaryNew
          ? `${getStepLabel(sectionSummaryNew, stepSummaryNew, allSectionsNew, getSetting)}`
          : `${getStepLabel(sectionSummaryOld, stepSummaryOld, allSectionsOld, getSetting)}`;
      }

      return sectionSummaryNew
        ? `${getSectionLabel(sectionSummaryNew, allSectionsNew, getSetting)}`
        : `${getSectionLabel(sectionSummaryOld, allSectionsOld, getSetting)}`;
    }

    return null;
  };

  const onJumpToClick = useCallback(
    (event) => {
      event.preventDefault();

      const path =
        'diff_change_state' in content
          ? getItemPathForDiff(sharedDiffUtil.getDiffValue(content, 'jumpToId', 'new'))
          : getItemPath(sharedDiffUtil.getDiffValue(content, 'jumpToId', 'new'));

      const { sectionId, stepId } = procedureUtil.parsePath(path);
      const latestRepeat =
        content.diff_change_state && content.diff_change_state !== ARRAY_CHANGE_SYMBOLS.UNCHANGED
          ? runUtil.getLatestRepeatForDiff(procedure, sectionId, stepId)
          : runUtil.getLatestRepeat(procedure, sectionId, stepId);

      scrollTo({
        sectionId: latestRepeat.sectionId,
        stepId: latestRepeat.stepId,
        stepHeaderId: latestRepeat.stepHeaderId,
      });
    },
    [content, getItemPathForDiff, getItemPath, procedure, scrollTo]
  );

  return (
    <tr>
      {/* Empty div for first grid column */}
      <td></td>
      <td colSpan={2}>
        <div className={generateHiddenClassString('', isHidden)}></div>

        {/* Content div */}
        <div className={generateHiddenClassString('flex mt-2 page-break', isHidden)}>
          {/* Bullet */}
          <Spacer />
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />

          <div>
            <DiffContainer label="Jump to" diffChangeState={content.diff_change_state} isTextSticky={false}>
              <span className="mr-2">Jump to</span>
              {isValid && !getIsTargetRemoved() && (
                <button
                  className="underline text-blue-600"
                  type="button"
                  onClick={onJumpToClick}
                  disabled={typeof scrollTo !== 'function'} // Disables for viewing in edit procedure
                >
                  {formatJumpToMessage()}
                </button>
              )}
              {(!isValid || getIsTargetRemoved()) && <InvalidMessage>Link no longer valid</InvalidMessage>}
            </DiffContainer>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default React.memo(ReviewJumpTo);
