import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloneDeep from 'lodash.clonedeep';
import { useMemo } from 'react';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import FieldSetCheckbox from '../../components/FieldSetCheckbox';
import Tooltip from '../../elements/Tooltip';
import idUtil from '../../lib/idUtil';
import useParts from '../hooks/useParts';
import { asComponentPart, isPartRestricted } from '../lib/parts';
import ComponentPartLabel from './ComponentPartLabel';
import ItemSelect from './ItemSelect';
import PartAndRevisionPusher from './PartAndRevisionPusher';
import RestrictedInfo from './RestrictedInfo';
import inventoryUtil from '../lib/inventoryUtil';

const TOOLTIP_MESSAGE = 'Additional parts for check-out may be added during a run';

const QuantityField = ({ component, componentPart, onQuantityChange }) => {
  return (
    <input
      type="number"
      className="text-sm border-1 border-gray-400 rounded disabled:bg-gray-100 w-full max-w-[14rem]"
      value={component.amount}
      disabled={componentPart?.tracking === 'serial'}
      placeholder="Quantity"
      onChange={(e) => onQuantityChange(e.target.value)}
    />
  );
};

const PartKitFieldSet = ({ content, contentErrors, path, teamId, setFieldValue, onPartChanged }) => {
  const partIdsInBlock = useMemo(() => inventoryUtil.collectPartIdsInBlock(content), [content]);

  const { getPart, getPartByRevisionId } = useParts({
    partIds: partIdsInBlock,
    includeAllReleasedRevisions: true,
  });

  const onAddItem = (part: Part) => {
    const component = asComponentPart(part, 1);
    const value = {
      ...content,
      items: [
        ...content.items,
        {
          ...component,
          id: idUtil.generateUuidEquivalentId(),
        },
      ],
    };
    setFieldValue(path ? path : '', value);
  };

  const onQuantityChange = (index, newQuantity) => {
    const quantity = newQuantity > 0 ? Math.floor(newQuantity) : 1;
    const updated = cloneDeep(content);
    updated.items[index].amount = quantity;
    setFieldValue(path ? path : '', updated);
  };

  const onRemoveItem = (item) => {
    const updated = cloneDeep(content);
    updated.items = updated.items.filter((component) => component.id !== item.id);
    setFieldValue(path ? path : '', updated);
  };

  return (
    <div className="flex flex-col grow">
      {/* Part components */}
      <table className="w-full table-fixed">
        <thead>
          <tr className="border-b items-center">
            <td>
              <div className="p-1">
                <span className="field-title">Parts for Check-Out</span>
                <Tooltip content={TOOLTIP_MESSAGE}>
                  <FontAwesomeIcon icon="circle-info" className="text-xs text-gray-400 ml-1" />
                </Tooltip>
              </div>
            </td>
            <td>
              <div className="p-1">
                <span className="field-title">Quantity</span>
              </div>
            </td>
            <td>
              <div className="p-1">
                <span className="field-title">Inventory</span>
              </div>
            </td>
            <td className="w-6"></td>
          </tr>
        </thead>
        <tbody>
          {content?.items.map((component, index) => {
            const componentPart = getPartByRevisionId(component.revision_id ?? '') ?? getPart(component.part_id);
            const partRestricted = isPartRestricted(componentPart);
            return (
              <tr key={index} className="h-10 text-sm border-b items-center">
                <td>
                  <ComponentPartLabel teamId={teamId} component={component} part={componentPart} />
                </td>
                <td>
                  {!partRestricted && (
                    <QuantityField
                      component={component}
                      componentPart={componentPart}
                      onQuantityChange={(quantity) => onQuantityChange(index, quantity)}
                    />
                  )}
                  {partRestricted && (
                    <div className="ml-1.5">
                      <RestrictedInfo />
                    </div>
                  )}
                </td>
                <td>
                  {!partRestricted && <ItemSelect itemId="" isDisabled={true} />}
                  {partRestricted && (
                    <div className="ml-1.5">
                      <RestrictedInfo />
                    </div>
                  )}
                </td>
                <td>
                  <div className="w-6 p-1">
                    <button type="button" className="secondary group" onClick={() => onRemoveItem?.(component)}>
                      <FontAwesomeIcon className="text-gray-400 group-hover:text-gray-500 p-1" icon="trash" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={4}>
              {contentErrors?.error && <div className="text-red-700 text-sm">{contentErrors.error}</div>}
              <div className="flex flex-row flex-wrap">
                <PartAndRevisionPusher onPush={onAddItem} />
                {/* Include in summary checkbox */}
                <div className="ml-2 mt-2 flex flex-row items-center">
                  <FieldSetCheckbox
                    text="Include in Summary"
                    fieldName={`${path}.include_in_summary`}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PartKitFieldSet;
