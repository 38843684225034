import { RefObject } from 'react';
import { BlockTypes } from '../components/Blocks/BlockTypes';
import procedureUtil from '../lib/procedureUtil';
import {
  DraftAttachmentBlock,
  StepBlock,
} from 'shared/lib/types/views/procedures';

/**
 * Change-event callback for file inputs:
 *   - Create new attachment block containing inputted file
 *   - Append new attachment block to content array
 *   - Reset hidden fileInputRef value
 */

const getFileInputBlock = (
  event: React.ChangeEvent<HTMLInputElement>,
  fileInputRef: RefObject<HTMLInputElement>
): StepBlock => {
  const file = event.currentTarget.files?.[0];
  const attachment = procedureUtil.newInitialBlock(
    BlockTypes.Attachment
  ) as DraftAttachmentBlock;
  attachment.file = file;
  attachment.name = attachment.file?.name;

  if (fileInputRef?.current) {
    fileInputRef.current.value = '';
  }

  return attachment;
};

export default getFileInputBlock;
