import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from 'primereact/dialog';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { selectOfflineInfo } from '../../app/offline';
import { FEATURE_RUN_PARTICIPANT_TOGGLE_ENABLED } from '../../config';
import { useAuth } from '../../contexts/AuthContext';
import { useSettings } from '../../contexts/SettingsContext';
import FieldError from '../../elements/internal/FieldError';
import ThreeDotMenu from '../../elements/ThreeDotMenu';
import Tooltip from '../../elements/Tooltip';
import { PERM } from '../../lib/auth';
import { isProcedureWithBatchSteps } from '../../lib/batchSteps';
import percentUtil from '../../lib/percentUtil';
import runUtil, { RUN_STATE } from '../../lib/runUtil';
import stickyHeaderUtil from '../../lib/stickyHeaderUtil';
import Button, { BUTTON_TYPES } from '../Button';
import EndProcedureButton from '../EndProcedureButton';
import ExpandCollapseButtons from '../ExpandCollapse/ExpandCollapseButtons';
import { VIEW_MODES } from '../FieldSetViewModeEditSelect';
import JiraIssuesButton from '../JiraIssuesButton';
import NavigationRedlines from '../NavigationRedlines';
import NextStepButton from '../NextStepButton';
import RunIssuesButton from '../RunIssuesButton';
import ToggleIsUserParticipant from '../ToggleIsUserParticipant';
import ToolbarDivider from '../Toolbar/Divider';
import TrackingWithQRCode from '../../manufacturing/components/TrackingWithQRCode';

const RunHeaderActions = ({
  run,
  scrollTo,
  scrollToId,
  expandAll,
  collapseAll,
  setShowPauseModal,
  endRun,
  onReopenRun,
  viewMode,
  projectId,
  isPreviewMode,
  onAddIssue,
  onAutomationChange,
  isVertical,
  isUserParticipant,
  qrCodeUrl,
}) => {
  const [showReopenModal, setShowReopenModal] = useState(false);
  const { auth } = useAuth();
  const online = useSelector((state) => selectOfflineInfo(state).online);
  const { isJiraIntegrationEnabled, isIssuesEnabled, isAutomationUIEnabled, isFullStepRedliningEnabled } =
    useSettings();

  const fullRedliningEnabled = useMemo(
    () => isFullStepRedliningEnabled && isFullStepRedliningEnabled(),
    [isFullStepRedliningEnabled]
  );

  const isComplete = useMemo(() => {
    const stepCounts = runUtil.getRunStepCounts(run);

    const completedStepsCount = stepCounts?.runCounts?.completedCount ?? 0;
    const notRequiredStepsCount = stepCounts?.runCounts?.notRequiredCount ?? 0;
    const failedStepsCount = stepCounts?.runCounts?.failedCount ?? 0;
    const skippedStepsCount = stepCounts?.runCounts?.skippedCount ?? 0;
    const totalStepsCount = stepCounts?.runCounts?.totalCount ?? 0;

    const endedSteps = completedStepsCount + skippedStepsCount + failedStepsCount;
    const canCompleteSteps = totalStepsCount - notRequiredStepsCount;

    const endedPercent = percentUtil.toPercent(endedSteps, canCompleteSteps);
    return endedPercent === 100;
  }, [run]);

  // All operators included on End Run signoffs
  const allEndRunSignoffOperators = useMemo(() => {
    const endRunSignoffs = run.end_run_signoffs_groups;
    if (!endRunSignoffs) {
      return [];
    }
    return endRunSignoffs.flatMap((signoff) => signoff.operators);
  }, [run.end_run_signoffs_groups]);

  // Operator roles the user has that are included on end run signoffs
  const userEndRunRoles = useMemo(() => {
    return allEndRunSignoffOperators.filter((operator) => auth.hasOperatorRole(operator));
  }, [allEndRunSignoffOperators, auth]);

  // Return true if signoffs required are 0 or user has met minimum signoff requirements
  const userHasEndRunSignoffs = useMemo(() => {
    return allEndRunSignoffOperators.length === 0 || userEndRunRoles.length > 0;
  }, [allEndRunSignoffOperators.length, userEndRunRoles.length]);

  const isEndRunButtonDisabled = useMemo(
    () => run.state !== RUN_STATE.RUNNING || !auth.hasPermission(PERM.RUNS_EDIT, projectId) || !userHasEndRunSignoffs,
    [run.state, auth, projectId, userHasEndRunSignoffs]
  );

  const areBatchStepsPresent = useMemo(() => {
    return isProcedureWithBatchSteps(run);
  }, [run]);

  const isPauseDisabled = useMemo(() => {
    return (
      run.state === RUN_STATE.PAUSED ||
      !auth.hasPermission(PERM.RUNS_EDIT, projectId) ||
      !isUserParticipant ||
      run.automation_status === RUN_STATE.PAUSED
    );
  }, [run.state, run.automation_status, auth, projectId, isUserParticipant]);

  const canCreateIssues = useMemo(() => {
    return (
      (run.state === RUN_STATE.RUNNING || run.state === RUN_STATE.PAUSED) &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId)
    );
  }, [auth, projectId, run.state]);

  const runRedlineScrollEntries = useMemo(
    () => runUtil.getRunRedlineScrollEntries(run, fullRedliningEnabled),
    [run, fullRedliningEnabled]
  );
  const numberPendingRedlines = useMemo(
    () => runRedlineScrollEntries.filter((entry) => entry.pending).length,
    [runRedlineScrollEntries]
  );

  const isPlanned = run.automation_status === 'planning';
  const isRunning = run.automation_status === 'running';
  const isPaused = run.automation_status === 'paused';

  const automationDisabled = useMemo(
    () => isComplete || isPaused || run.state === RUN_STATE.PAUSED || !online || !isUserParticipant,
    [isComplete, isPaused, run.state, online, isUserParticipant]
  );

  const canReopenRun = auth.hasPermission(PERM.RUNS_REOPEN, run.project_id);

  const Divider = () => {
    return isVertical ? <></> : <ToolbarDivider ml={1} mr={1} />;
  };

  return (
    <div>
      <div className="bg-transparent gap-y-2 lg:gap-y-0 lg:pl-4 h-full lg:items-center flex-col lg:flex-row justify-start lg:justify-end flex">
        {isAutomationUIEnabled() && run.automation_enabled && !isPreviewMode && (
          <>
            {!isPlanned && (
              <div className="bg-transparent lg:pl-4 h-full items-center flex flex-row justify-center">
                {isComplete && (
                  <>
                    <FontAwesomeIcon fixedWidth={true} icon="circle-check" className="text-xs text-green-500" />
                    <span className="ml-1 text-xs">Automation Complete</span>
                  </>
                )}
                {isRunning && (
                  <>
                    <FontAwesomeIcon fixedWidth={true} icon="bolt" className="text-xs" />
                    <span className="ml-1 text-xs">Automation Running</span>
                  </>
                )}
              </div>
            )}
            <Tooltip content="Automate">
              <button
                className={`flex flex-none items-center ${stickyHeaderUtil.getButtonClassNames(automationDisabled)}`}
                type="button"
                onClick={onAutomationChange}
                disabled={automationDisabled}
              >
                {isRunning ? (
                  <>
                    <FontAwesomeIcon fixedWidth={true} icon="circle-xmark" />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon fixedWidth={true} icon="bolt" />
                  </>
                )}
                {isVertical && <div className="ml-2 text-sm">Automate</div>}
              </button>
            </Tooltip>
          </>
        )}
        {!isPreviewMode && isIssuesEnabled && isIssuesEnabled() && (
          <>
            <Divider />
            <RunIssuesButton runId={run._id} projectId={run.project_id} canCreateIssue={canCreateIssues} />
          </>
        )}
        {!isPreviewMode && isJiraIntegrationEnabled && isJiraIntegrationEnabled() && (
          <>
            <Divider />
            <JiraIssuesButton
              runId={run._id}
              issues={run.issues}
              onAddIssue={onAddIssue}
              canCreateIssue={canCreateIssues}
              urlText={run?.name}
            />
          </>
        )}
        {runRedlineScrollEntries.length > 0 && (
          <>
            <Divider />
            <NavigationRedlines
              redlineFieldList={runRedlineScrollEntries}
              goToRedline={scrollToId}
              unresolvedActionsCount={numberPendingRedlines}
              unresolvedDescription="Pending"
              totalActionsCount={runRedlineScrollEntries.length}
            />
          </>
        )}
        <Divider />
        <ExpandCollapseButtons
          buttonClasses={stickyHeaderUtil.getButtonClassNames(viewMode !== VIEW_MODES.LIST, false)}
          onExpand={expandAll}
          onCollapse={collapseAll}
          isDisabled={viewMode !== VIEW_MODES.LIST}
          showLabel={isVertical}
        />
        {runUtil.isRunStateActive(run?.state) && (
          <>
            {!areBatchStepsPresent && (
              <Fragment>
                <Divider />
                <NextStepButton run={run} scrollTo={scrollTo} showLabel={isVertical} />
              </Fragment>
            )}
            {!isPreviewMode && (
              <Fragment>
                <Divider />
                <button
                  className={`flex flex-none items-center ${stickyHeaderUtil.getButtonClassNames(
                    isPauseDisabled,
                    false
                  )}`}
                  type="button"
                  title="Pause Run"
                  aria-label="Pause Run"
                  disabled={isPauseDisabled}
                  onClick={() => setShowPauseModal(true)}
                >
                  <FontAwesomeIcon fixedWidth={true} icon="pause" />
                  {isVertical && <div className="ml-2 text-sm">Pause</div>}
                </button>
              </Fragment>
            )}

            {!isPreviewMode && (
              <Fragment>
                <Divider />
                <EndProcedureButton
                  endRun={endRun}
                  isDisabled={isEndRunButtonDisabled}
                  hasEndRunPermissions={userHasEndRunSignoffs}
                  showLabel={isVertical}
                />
              </Fragment>
            )}
            <Divider />
            <h2>
              <TrackingWithQRCode name={run.name} url={qrCodeUrl} />
            </h2>
            {!isPreviewMode && FEATURE_RUN_PARTICIPANT_TOGGLE_ENABLED && (
              <Fragment>
                <Divider />
                <div className="flex self-center h-full items-center px-2">
                  <ToggleIsUserParticipant />
                </div>
              </Fragment>
            )}
          </>
        )}

        {runUtil.isCompleted(run) && (
          <>
            <Divider />
            <h2>
              <TrackingWithQRCode name={run.name} url={qrCodeUrl} />
            </h2>
            <Divider />
            <ThreeDotMenu
              menuActions={[
                {
                  type: 'label',
                  label: 'Reopen Run',
                  data: {
                    icon: 'rotate-left',
                    title: canReopenRun ? 'Reopen Run' : 'Requires Admin Access',
                    onClick: () => setShowReopenModal(true),
                    disabled: !canReopenRun,
                  },
                },
              ]}
              menuLabel="Run Options"
            />

            <ReopenRunModal
              isModalShown={showReopenModal}
              onCloseModal={() => setShowReopenModal(false)}
              onReopenRun={onReopenRun}
            />
          </>
        )}
      </div>
    </div>
  );
};

const ReopenRunModal = ({ isModalShown, onCloseModal, onReopenRun }) => {
  const { reset, handleSubmit, control, formState } = useForm({
    defaultValues: {
      comment: '',
    },
  });
  const { errors, isSubmitting } = formState;
  const onSubmit = useCallback(
    (data) => {
      onReopenRun(`Run reopened: ${data.comment}`);
      onCloseModal();
      reset();
    },
    [onCloseModal, reset, onReopenRun]
  );

  return (
    <Dialog visible={isModalShown} header="Reopen Run" onHide={onCloseModal} keepInViewport={false} className="w-96">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="comment"
          control={control}
          rules={{
            required: 'Required',
            validate: (value) => value.trim().length > 0 || 'Required',
          }}
          render={({ field: { value, onChange } }) => (
            <TextareaAutosize
              className="w-full text-sm border border-gray-400 focus:ring-blue-600 ring-inset rounded"
              placeholder="Justification (Required)"
              value={value}
              minRows={2}
              onChange={onChange}
              maxLength={486} // temporary jank: max length = 500 - 14 characters for prepending "Run reopened: " text
            />
          )}
        />
        {errors.comment?.message && <FieldError errorMessage={errors.comment.message} />}
        <div className="mt-4 text-right space-x-2">
          <Button
            type={BUTTON_TYPES.SECONDARY}
            title="Cancel"
            ariaLabel="Cancel Reopen Run"
            isDisabled={isSubmitting}
            onClick={onCloseModal}
          >
            Cancel
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            title="Reopen Run"
            ariaLabel="Confirm Reopen Run"
            isDisabled={isSubmitting}
            buttonTypeAttribute="submit"
          >
            Reopen
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default RunHeaderActions;
