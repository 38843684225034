import React, { useCallback, useState, ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';
import { Duration } from 'luxon';
import durationUtil from '../lib/durationUtil';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';
import { StepTiming } from 'shared/lib/types/couch/procedures';

const NAME = 'timer';
const ICON = 'hourglass-half';

interface FieldSetStepTimerProps {
  value: StepTiming;
  errors: string;
  onRemove?: (field: string) => void;
}

const FieldSetStepTimer = ({ value, errors, onRemove }: FieldSetStepTimerProps) => {
  const { setFieldValue } = useFormikContext();
  const [inputValue, setInputValue] = useState(durationUtil.isoDurationToHHMMSS(value?.time_left));

  const remove = useCallback(() => {
    if (onRemove) {
      onRemove(NAME);
    }
  }, [onRemove]);

  const handleTimingChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newTimeValue = event.target.value;
      setInputValue(newTimeValue);
      const parts = newTimeValue.split(':').map(Number);
      const isValidInput = parts.length === 3 && parts.every((part) => !isNaN(part));

      let isoDuration;
      if (isValidInput) {
        const [hours, minutes, seconds] = parts;
        const duration = Duration.fromObject({ hours, minutes, seconds });
        isoDuration = duration.toISO();
      } else {
        isoDuration = '';
      }

      const timingObject = {
        started_at: '',
        time_left: isoDuration,
        completed: false,
        time_remaining: '',
      };

      setFieldValue('timer', timingObject);
    },
    [setFieldValue]
  );

  return (
    <div className="group relative flex flex-col grow">
      <div className="flex flex-col shrink-0" style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}>
        <span className="field-title">Timer</span>
        <div className="grow w-full">
          <div className="grow relative">
            <div className="absolute top-2 left-3 text-gray-400">
              <FontAwesomeIcon icon={ICON} />
            </div>
            <Field
              name="timingField"
              type="text"
              placeholder="HH:MM:SS"
              className="text-sm border-1 border-gray-400 rounded pl-10 w-full"
              onChange={handleTimingChange}
              value={inputValue}
            />
          </div>
        </div>
        {errors && <span className="text-sm text-red-700 font-normal capitalize">{errors}</span>}
      </div>
      {onRemove && (
        <FontAwesomeIcon
          className="absolute top-0.5 right-0 text-gray-400 hover:text-gray-600 cursor-pointer opacity-0 group-hover:opacity-100"
          icon="times-circle"
          aria-label="Remove Detail"
          onClick={remove}
        />
      )}
    </div>
  );
};

export default FieldSetStepTimer;
