import React, { useMemo } from 'react';
import { generateHiddenClassString } from '../lib/styles';
import Comment from './Comment';
import { DraftRedlineComment, ReviewComment } from 'shared/lib/types/views/procedures';
import revisions from '../lib/revisions';
import procedureUtil from '../lib/procedureUtil';
import ParentReviewComment from './ParentReviewComment';

/**
 * Displays procedure comments for a step in edit procedure, sorted by time created.
 *
 * @param {Array} comments - Array of comment objects to be displayed.
 * @param {Boolean} isCollapsed - True if comments should be collapsed.
 */
interface ProcedureEditStepCommentsListProps {
  comments: Array<DraftRedlineComment | ReviewComment>;
  errors: { [commentId: string]: string } | null;
  isCollapsed: boolean;
  onFieldRefChanged: (id: string, element: HTMLElement | null) => void;
  onSaveReviewComment: (comment: ReviewComment) => Promise<void>;
  onResolveComment: (commentId: string) => void;
  onUnresolveComment: (commentId: string) => void;
  stepId?: string;
}

const ProcedureEditStepCommentsList = ({
  comments,
  errors,
  isCollapsed,
  onFieldRefChanged,
  onSaveReviewComment,
  onResolveComment,
  onUnresolveComment,
  stepId,
}: ProcedureEditStepCommentsListProps) => {
  const sortedRedlineComments = useMemo(() => {
    // Sort by created_at ascending
    return comments
      .filter((comment) => revisions.isRedlineComment(comment))
      .sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
  }, [comments]);

  const reviewComments: Array<ReviewComment> = useMemo(() => {
    return comments.filter((comment) => !revisions.isRedlineComment(comment)) as Array<ReviewComment>;
  }, [comments]);

  const parentCommentsMap = useMemo(() => {
    /*
     * Mapping between Parents Comments' Ids and Parent Comment
     */
    return procedureUtil.parentCommentsMap(reviewComments);
  }, [reviewComments]);

  const parentChildCommentsMap = useMemo(() => {
    // Mapping between ParentId and child comments
    return procedureUtil.parentChildCommentsMap(reviewComments);
  }, [reviewComments]);

  if (!comments.length) {
    return null;
  }

  let saveEditComment;
  if (typeof onSaveReviewComment === 'function') {
    saveEditComment = async (editedComment, commentId) => {
      const existingComment = reviewComments?.find((comment) => comment.id === commentId);
      const comment = {
        ...existingComment,
        text: editedComment.comment,
        mention_list: editedComment.mentions,
        parent_id: existingComment?.parent_id ?? '',
        updated_at: editedComment.updated_at,
      } as ReviewComment;

      return onSaveReviewComment(comment);
    };
  }

  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col grow">
        <div className={generateHiddenClassString('flex flex-col gap-y-3 my-3', isCollapsed)}>
          {sortedRedlineComments.map((comment) => (
            <Comment
              key={comment.id}
              onFieldRefChanged={onFieldRefChanged}
              error={errors && errors[comment.id]}
              comment={comment}
              onResolve={onResolveComment}
              onUnresolve={onUnresolveComment}
              editComment={undefined}
              commentButton={undefined}
              onRefChanged={undefined}
              isChild={undefined}
              onSetParentCollapsed={undefined}
              parentCollapsed={undefined}
            />
          ))}

          {reviewComments &&
            Object.entries(parentChildCommentsMap).map(([parentId, childComments]) => (
              <ParentReviewComment
                key={parentId}
                childComments={childComments}
                parentId={parentId}
                parentComment={parentCommentsMap[parentId]}
                stepId={stepId}
                onResolveComment={onResolveComment}
                onUnresolveComment={onUnresolveComment}
                saveReviewComment={onSaveReviewComment}
                editReviewComment={saveEditComment}
                isDocStateActive={true}
                onFieldRefChanged={onFieldRefChanged}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProcedureEditStepCommentsList;
