import React, { useMemo } from 'react';
import { Field } from 'formik';
import FieldSetMultiSelectWithIcon from './FieldSetMultiSelectWithIcon';
import { useSettings } from '../contexts/SettingsContext';

/**
 * Renders a multi-select input with a list of operators.
 *
 * @param {String} name - property path of the field to be updated.
 * @param {Array} values - values array representing selected values eg ['op1', 'op2'].
 * @param {String} icon - icon name that will be rendered in the field input.
 */
const FieldSetOperatorSelect = React.memo(({ name, values, icon }) => {
  const { operatorRoles } = useSettings();
  const labelOptions = useMemo(() => {
    if (!operatorRoles || !operatorRoles.operators) {
      return [];
    }

    return operatorRoles.operators.map((op) => ({
      value: op.code,
      label: op.code,
    }));
  }, [operatorRoles]);

  const selectValue = useMemo(() => {
    // If its values is not an array, return an empty array.
    if (!Array.isArray(values)) {
      return [];
    }

    return values.map((value) => {
      return {
        value,
        label: value,
      };
    });
  }, [values]);

  return (
    <Field name={name} value={selectValue} component={FieldSetMultiSelectWithIcon} options={labelOptions} icon={icon} />
  );
});

export default FieldSetOperatorSelect;
