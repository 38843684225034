import React from 'react';
import FieldInput from '../components/Blocks/FieldInput';
import FieldInputFieldSet from '../components/Blocks/FieldInputFieldSet';
import FieldInputProcedureBlock from '../components/Blocks/FieldInputProcedureBlock';
import AlertRun from '../components/Blocks/AlertRun';
import AlertFieldSet from '../components/Blocks/AlertFieldSet';
import AlertProcedureBlock from '../components/Blocks/AlertProcedureBlock';
import Requirement from '../components/Blocks/Requirement';
import RequirementFieldSet from '../components/Blocks/RequirementFieldSet';
import RequirementProcedureBlock from '../components/Blocks/RequirementProcedureBlock';
import ExternalItem from '../components/Blocks/ExternalItem';
import ExternalItemFieldSet from '../components/Blocks/ExternalItemFieldSet';
import ExternalItemProcedureBlock from '../components/Blocks/ExternalItemProcedureBlock';
import {
  BlockTypes,
  ExternalDataItem,
  RecordedString,
  RecordedNumber,
  TypedBlockProps,
  TypedFieldSetProps,
  TypedProcedureBlockProps,
} from '../components/Blocks/BlockTypes';
import AlertReview from '../components/Review/Blocks/AlertReview';
import FieldInputReview from '../components/Review/Blocks/FieldInputReview';
import RequirementReview from '../components/Review/Blocks/RequirementReview';

/*
 *This hook provides components for working with and rendering block content.
 *Components implement a generic interface (props) as described below.
 *
 *Interface: Action
 *Description: Tiny object to specify an inline action to render in a procedure
 *             block. Eg, "S" to show redlines or "{pencil}" to make changes.
 *An Action object has the following properties:
 *{
 *  icon: String, a FontAwesome icon name
 *  onAction: Function, Callback to trigger when clicked
 *  ariaLabel: String, Aria label for accessibility and testing
 *}
 *
 *Component: TypedBlock
 *Description: Simplest component for rendering a block. Supports the minimum for
 *redline functionality (text diffs) and recording or showing user-submitted values.
 *Props:
 *  block: The Block to render.
 *  redlined: A Block with changes. If present, redline differences are rendered,
 *            typically with strikethrough or red text.
 *  recorded: An arbitrary object representing any recorded values for this block.
 *  isEnabled: Whether recording values should be enabled, if supported by this block.
 *  onRecordValuesChanged: Callback for when user enters new recorded values.
 *                         Type fn(recorded), where recorded is any arbitrary object.
 *  onRecordErrorsChanged: Callback when there are errors in user-submitted recorded
 *                         values. Type fn(errors), where errors is a dictionary
 *                         of type { fieldName: String error }
 *
 *Component: TypedProcedureBlock
 *Description: Renders a Block in the context of a procedure. Is responsible for
 *functionality like expand/collapse, and understands the procedure CSS grid layout.
 *In the future, this component is a good candidate for further refactoring.
 *Props:
 *  isHidden: Whether to hide or show the current procedure block. Supports
 *            expand/contract functionality.
 *  actions: A list of Action objects to render within the procedure block.
 *  children: Children to render, eg a TypedBlock
 *
 *Component: TypedFieldSet
 *Description: Renders the necessary form elements for a specific block type.
 *Props:
 *  path: String, a path prefix to use in for all Formik fields. Can be empty.
 *        Eg, 'sections[0].steps[0].content[2]', for editing the third block
 *        in the first step of the first section.
 *  content: The current Block. (Block and Content objects are synonymous here.)
 *  contentErrors: Current Formik errors for the given content object.
 *  disabledFields: A dictionary of type { fieldName: true } to customize
 *                  which form elements should be disabled during edit. If empty,
 *                  no fields are disabled.
 *
 */

/*
 * Get components for the given block type.
 *
 * blockType: String, one of the listed BlockTypes in 'BlockTypes.js'
 * returns: Dictionary with { TypedProcedureBlock, TypedBlock, TypedFieldSet }
 *          for the given block type.
 */
type SupportedBlockTypes = 'alert' | 'input' | 'requirement' | 'external_item';

// Type weirdness here is to ensure we can interop with javascript properly
type useBlockComponentsReturn<
  T extends SupportedBlockTypes | unknown,
  U extends RecordedString | RecordedNumber | ExternalDataItem
> = T extends SupportedBlockTypes
  ? {
      TypedBlock: React.JSXElementConstructor<TypedBlockProps<T, U>> | React.ExoticComponent<TypedBlockProps<T, U>>;
      TypedFieldSet: React.JSXElementConstructor<TypedFieldSetProps<T>> | React.ExoticComponent<TypedFieldSetProps<T>>;
      TypedProcedureBlock:
        | React.JSXElementConstructor<TypedProcedureBlockProps>
        | React.ExoticComponent<TypedProcedureBlockProps>;
    }
  : {
      TypedBlock: null;
      TypedFieldSet: null;
      TypedProcedureBlock: null;
    };

const useBlockComponents = <T extends string, U extends RecordedString | RecordedNumber | ExternalDataItem>({
  blockType,
  isReview = false,
}: {
  blockType: T;
  isReview?: boolean;
}): useBlockComponentsReturn<T, U> => {
  switch (blockType) {
    case BlockTypes.Alert:
      return {
        TypedBlock: isReview ? AlertReview : AlertRun,
        TypedFieldSet: AlertFieldSet,
        TypedProcedureBlock: AlertProcedureBlock,
      } as unknown as useBlockComponentsReturn<T, U>;
    case BlockTypes.FieldInput:
      return {
        TypedBlock: isReview ? FieldInputReview : FieldInput,
        TypedFieldSet: FieldInputFieldSet,
        TypedProcedureBlock: FieldInputProcedureBlock,
      } as unknown as useBlockComponentsReturn<T, U>;
    case BlockTypes.Requirement:
      return {
        TypedBlock: isReview ? RequirementReview : Requirement,
        TypedFieldSet: RequirementFieldSet,
        TypedProcedureBlock: RequirementProcedureBlock,
      } as unknown as useBlockComponentsReturn<T, U>;
    case BlockTypes.ExternalItem:
      return {
        TypedBlock: ExternalItem,
        TypedFieldSet: ExternalItemFieldSet,
        TypedProcedureBlock: ExternalItemProcedureBlock,
      } as unknown as useBlockComponentsReturn<T, U>;
    default:
      return {
        TypedBlock: null,
        TypedFieldSet: null,
        TypedProcedureBlock: null,
      } as useBlockComponentsReturn<T, U>;
  }
};

export default useBlockComponents;
