import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, LinkProps } from 'react-router-dom';
import Tooltip from '../elements/Tooltip';

const CODE_TRUNCATE_AT_CHARS = 50;
const NAME_TRUNCATE_AT_CHARS = 50;

type RunLabelProps = {
  code: string;
  name?: string;
  runNumber?: number;
  runNumberBg?: 'light' | 'medium' | 'dark';
  link?: LinkProps['to'];
  icon?: IconProp;
  iconLink?: LinkProps['to'];
  iconTitle?: string;
};

const RunLabel = ({ code, name, runNumber, runNumberBg = 'light', link, icon, iconLink, iconTitle }: RunLabelProps) => {
  const codeWithNumber = `${code}${runNumber ? ` ${runNumber}` : ''}`;

  const truncatedCode = (() => {
    if (code.length > CODE_TRUNCATE_AT_CHARS) {
      return `${code.substring(0, CODE_TRUNCATE_AT_CHARS)}...`;
    }
    return code;
  })();

  const truncatedCodeWithNumber = (() => (
    <>
      {truncatedCode}
      {runNumber && (
        <span
          className={`ml-1 px-1 ${
            runNumberBg === 'light' ? 'bg-slate-200' : runNumberBg === 'medium' ? 'bg-slate-300' : 'bg-slate-800'
          }`}
        >
          {runNumber}
        </span>
      )}
    </>
  ))();

  const truncatedName = (() => {
    if (!name) {
      return;
    }
    if (name.length > NAME_TRUNCATE_AT_CHARS) {
      return `${name?.substring(0, NAME_TRUNCATE_AT_CHARS)}...`;
    }
    return name;
  })();

  return (
    <>
      <div className="flex flex-row">
        <Tooltip content={codeWithNumber} visible={code.length > CODE_TRUNCATE_AT_CHARS}>
          <div className="text-xs font-medium">
            {link && (
              <Link to={link} className="mr-1 text-blue-600 tracking-wider hover:underline truncate">
                {truncatedCodeWithNumber}
              </Link>
            )}
            {!link && truncatedCodeWithNumber}
          </div>
        </Tooltip>
        {icon && iconLink && (
          <Tooltip content={iconTitle}>
            <div className="mx-1 inline-block group">
              <Link to={iconLink}>
                <FontAwesomeIcon icon={icon as IconProp} className="text-gray-400" aria-label={iconTitle} role="img" />
              </Link>
            </div>
          </Tooltip>
        )}
      </div>
      {name && (
        <div className="mt-0 text-gray-900 truncate">
          <Tooltip content={name} visible={name.length > NAME_TRUNCATE_AT_CHARS}>
            <div>
              {link && <Link to={link}>{truncatedName}</Link>}
              {!link && truncatedName}
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default RunLabel;
