import { customAlphabet } from 'nanoid';
import nanoidDictionary from 'nanoid-dictionary';
import { HAZARD_PREFIX } from './hazards';
const { alphanumeric } = nanoidDictionary;

const DEFAULT_KEY_LENGTH = 10;
const UUID_EQUIVALENT_KEY_LENGTH = 22;

/**
 * Generates a random id with the given number of characters. Ids are
 * alphanumeric (A-Za-z0-9). Uses cryptographically strong randomness.
 *
 * length: Number of characters for id length.
 * returns: String, unique id.
 */
const _generateId = (length: number): string => {
  const nanoid = customAlphabet(alphanumeric, length);
  return nanoid();
};

/**
 * Returns generated unique string id, e.g., 'e4cR301383'
 * It would take 250 years at one id generated per minute to
 * have a >1% chance of generating a previously generated id
 * Link to calculator: https://zelark.github.io/nano-id-cc/
 */
const _generateDefaultId = (): string => {
  return _generateId(DEFAULT_KEY_LENGTH);
};

/**
 * Generate an id with length 22, which guarantees the same level
 * of randomness as UUID.
 *
 * @returns - alphanumeric string of length 22.
 */
const _generateUuidEquivalentId = (): string => {
  return _generateId(UUID_EQUIVALENT_KEY_LENGTH);
};

/**
 * Generated unique string for a run. Example: '56ncrXbmEUM7YET1KAZBbE'.
 * @returns - Random id.
 */
export const generateRunId = (): string => _generateUuidEquivalentId();

export const generateRedlineDocId = (): string => {
  return `rl_${_generateUuidEquivalentId()}`;
};

export const generateReviewerGroupId = (): string => {
  return `rg_${_generateUuidEquivalentId()}`;
};

export const generateReviewTypeId = (): string => {
  return `rt_${_generateUuidEquivalentId()}`;
};

export const generateReviewerId = (): string => {
  return `reviewer_${_generateUuidEquivalentId()}`;
};

export const generateStepConditionalId = (): string => {
  return `sc_${_generateUuidEquivalentId()}`;
};

export const generateAssessmentMatrixId = (): string => {
  return `${HAZARD_PREFIX}_matrix_${_generateUuidEquivalentId()}`;
};

export const generateAssessmentRowId = (): string => {
  return `${HAZARD_PREFIX}_row_${_generateUuidEquivalentId()}`;
};

export const generateAssessmentColumnId = (): string => {
  return `${HAZARD_PREFIX}_column_${_generateUuidEquivalentId()}`;
};

export const generatePlaceholderId = (): string => {
  return `placeholder_${_generateUuidEquivalentId()}`;
};

// Generated unique string for a comment. Example: cmt_db6e439549
export const generateCommentId = (): string => `cmt_${_generateDefaultId()}`;
export const generateRunTagId = (): string => _generateDefaultId();

export const generateUuidEquivalentId = _generateUuidEquivalentId;
