import React from 'react';
import FieldSetInputWithIcon from './FieldSetInputWithIcon';

/**
 * Renders a text input with an icon on the left side.
 *
 * @param {String} name - Property path of the field to be updated.
 * @param {String} icon - Icon name that will be rendered in the field input.
 * @param {String} placeholder - Placeholder string to be rendered in the field input.
 */
const FieldSetStepDetailText = React.memo(({ name, icon, placeholder }) => {
  return (
    <FieldSetInputWithIcon
      name={name}
      className="text-sm border-1 border-gray-400 rounded"
      icon={icon}
      placeholder={placeholder}
      type="text"
    />
  );
});

export default FieldSetStepDetailText;
