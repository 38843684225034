import { BlockType } from 'shared/lib/types/views/procedures';
import { AlertSubtypes } from '../Blocks/AlertRun';
import { BlockTypes } from '../Blocks/BlockTypes';
import {
  Actions,
  ContentMenuItem,
  MenuActionItem,
  addGroupings,
  Shortcuts,
} from './addContentTypes';

export interface GetBlockAddContentOptions {
  enabledContentTypes?: Array<BlockType>;
  groupItems?: boolean;
  canCopyBlock?: boolean;
  canCutBlock?: boolean;
  canPasteBlock?: boolean;
  canDeleteBlock?: boolean;
  hasConditionals?: boolean;
  hasDependencies?: boolean;
  isTestProcedure?: boolean;
  automationEnabled?: boolean;
  isOnline?: boolean;
  isReadOnly?: boolean;
}

const defaultOptions: GetBlockAddContentOptions = {
  groupItems: true,
  canCopyBlock: true,
  canCutBlock: true,
  canPasteBlock: false,
  canDeleteBlock: true,
  hasConditionals: false,
  hasDependencies: false,
  isTestProcedure: false,
  automationEnabled: false,
  isOnline: true,
  isReadOnly: false,
};

const getBlockAddContentItems = (
  options: GetBlockAddContentOptions
): Array<ContentMenuItem> => {
  const opts = {
    ...defaultOptions,
    ...options,
  };

  const blockAddContentItems: Array<MenuActionItem> = [
    {
      action: Actions.AddBlock,
      label: 'Text',
      icon: 'text-width',
      description: 'Text field with no background',
      group: 'Content Blocks',
      blockType: BlockTypes.Text,
    },
    {
      action: Actions.AddBlock,
      label: 'Note',
      icon: 'note-sticky',
      description: 'Text field with blue background',
      group: 'Content Blocks',
      blockType: BlockTypes.Alert,
      subType: AlertSubtypes.NOTE,
    },
    {
      action: Actions.AddBlock,
      label: 'Caution',
      icon: 'circle-info',
      description: 'Text field with yellow background',
      group: 'Content Blocks',
      blockType: BlockTypes.Alert,
      subType: AlertSubtypes.CAUTION,
    },
    {
      action: Actions.AddBlock,
      label: 'Warning',
      icon: 'triangle-exclamation',
      description: 'Text field with red background',
      group: 'Content Blocks',
      blockType: BlockTypes.Alert,
      subType: AlertSubtypes.WARNING,
    },
    {
      action: Actions.AddBlock,
      label: 'Requirement',
      icon: 'file-circle-exclamation',
      description: 'Text field with grey background',
      group: 'Content Blocks',
      blockType: BlockTypes.Requirement,
    },
    {
      action: Actions.AddBlock,
      label: 'File',
      icon: 'paperclip',
      description: 'Attach image, video, audio, or doc',
      group: 'Content Blocks',
      blockType: BlockTypes.Attachment,
    },
    {
      action: Actions.AddBlock,
      label: 'Jump To',
      icon: 'arrow-turn-down',
      description: 'Jump to another step in procedure',
      group: 'Content Blocks',
      blockType: BlockTypes.JumpTo,
    },
    {
      action: Actions.AddBlock,
      label: 'Link to Procedure',
      icon: 'link',
      description: 'Link to a sub procedure',
      group: 'Content Blocks',
      blockType: BlockTypes.ProcedureLink,
    },
    {
      action: Actions.AddBlock,
      label: 'Field Input',
      icon: 'keyboard',
      description: 'Input readings or data',
      group: 'Inputs',
      blockType: BlockTypes.FieldInput,
    },
    {
      action: Actions.AddBlock,
      group: 'Inputs',
      label: 'Field Input Table',
      icon: 'table-columns',
      description: 'Adds field input table to step',
      blockType: BlockTypes.FieldInputTable,
    },
    {
      action: Actions.AddBlock,
      label: 'Input Reference',
      icon: 'glasses',
      description: 'Show value from a previous field input',
      group: 'Inputs',
      blockType: BlockTypes.Reference,
      subType: BlockTypes.FieldInput,
    },
    {
      action: Actions.AddBlock,
      label: 'Expression',
      icon: 'square-root-variable',
      description: 'Perform calculations with field input data',
      group: 'Inputs',
      hidden: !opts.isOnline,
      blockType: BlockTypes.Expression,
    },
    {
      action: Actions.AddBlock,
      label: 'Table',
      icon: 'table',
      description: 'Add table to step',
      group: opts.isReadOnly ? 'Content Blocks' : 'Inputs',
      blockType: BlockTypes.TableInput,
    },
    {
      key: 'part-check-out',
      action: Actions.AddBlock,
      label: 'Check Out Part',
      icon: 'upload',
      group: 'Parts',
      hidden: !opts.isOnline,
      blockType: BlockTypes.PartKit,
    },
    {
      key: 'part-usage',
      action: Actions.AddBlock,
      label: 'Record Part Usage',
      icon: 'spinner',
      group: 'Parts',
      hidden: !opts.isOnline,
      blockType: BlockTypes.PartUsage,
    },
    {
      key: 'part-check-in',
      action: Actions.AddBlock,
      label: 'Check In Part',
      icon: 'download',
      group: 'Parts',
      hidden: !opts.isOnline,
      blockType: BlockTypes.PartBuild,
    },
    {
      key: 'update-inventory-detail',
      action: Actions.AddBlock,
      label: 'Update Inventory Detail',
      icon: 'pen-to-square',
      group: 'Parts',
      hidden: !opts.isOnline,
      blockType: BlockTypes.InventoryDetailInput,
    },
    {
      key: 'tool-check-out',
      action: Actions.AddBlock,
      label: 'Check Out Tool',
      icon: 'upload',
      group: 'Tools',
      hidden: !opts.isOnline,
      blockType: BlockTypes.ToolCheckOut,
    },
    {
      key: 'tool-usage',
      action: Actions.AddBlock,
      label: 'Record Tool Usage',
      icon: 'spinner',
      group: 'Tools',
      hidden: !opts.isOnline,
      blockType: BlockTypes.ToolUsage,
    },
    {
      key: 'tool-check-in',
      action: Actions.AddBlock,
      label: 'Check In Tool',
      icon: 'download',
      group: 'Tools',
      hidden: !opts.isOnline,
      blockType: BlockTypes.ToolCheckIn,
    },
    {
      action: Actions.AddBlock,
      label: 'Telemetry',
      icon: 'signal',
      description: 'Incoming data from an external system',
      group: 'External',
      hidden: !opts.isOnline,
      blockType: BlockTypes.Telemetry,
    },
    {
      action: Actions.AddBlock,
      label: 'Commanding',
      icon: 'satellite-dish',
      description: 'Send data to an external system',
      group: 'External',
      hidden: !opts.isOnline,
      blockType: BlockTypes.Commanding,
    },
    {
      action: Actions.AddBlock,
      label: 'External Data',
      icon: 'database',
      description: 'Incoming defined values from external databases',
      group: 'External',
      hidden: !opts.isOnline,
      blockType: BlockTypes.ExternalItem,
    },
    {
      action: Actions.AddBlock,
      label: 'Conditionals',
      icon: 'diagram-project',
      description: 'Action of next step based on result of current step',
      group: 'Logic',
      blockType: BlockTypes.Conditionals,
      hidden: opts.hasConditionals,
    },
    {
      action: Actions.AddBlock,
      label: 'Dependencies',
      icon: 'diagram-project',
      description: 'Step is dependent on completion of another step',
      group: 'Logic',
      blockType: BlockTypes.Dependencies,
      hidden: opts.hasDependencies,
    },
    {
      action: Actions.AddBlock,
      group: 'Testing',
      label: 'Test Points',
      icon: 'table-cells',
      description: 'Adds test points',
      blockType: BlockTypes.TestCases,
      hidden: !opts.isTestProcedure,
    },
    {
      group: 'Actions',
      action: Actions.CopyBlock,
      label: 'Copy Block',
      icon: 'clone',
      description: 'Copy this content block to clipboard',
      hidden: !opts.canCopyBlock,
      keyboardShortcut: Shortcuts.copy,
    },
    {
      group: 'Actions',
      action: Actions.CutBlock,
      label: 'Cut Block',
      icon: 'scissors',
      description: 'Move this content block to clipboard',
      hidden: !opts.canCutBlock,
      keyboardShortcut: Shortcuts.cut,
    },
    {
      group: 'Actions',
      action: Actions.PasteBlock,
      label: 'Paste Block',
      icon: 'clone',
      description: 'Paste content block from clipboard',
      hidden: !opts.canPasteBlock,
      keyboardShortcut: Shortcuts.paste,
    },
    {
      group: 'Actions',
      action: Actions.DeleteBlock,
      label: 'Remove',
      icon: 'circle-xmark',
      description: 'Remove this content block',
      hidden: !opts.canDeleteBlock,
      keyboardShortcut: Shortcuts.remove,
    },
  ];

  const filtered = opts.enabledContentTypes
    ? [
        ...blockAddContentItems.filter(
          (item) =>
            !item.blockType ||
            (opts.enabledContentTypes &&
              opts.enabledContentTypes.includes(item.blockType))
        ),
      ]
    : [...blockAddContentItems];

  if (opts.groupItems) {
    return addGroupings(filtered);
  }

  return filtered;
};

export default getBlockAddContentItems;
