import { cloneDeep } from 'lodash';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Button, { BUTTON_TYPES } from '../../components/Button';
import UploadCsvModal from '../../components/UploadCsvModal';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { inventoryAddPath } from '../../lib/pathUtil';
import InventoryGrid from '../components/InventoryGrid';
import WelcomeModal from '../components/WelcomeModal';
import useItems from '../hooks/useItems';
import useLocations from '../hooks/useLocations';
import useManufacturingAuth from '../hooks/useManufacturingAuth';
import csvLib, { ITEM_COLUMNS, getMissingColumnHeaders, getMissingColumnsError } from '../lib/csv';
import { isNewSerialNumber } from '../lib/items';
import { Item } from '../types';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import LocationTreeSelect from '../components/LocationTreeSelect';
import TabBar, { TabProps } from '../../components/TabBar/TabBar';
import ExportInventoryModal from '../components/ExportInventoryModal';
import useParts from '../hooks/useParts';
import SearchInputControlled from '../../elements/SearchInputControlled';
import SearchFilter from '../../components/lib/SearchFilter';
import { useSettings } from '../../contexts/SettingsContext';
import projectUtil from '../../lib/projectUtil';

export enum InventoryTab {
  Available = 'available',
  Unavailable = 'unavailable',
}

const INVENTORY_TABS: ReadonlyArray<TabProps<InventoryTab>> = [
  { id: InventoryTab.Available, label: 'Available' },
  { id: InventoryTab.Unavailable, label: 'Unavailable' },
];

const Inventory = () => {
  const { services, currentTeamId } = useDatabaseServices();
  const { projects, isBuildsPermissionsEnabled } = useSettings();
  const [partSearchTerm, setPartSearchTerm] = useState('');
  const [locationIdsFilter, setLocationIdsFilter] = useState<string[]>([]);

  const [showImportModal, setShowImportModal] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState('');
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [activeTab, setActiveTab] = useState<InventoryTab>(InventoryTab.Available);
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<string> => new Set());
  const [exportItemsModalVisible, setExportItemsModalVisible] = useState(false);
  const [selectedProjectIds, setSelectedProjectIds] = useState<ReadonlySet<string | null>>(new Set([]));

  const { locations } = useLocations();
  const { hasEditPermission, hasOperatorPermission, hasWorkspaceViewerPermission } = useManufacturingAuth();
  const canPotentiallyEditBuilds = hasWorkspaceViewerPermission || isBuildsPermissionsEnabled();
  const { allItems } = useItems();
  const { parts } = useParts();

  const projectOptions = useMemo(() => projectUtil.getProjectsAsOptions(projects), [projects]);

  const example = {
    header: ['Part Number', 'Rev', 'Quantity', 'Serial / Lot #', 'Location ID'],
    data: [
      ['C-1', 'B', '50', 'A001', 'S1B1'],
      ['C-1', 'B', '50', 'A002', 'S1B2'],
      ['C-1', 'B', '50', 'B001', ''],
      ['G-1', 'X', '100', '', ''],
      ['A-1', 'A', '1', 'PA-01-001', 'W-800'],
      ['A-1', 'A', '1', 'PA-01-003', ''],
      ['A-1', 'A', '1', 'PA-01-004', ''],
    ],
  };

  const onImport = async (file: File) => {
    setImportError('');
    setIsImporting(true);

    try {
      const missingColumns = await getMissingColumnHeaders(file, ITEM_COLUMNS);
      if (missingColumns.length > 0) {
        const missingColumnsError = getMissingColumnsError(missingColumns, 'items');
        setImportError(missingColumnsError);
        setIsImporting(false);
        return;
      }

      const { items: itemsFromCsv, csvRowCount } = await csvLib.parseItemsCsv(file);
      let importCount = 0;
      const duplicateSerialNums: string[] = [];

      if (itemsFromCsv.length > 0) {
        // build out a 'parts lookup by part_no' map
        const partsByPartNo = {};
        for (const part of parts || []) {
          partsByPartNo[part.part_no.toLowerCase()] = part;
        }

        // build out a 'locations lookup by location code' map
        const locationsByCode = {};
        for (const location of Object.values(locations || {})) {
          const locationCode = location.full_code || location.code;
          locationsByCode[locationCode] = location;
        }

        const itemsToImport: Partial<Item>[] = [];
        for (const item of itemsFromCsv) {
          // part
          const part: Part = partsByPartNo[item.part_no as string];
          if (!part || !part.revisions?.some((revision) => revision.revision.toLowerCase() === item.rev)) {
            continue;
          }
          // check if duplicate serial # in csv or if it is already set on an existing item
          if (
            item.serial &&
            (!isNewSerialNumber(item.serial, part.id, itemsToImport) ||
              !(allItems && isNewSerialNumber(item.serial, part.id, allItems)))
          ) {
            duplicateSerialNums.push(item.serial);
            continue;
          }

          const itemPart = cloneDeep(part);
          itemPart.rev = item.rev as string;
          item.part = itemPart;
          item.part_no = part.part_no;

          // tracking
          if (part.tracking === 'serial') {
            if (!item.serial) {
              continue;
            }
            item.amount = 1;
          } else {
            item.serial = undefined;
          }

          if (part.tracking === 'lot') {
            if (!item.lot) {
              continue;
            }
          } else {
            item.lot = undefined;
          }

          // location
          if (item.location_id) {
            const location = locationsByCode[item.location_id];
            item.location_id = location ? location.id : undefined;
          } else {
            item.location_id = undefined;
          }

          itemsToImport.push(item);
        }

        if (itemsToImport.length > 0) {
          const importedItems: Item[] = await services.manufacturing.addItems(itemsToImport);
          importCount = importedItems.length;
        }
      }

      const duplicateSerialNumsMessage = `Failed to import ${pluralize(
        'item',
        duplicateSerialNums.length,
        true
      )} with duplicate serial numbers (${[...new Set(duplicateSerialNums)].join(', ')})`;

      if (importCount === 0) {
        let importErrorMessage = 'Unable to import any new items from file.';
        if (duplicateSerialNums.length > 0) {
          importErrorMessage += ` ${duplicateSerialNumsMessage}`;
        }
        setImportError(importErrorMessage);
      } else {
        const importCountStr = pluralize('item', importCount, true);
        const rowCountStr = pluralize('row', csvRowCount, true);
        setShouldRefresh(true);
        let importMessage = `Imported ${importCountStr} after parsing ${rowCountStr}.`;
        if (duplicateSerialNums.length > 0) {
          importMessage += ` ${duplicateSerialNumsMessage}`;
        }
        window.alert(importMessage);
        setShowImportModal(false);
      }
    } catch (error) {
      setImportError('Unexpected error occurred. Please try again.');
    }
    setIsImporting(false);
  };

  const onExport = () => {
    const selectedItems = (allItems ?? []).filter((item) => selectedRows.has(item.id));

    const csvExport = csvLib.createItemsExport(selectedItems, locations || {});
    // escape double quotes in content
    csvExport.forEach((row) => {
      row.forEach((field, index) => {
        if (typeof field === 'string') {
          row[index] = field.replace(/"/g, '""');
        }
      });
    });

    return csvExport;
  };

  const onExportComponentTrees = () => {
    const selectedItems = (allItems ?? []).filter((item) => selectedRows.has(item.id));

    csvLib.exportItemsWithComponentTree(selectedItems, parts || [], allItems || [], locations || {});
    setExportItemsModalVisible(false);
  };

  if (allItems === undefined) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <WelcomeModal />
      {exportItemsModalVisible && (
        <ExportInventoryModal
          onCancel={() => setExportItemsModalVisible(false)}
          onExport={onExport}
          onExportComponentTrees={onExportComponentTrees}
        />
      )}
      <div className="flex justify-between ">
        <div className="text-xl">Parts Inventory</div>
        {canPotentiallyEditBuilds && (
          <div className="flex flex-row">
            <div className="mr-2">
              {hasEditPermission && (
                <Button
                  type={BUTTON_TYPES.TERTIARY}
                  ariaLabel="Import"
                  leadingIcon="upload"
                  onClick={() => setShowImportModal(true)}
                >
                  Import
                </Button>
              )}
              {showImportModal && (
                <UploadCsvModal
                  modalSize="md"
                  dataType="Parts Inventory"
                  example={example}
                  footerText="Note: Columns 'Part Number', 'Rev', and 'Quantity' are required."
                  isUploading={isImporting}
                  uploadErrorMessage={importError}
                  onSubmit={onImport}
                  onCancel={() => {
                    setImportError('');
                    setShowImportModal(false);
                  }}
                />
              )}
            </div>
            {hasOperatorPermission && (
              <Link to={inventoryAddPath(currentTeamId)} className="btn">
                Add
              </Link>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-row items-center w-full mt-2">
        <SearchInputControlled
          searchTerm={partSearchTerm}
          setSearchTerm={setPartSearchTerm}
          placeholder="Search parts inventory"
        />
        {/* Location Filter */}
        <label htmlFor="tag_select" className="ml-4 mr-2">
          Location:
        </label>
        <LocationTreeSelect locations={locations || {}} onSelect={setLocationIdsFilter} />
        <div className="ml-1"></div>
        <SearchFilter
          filterTitle="Projects"
          filterOptions={projectOptions}
          selectedFilterIds={selectedProjectIds}
          onFilterIdsChange={(ids) => setSelectedProjectIds(ids)}
          ariaLabel="Projects Filter"
        />
      </div>
      <div className="mt-2 mr-2">
        <div className="flex w-full">
          <div className="divide-x">
            <span className="text-sm text-gray-400 w-42 mr-2 ml-1">{selectedRows.size} items selected</span>
            <span className="w-28 text-center mb-2">
              <Button
                isDisabled={selectedRows.size === 0}
                type={BUTTON_TYPES.TERTIARY}
                onClick={() => setExportItemsModalVisible(true)}
                leadingIcon="download"
                title="Export Selected Items"
              >
                Export Selected Items
              </Button>
            </span>
          </div>
        </div>
        <TabBar tabs={INVENTORY_TABS} selectedTab={activeTab} setSelectedTab={setActiveTab} />
      </div>
      <InventoryGrid
        searchTerm={partSearchTerm}
        setSearchTerm={setPartSearchTerm}
        locationIdsFilter={locationIdsFilter}
        shouldRefresh={shouldRefresh}
        onRefreshComplete={() => setShouldRefresh(false)}
        activeTab={activeTab}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        projects={projects}
        selectedProjectIds={selectedProjectIds}
      />
    </div>
  );
};

export default Inventory;
