import React from 'react';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FieldSetInputWithIcon = React.memo(
  ({
    name, //unique key for value in json
    type, //text, number,
    placeholder, //placeholder text for input
    className, //classes applied to formik field
    icon, //fontawesome icon key
    validate, //validate function for formik field,
    list, //key for data-list to be used
    errors, // String representing errors
    ...props
  }) => (
    <div className="grow w-full">
      <div className="grow relative">
        <div className="absolute top-2 left-3 text-gray-400">
          <FontAwesomeIcon icon={icon} />
        </div>
        <Field
          name={name}
          type={type}
          placeholder={placeholder}
          className={`${className} pl-10 w-full`}
          validate={validate}
          list={list}
          {...props}
        />
      </div>
      {errors && <div className="text-red-700">{errors}</div>}
    </div>
  )
);

export default FieldSetInputWithIcon;
