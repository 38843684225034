import { useCallback } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { Draft } from 'shared/lib/types/views/procedures';
import usePartBlockHelpers from './usePartBlockHelpers';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';

interface ProcedurePartsState {
  onPartChanged: (part: Part | null) => void;
}

interface ProcedurePartsProps {
  procedure: Draft;
  onProcedureChanged?: (updated: Draft, present: Draft) => void;
}

const useProcedureParts = ({
  procedure,
  onProcedureChanged,
}: ProcedurePartsProps): ProcedurePartsState => {
  const { getKitItems } = usePartBlockHelpers({});

  const onPartChanged = useCallback(
    (part: Part | null) => {
      const updated = cloneDeep(procedure);

      // Update part built and parts list.
      if (part) {
        updated.part_list.part = part;
        updated.part_list.part_id = part.id;
        updated.part_list.items = getKitItems(part);
      } else {
        updated.part_list.part = null;
        updated.part_list.part_id = null;
        updated.part_list.items = [];
      }

      onProcedureChanged?.(updated, procedure);
    },
    [procedure, onProcedureChanged, getKitItems]
  );

  return { onPartChanged };
};

export default useProcedureParts;
