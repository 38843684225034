import React from 'react';
import Select, { components, Option } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <div className="flex pl-3 last:pl-4">
        <FontAwesomeIcon className="text-gray-500" icon="info-circle" />
      </div>
      {children}
    </components.Control>
  );
};

interface DependencySelectProps {
  options: Array<Option>;
  name?: string;
  placeholder: JSX.Element | string;
  opacity: number;
  hoverOpacity: number;
  value: Option;
  onChange: (option: Option) => void;
  ariaLabel: string;
}

const DependencySelect = ({
  options,
  name,
  placeholder,
  opacity,
  hoverOpacity,
  value,
  onChange,
  ariaLabel,
}: DependencySelectProps) => {
  const styles = {
    control: (base) => ({
      ...base,
      borderColor: 'rgba(156, 163, 175)',
      backgroundColor: 'white',
      opacity,
      '&:hover': { opacity: hoverOpacity },
      borderWidth: 1,
      minWidth: 40,
    }),
    valueContainer: (base) => ({
      ...base,
      minWidth: 0,
      paddingRight: 0,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'rgba(156, 163, 175)',
    }),
  };

  const Placeholder = () => {
    return <div className="text-gray-600">{placeholder}</div>;
  };
  return (
    <Select
      classNamePrefix="react-select"
      styles={styles}
      options={options}
      name={name}
      components={{
        Control,
        Placeholder,
        IndicatorSeparator: () => null,
      }}
      value={value}
      onChange={onChange}
      isMulti
      placeholder
      isClearable={false}
      aria-label={ariaLabel}
    />
  );
};

export default React.memo(DependencySelect);
