import React, { useMemo } from 'react';
import { DraftFieldInputTableBlock } from 'shared/lib/types/views/procedures';
import GridFieldInput from './GridFieldInput';
import SubstepNumber from './SubstepNumber';

export interface FieldInputTableProps {
  content: DraftFieldInputTableBlock;
  recorded;
  isHidden: boolean;
  isEnabled: boolean;
  blockLabel: string;
  onRecordValuesChanged: (blockId: string, values, fieldIndex: number) => void;
  onRecordErrorsChanged?: (errors: { text?: string }) => void;
}

const FieldInputTable = ({
  content,
  recorded,
  isHidden,
  isEnabled,
  blockLabel,
  onRecordValuesChanged,
  onRecordErrorsChanged,
}: FieldInputTableProps) => {
  const { fieldsPerRow, fields } = content;

  const handleRecordValuesChanged = (fieldIndex: number, newRecordedValue) => {
    onRecordValuesChanged(content.id, newRecordedValue, fieldIndex);
  };

  const numberofColumns = useMemo(() => {
    return fieldsPerRow <= fields.length ? fieldsPerRow : fields.length;
  }, [fieldsPerRow, fields]);

  if (isHidden) return null;

  return (
    <div className="relative flex max-w-full w-full mr-2">
      <div className="flex flex-col mt-1 ml-3">
        <SubstepNumber blockLabel={blockLabel} />
      </div>
      <div className="flex-grow relative pr-7">
        <div
          className="grid"
          style={{
            gridTemplateColumns: `repeat(${numberofColumns}, minmax(125px, 1fr))`,
          }}
        >
          {fields.map((field, index) => (
            <div key={index} className="flex w-full">
              <GridFieldInput
                block={field}
                recorded={recorded && recorded[index]}
                isEnabled={isEnabled}
                onRecordValuesChanged={(newValue) => handleRecordValuesChanged(index, newValue)}
                onRecordErrorsChanged={onRecordErrorsChanged}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FieldInputTable;
