import { DragDropContext, DropResult, Droppable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Step } from 'shared/lib/types/views/procedures';
import { ArrayHelpers } from 'formik';

type DropWrapperReturn = {
  snapshot: DroppableStateSnapshot;
};
interface DropWrapperProps {
  onDragStart: () => void;
  onDragEnd: (result: DropResult, arrayHelpers: ArrayHelpers, block: Step, syncBlock: (values: Step) => void) => void;
  contentArrayHelpers: ArrayHelpers;
  step: Step;
  syncStepToProcedure: (values: Step) => void;
  sectionIndex: number;
  stepIndex: number;
  children(params: DropWrapperReturn): React.ReactElement;
}

const DropWrapper = ({
  onDragStart,
  onDragEnd,
  contentArrayHelpers,
  step,
  syncStepToProcedure,
  sectionIndex,
  stepIndex,
  children,
}: DropWrapperProps) => {
  return (
    <DragDropContext
      onDragStart={onDragStart}
      onDragEnd={(result) => onDragEnd(result, contentArrayHelpers, step, syncStepToProcedure)}
    >
      <Droppable droppableId={`section.${sectionIndex}.step.${stepIndex}`}>
        {(provided, snapshot) => (
          <>
            <div
              className={`border-2 border-dashed ${snapshot.isDraggingOver ? 'border-gray-400' : 'border-transparent'}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {children({ snapshot })}
              {provided.placeholder}
            </div>
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DropWrapper;
