import React, { useMemo, useCallback } from 'react';
import { cloneDeep } from 'lodash';
import DependencySelect from './DependencySelect';
import { Dependency } from 'shared/lib/types/views/procedures';

type DependencyOption = {
  type: string;
  label: string;
  value: string;
};

export type DependencyOptions = {
  remaining: Array<DependencyOption>;
  all: Array<DependencyOption>;
};

interface EditDependencyProps {
  dependencyIndex: number;
  dependency: Dependency;
  onRemoveDependency: (index: number) => void;
  dependencyOptions: DependencyOptions;
  onDependencyUpdated: (dependency: Dependency, index: number) => void;
}

const EditDependency = ({
  dependencyIndex,
  dependency,
  onRemoveDependency,
  dependencyOptions,
  onDependencyUpdated,
}: EditDependencyProps) => {
  const onRemove = useCallback(() => {
    onRemoveDependency(dependencyIndex);
  }, [dependencyIndex, onRemoveDependency]);

  const updateDependency = useCallback(
    (values) => {
      const updated = cloneDeep(dependency);
      updated.dependent_ids = values;

      onDependencyUpdated(updated, dependencyIndex);

      if (values.length === 0) {
        onRemove();
      }
    },
    [dependency, dependencyIndex, onRemove, onDependencyUpdated]
  );

  const onChange = useCallback(
    (options) => {
      const values = options.map((option) => option.value);
      updateDependency(values);
    },
    [updateDependency]
  );

  const value = useMemo(
    () =>
      dependency?.dependent_ids.map((id) => {
        const dependencyOption = dependencyOptions.all.find((depOpt) => depOpt.value === id);

        if (!dependencyOption) {
          return null;
        }

        return {
          value: id,
          label: dependencyOption.label,
        };
      }),
    [dependencyOptions.all, dependency?.dependent_ids]
  );

  return (
    <div className="relative group max-w-xl text-sm">
      <DependencySelect
        options={dependencyOptions.remaining}
        placeholder="Add Dependency"
        opacity={0.8}
        hoverOpacity={1}
        value={value}
        onChange={onChange}
        ariaLabel="Dependency Select"
      />
    </div>
  );
};

export default EditDependency;
