import React, { useCallback, useMemo } from 'react';
import TableEdit from './TableEdit';
import FieldSetCheckbox from '../FieldSetCheckbox';
import ImportTableButton from './ImportTableButton';
import { BlockValues } from '../Blocks/BlockTypes';
import { FormikHelpers, FormikValues } from 'formik';
import FieldError from '../../elements/internal/FieldError';

interface TableInputEditProps {
  content: BlockValues<'table_input'>;
  path: string;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  isReadOnly?: boolean;
  errors?: { error: string } | null;
}

const TableInputEdit = React.memo(
  ({ content, path, setFieldValue, isReadOnly = false, errors }: TableInputEditProps) => {
    const updateTableContent = useCallback(
      (tableContent) => {
        setFieldValue(path, tableContent);
      },
      [path, setFieldValue]
    );

    const tableName = useMemo(() => {
      if (content.sub_type === 'test_point') {
        return 'Test Point Table';
      }
      return 'Table';
    }, [content.sub_type]);

    return (
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between mr-5 gap-x-2">
          <div className="field-title my-1 mt-3">{tableName}</div>
          {content.sub_type !== 'test_point' && <ImportTableButton onUpload={updateTableContent} />}
        </div>
        <div className="w-full">
          {/*@ts-ignore*/}
          <TableEdit path={path} content={content} setFieldValue={setFieldValue} isReadOnly={isReadOnly} />
        </div>
        {errors?.error && <FieldError errorMessage={errors.error} />}

        {!isReadOnly && (
          <div className="flex flex-row pt-2">
            {/* Include in summary checkbox */}
            <div className="flex flex-row items-center">
              <FieldSetCheckbox
                text="Include in Summary"
                fieldName={`${path}.include_in_summary`}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default TableInputEdit;
